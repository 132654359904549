import React, { Component } from "react";

// Assuming the "children" has 16px bottom padding.
const textStyle = {
  paddingTop: 0,
  paddingBottom: 0
};
const strapLineTextStyle = {
  paddingTop: 8,
  paddingBottom: 40
};
const headingStyle = {
  paddingBottom: 8
};
const mainHeadingStyle = {
  paddingBottom: 8,
  marginTop: 12
};
const sectionTitleStyle = {
  paddingTop: "40px",
};
const sectionPadding = {
  paddingTop: "40px",
  paddingBottom: "40px",
};

// Section style and sectioIDStyle move the link 24px down.
// This way the title is 24px down from the header when selected on inpage-nav.
// Section style
const sectionStyle = {
  position: "relative"
};
// Section ID style
const sectionIDStyle = {
  position: "absolute",
  top: "-24px"
};

export default class ContentBlock extends Component {
  render() {
    const {
      id,
      title,
      section,
      section_title,
      heading,
      mainHeading,
      children,
      blockStyle,
      sectionTopOffset = 24
    } = this.props;

    return (
      <div
        className="uil-dls-font-body-01"
        style={{ ...sectionStyle, ...blockStyle }}
      >
        <span
          id={id}
          style={{ position: "absolute", top: -sectionTopOffset }}
        ></span>
        {mainHeading && (
          <div
            className="uil-dls-font-heading-02 uil-font-medium-weight"
            style={mainHeadingStyle}
          >
            {mainHeading}
          </div>
        )}

{section && (
          <div
            className="uil-dls-font-heading-01 uil-font-medium-weight"
            style={sectionPadding}
          >
            {section}
          </div>
        )}
        {title && (
          <div
            className="uil-dls-font-heading-01 uil-font-medium-weight"
            style={headingStyle}
          >
            {title}
          </div>
        )}
        {heading && (
          <div
            className="uil-dls-font-title-02 uil-font-medium-weight"
            style={headingStyle}
          >
            {heading}
          </div>
        )}
        {section_title && (
          <div
            // className="uil-container-section-title uil-section-title"
            className="uil-container-section-title uil-font-medium-weight"
            style={sectionTitleStyle}
          >
            <span>{section_title}</span>
          </div>
        )}
        <div
          className={
            mainHeading
              ? "uil-dls-font-title-00 uil-color-gray-08"
              : "uil-dls-font-body-01"
          }
          style={mainHeading ? strapLineTextStyle : textStyle}
        >
          {children}
        </div>
      </div>
    );
  }
}
