import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./uil_tag.module.css";

// import  UilTooltip  from "../UilTooltip/UilTooltip.jsx";

const UilTag = (props) => {
  const {
    id,
    shape,
    disabled,
    type,
    text,
    onClose,
    onClick,
    size,
    customClasses,
    tooltipText,
    tooltipPosition,
    closeIcon,
    isSelected,
  } = props;

  const typeClass = classNames([styles["uil-tag"]], customClasses, {
    [styles["uil-tag-square"]]: shape === "square",
    [styles["uil-tag-round"]]: shape === "round",
    [styles["uil-tag-link"]]: shape === "link",
    [styles["uil-type-primary"]]: type === "primary",
    [styles["uil-type-secondary"]]: type === "secondary",
    [styles["uil-type-error"]]: type === "error",
    [styles["uil-type-warning"]]: type === "warning",
    [styles["uil-type-success"]]: type === "success",
    [styles["uil-type-info"]]: type === "info",
    [styles["uil-size-small"]]: size === "small",
    [styles["uil-size-medium"]]: size === "medium",
    [styles["uil-size-large"]]: size === "large",
    [styles.disabled]: disabled,
    [styles["uil-type-selected"]]: isSelected,
  });

  return disabled ? (
    <div htmlFor={id} className={typeClass}>
      <div className={styles["uil-tag-text"]} onClick={null}>
        {text}
      </div>
      {closeIcon ? (
        <a className={styles["uil-dl-icon-Close"]} onClick={null}></a>
      ) : null}
    </div>
  ) : tooltipText ? (
    // <UilTooltip
    //   id="tooltip_id"
    //   position={tooltipPosition}
    //   tooltipText={tooltipText}
    //   event="hover"
    // >
      <div className={typeClass}>
        <div className={styles["uil-tag-text"]} onClick={onClick}>
          {text}
        </div>
        {closeIcon ? (
          <a className={styles["uil-dl-icon-Close"]} onClick={onClose}></a>
        ) : null}
      </div>
    // </UilTooltip>
  ) : (
    <div className={typeClass}>
      <div className={styles["uil-tag-text"]} onClick={onClick}>
        {text}
      </div>
      {closeIcon ? (
        <a className={styles["uil-dl-icon-Close"]} onClick={onClose}></a>
      ) : null}
    </div>
  );
};

UilTag.defaultProps = {
  shape: "default",
  disabled: false,
  text: "Tags",
  size: "small",
  closeIcon: true,
};

UilTag.propTypes = {
  id: PropTypes.string,
  shape: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  customClasses: PropTypes.string,
  onClose: PropTypes.func,
  closeIcon: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.element,
  isSelected: PropTypes.bool,
};

export default UilTag;
