import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./uil_sidenavigationbar.module.css";
import UilShadow from "../UilShadow/UilShadow";

import { Link } from "react-router-dom";

const UilSideNavigationBar = ({
  id,
  disabled,
  mode,
  navOptions,
  navbottomOptions,
  delay,
  delayClose,
  customPadding,
  clickHandler,
}) => {
  const [activeTab, setActiveTab] = useState("");

  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 0);
  };

  const hideTip = () => {
    timeout = setTimeout(() => {
      clearInterval(timeout);
      setActive(false);
    }, delayClose || 0);
  };

  const handleMenuClick = (item) => {
    setActiveTab(item);
    if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <div className="uil-sidenavbar">
      {mode === "light" ? (
        <UilShadow
          customClasses={`${styles["uil_sidenavbarlight"]} ${
            active ? [styles["uil-nav-expanded"]] : ""
          } `}
        >
          <div
            className={`${styles["uil_sidenavbar_wrapper"]} `}
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
          >
            <ul>
              {navOptions.map((item) => (
                <Link to={item.redirectTo}>
                  <li
                    id={item.id}
                    key={item.id}
                    className={`${styles["uil-sidenav-items"]} ${item.icon} ${
                      activeTab === item.id && !disabled ? styles.active : ""
                    } ${disabled ? styles["disabled"] : ""}`}
                    {...(!disabled && {
                      onClick: () => {
                        handleMenuClick(item.id);
                      },
                    })}
                  >
                    {active && [item.displayValue]}
                  </li>
                </Link>
              ))}
            </ul>
            <ul style={customPadding}>
              {navbottomOptions.map((item) => (
                <li
                  key={item.id}
                  className={`${styles["uil-sidenav-bottom-items"]} ${
                    item.icon
                  } ${
                    activeTab === item.id && !disabled ? styles.active : ""
                  } ${disabled ? styles["disabled"] : ""}`}
                  {...(!disabled && {
                    onClick: () => {
                      handleMenuClick(item.id);
                    },
                  })}
                >
                  {active && [item.displayValue]}
                </li>
              ))}
            </ul>
          </div>
        </UilShadow>
      ) : (
        <UilShadow
          customClasses={` ${styles["uil_sidenavbardark"]} ${
            active ? [styles["uil-nav-expanded"]] : ""
          }`}
        >
          <div
            className={`${styles["uil_sidenavbar_wrapper"]} `}
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
          >
            <ul>
              {navOptions.map((item) => (
                <li
                  id={item.id}
                  key={item.id}
                  className={`${styles["uil-sidenav-items-dark"]} ${
                    item.icon
                  } ${
                    activeTab === item.id && !disabled ? styles.active : ""
                  } ${disabled ? styles["disabled"] : ""} `}
                  {...(!disabled && {
                    onClick: () => {
                      handleMenuClick(item.id);
                    },
                  })}
                >
                  {active && [item.displayValue]}
                </li>
              ))}
            </ul>
            <ul style={customPadding}>
              {navbottomOptions.map((item) => (
                <li
                  key={item.id}
                  className={`${styles["uil-sidenav-bottom-items-dark"]} ${
                    item.icon
                  } ${
                    activeTab === item.id && !disabled ? styles.active : ""
                  } ${disabled ? styles["disabled"] : ""}`}
                  {...(!disabled && {
                    onClick: () => {
                      handleMenuClick(item.id);
                    },
                  })}
                >
                  {active && [item.displayValue]}
                </li>
              ))}
            </ul>
          </div>
        </UilShadow>
      )}
    </div>
  );
};

UilSideNavigationBar.defaultProps = {};

UilSideNavigationBar.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  mode: PropTypes.string,
  delay: PropTypes.any,
  delayClose: PropTypes.any,
  customPadding: PropTypes.any,
  clickHandler: PropTypes.func,
};

export default UilSideNavigationBar;
