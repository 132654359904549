import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import styles from "./uil_headerbar.module.css";
import { BrowserRouter, Link } from "react-router-dom";
import classNames from "classnames";
import UilTabBar from '../UilTab/UilTabBar';

const SubMenu = ({ subMenus }) => {
  return (
    <ul className={styles['sub-menu']} >
      {subMenus?.map((subMenu) => (
        <li key={subMenu.subMenuId}>
          <BrowserRouter>
            <Link to={subMenu.subMenuPath}>{subMenu.subMenuName}</Link>
          </BrowserRouter>
        </li>
      ))}
    </ul>
  );
};

const UilHeaderBar = ({ id, logo, islogo, title, IsWithBottomBorder, headertab, tools, customClasses, headerSize, headerPadding, ishamburgerIcon, onClose, customStyles }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTabsVisible, setIsTabsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(null);
  const headerRef = useRef(null);
  const logoRef = useRef(null);
  const appNameRef = useRef(null);
  const toolsRef = useRef(null);
  const tabsRef = useRef(null);
  const seperatorRef = useRef(null);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

 
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const onBlurTabMenu = () => {
    setActiveSubMenu(null)
    setSelectedTabIndex(null);
  }
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const headerWidth = headerRef.current?.offsetWidth || 0;
      const tabsWidth = tabsRef.current ? tabsRef.current.offsetWidth : 0;
      const iconWidth = logoRef.current ? logoRef.current.offsetWidth : 0;
      const toolsWidth = toolsRef.current ? toolsRef.current.offsetWidth : 0;
      const appNameWidth = appNameRef.current ? appNameRef.current.offsetWidth : 0;
      const separatorWidth = seperatorRef.current ? seperatorRef.current.offsetWidth : 0;

      let totalTabWidth = 0;
      if (tabsRef.current) {
        tabsRef.current.querySelectorAll('li').forEach(tab => {
          const tabWidth = tab.getBoundingClientRect().width;
          totalTabWidth += tabWidth;
        });
      }

      const availableSpace = headerWidth - iconWidth - toolsWidth - appNameWidth - separatorWidth;
      const requiredSpace = totalTabWidth + toolsWidth;

      if (requiredSpace > availableSpace || headerWidth < 800) {
        setIsTabsVisible(false);
      } else {
        setIsTabsVisible(true);
      }
    };
    setIsLoading(false);

    handleResize();
    window.addEventListener('resize', handleResize);
   
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>  
      <div id={id} className={classNames(`${styles['header']} ${styles[headerSize]} ${styles[headerPadding]} ${customClasses ? ` ${styles[customClasses]}`:" "} ${IsWithBottomBorder ? " ":` ${styles['uil-borderless']}`} `)} ref={headerRef} onClose={onClose}>
        <div className={styles['header-right']} ref={logoRef}>
          {ishamburgerIcon && islogo &&
            <div className={styles['uil-font-icon']} onClick={handleMenuClick} style={{ paddingRight: '24px' }}>
              <span className={styles['uil-dl-icon-Burger']} onClick={handleMenuClick} style={{ cursor: 'pointer', fontSize: '24px' }}></span>
            </div>}
          
          
          {islogo ?
            <span className={styles['uil-font-icon']}
              style={{ cursor: 'pointer' }}
            >
              {logo}
            </span> :
            <span className={styles['uil-font-icon']}
              style={{ cursor: 'pointer', display: 'none' }}
            >
              {logo}
            </span>
          }
        </div>
        {islogo  &&
        <div class={styles['separator']} ref={seperatorRef}>
          |
        </div>
        }
        <div className={styles['header-name']} ref={appNameRef}>
          <div className={styles['logo']}>
            <a className={styles['uil-horizontal-nav-title']}>{title}</a>
          </div>
        </div>

        {isTabsVisible ?
          <div className={styles['uil-horizontal-nav-navigationarea']}>
            <ul className={classNames(`${styles['uil-horizontal-nav-navbar']} ${styles[headerSize]}`)} ref={tabsRef}>
              <div className={classNames(`${styles['nav']} ${styles['uil-navbar-tabs']} ${styles['uil-horizontal-nav-mainmenu']} ${styles[headerSize]}`)}>
                {headertab?.map((tab, index) => {
                  const isActive = selectedTabIndex === index;
                  const showSubMenu = isActive && activeSubMenu === tab.subMenus;

                  return (
                    <li className={styles['uil-horizontal-nav-item']} key={index}>
                      <BrowserRouter>
                        <Link
                          to={tab.tabPath}
                          className={classNames(`${styles['tabs']} ${isActive && activeSubMenu ? `${styles['active']}` : ''} ${styles[headerSize]}`)}
                          onClick={() => {
                            setSelectedTabIndex(isActive ? null : index);
                            setActiveSubMenu(isActive ? null : tab.subMenus);
                          }}
                          
                          onBlur={onBlurTabMenu}
                        >
                          {tab.tabName}
                        </Link>
                      </BrowserRouter>
                      {tab.subMenus && showSubMenu && (
                        <SubMenu subMenus={tab.subMenus} />
                      )}
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
          :
          <div className={styles['uil-horizontal-nav-navigationarea']} >
            <ul className={styles['uil-horizontal-nav-navbar']} ref={tabsRef}>
              <div className={classNames(`${styles['nav']} ${styles['uil-navbar-tabs']} ${styles['uil-horizontal-nav-mainmenu']} ${styles[headerSize]}`)}>
                {headertab?.map((tab, index) => {
                  const isActive = selectedTabIndex === index;
                  const showSubMenu = isActive && activeSubMenu === tab.subMenus;

                  return (
                    <li className={styles['uil-horizontal-nav-item']} key={index}>
                      <BrowserRouter>
                        <Link
                          to={tab.tabPath}
                          className={classNames(`${styles['tabs']} ${isActive && activeSubMenu ? `${styles['active']}` : ''} ${styles[headerSize]}`)}
                          style={customStyles}
                          onClick={() => {
                            setSelectedTabIndex(isActive ? null : index);
                            setActiveSubMenu(isActive ? null : tab.subMenus);
                          }}
                        >
                          {tab.tabName}
                        </Link>
                      </BrowserRouter>
                      {tab.subMenus && showSubMenu && (
                        <SubMenu subMenus={tab.subMenus} />
                      )}
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        }

        <div className={`${styles['header-right']} ${styles['tools']}`} ref={toolsRef}>
          {tools?.map((tool, index) => (
            <span key={index} className={styles['tool-button']} onClick={tool.onClick}>
              {tool.icon}
            </span>
          ))}
        </div>
      </div>
      {isMenuOpen && isTabsVisible === false &&
        <div className={styles['tabs']}>
          <ul>
            <UilTabBar tabs={headertab} type='textOnly' tabOrientation="vertical"></UilTabBar>
          </ul>
        </div>
      }
    </div>
  );
};


UilHeaderBar.propTypes = {
  id: PropTypes.string,
  title: PropTypes.element,
  IsWithBottomBorder:PropTypes.bool,
  headertab: PropTypes.any,
  ishamburgerIcon: PropTypes.bool,
  logo: PropTypes.node,
  islogo:PropTypes.bool,
  tools: PropTypes.node,
  headerSize: PropTypes.string,
  headerPadding: PropTypes.string,
  customClasses: PropTypes.string,
  customStyles: PropTypes.any,
}

export default UilHeaderBar;
