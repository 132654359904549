import React, { useState, useRef } from "react";
import cx from "classnames";
import './iconRef.css'
import UilButton from "../UilButton/UilButton";
import UilTooltip from "../UilTooltip/UilTooltip";
// import styled from "styled-components";
// import { UilIconButton, UilTooltip } from "react-uicomp-lib/src/DpoUilReact";


const contentStyle = {
  textAlign: "center"
};
const bottomtextStyle = {
  textAlign: "center"
};
const toptextStyle = {
  textAlign: "center",
  padding: 8
};
const iconStyle = {
  fontSize: 32
};
const iconCopyStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "flex-end"
};
const IconPreviewBlock = ({ title, onCopyIcon }) => {
  const copyButtonRef = useRef();
  let name = title.replace("uil-dl-icon-", "");
  name = name.replace("uil-dl-new-","")
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={contentStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div class="uil-icon-preview-wrapper">
        <div className={cx("uil-dls-font-caption-01")} style={toptextStyle}>
          {name}
        </div>
        <div className="uil-font-icon" style={iconStyle}>
          <span className={title}></span>
        </div>
        <div style={iconCopyStyle}>
          <div ref={copyButtonRef}>
            <UilTooltip parent={copyButtonRef} position={"bottom"}  tooltipText="Copy Icon Class">
            <UilButton
              type="secondary"
              iconPosition= 'op_iconOnly'
              icon="uil-dl-icon-Copy"
              iconStyle={{ fontSize: "20px" }}
              onClick={onCopyIcon}
              customClasses="uil-btn-icons"
            /></UilTooltip>
          </div>
        </div>
        {hovered && (
        <></>
        )}
      </div>
    </div>
  );
};

export default IconPreviewBlock;
