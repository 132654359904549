import backgroundImage from "../../../assets/gradients/ACC_Gradient_Dark_1.jpg";
import bannerstyles from "../../../app-components/UilBanner/uil_banner.module.css";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import UilBadge from "../../../app-components/UilBadge/UilBadge";
import acn_logo from "../../../assets/img/AccentureLogo_black.png";
import "../content/components.css";

const componentData = {
  UilBanner: {
    type: "hero",
    size: "medium",
    title: "Component and technologies",
    content: (
      <span>
        Here you can browse all our current and future components. The
        technologies
        <br /> they are build upon and links to to the individual Storybooks.
        <br />
        <br />
        Below are link to the indivdual Storybook code repositories.
      </span>
    ),
    backgroundImage: { backgroundImage },
    customClasses: "uil-custom-banner",
    showTitle: true,
    showContent: true,
    showSubHeading: false,
    leftSpacing: "small",
    showFooter: (
      <>
        <span className={bannerstyles["uil-banner-btn-border"]}>
          <UilButton
            type="primary"
            label={<span>React repository</span>}
            sizeType="large"
            fitcontent={true}
            customClasses="uil-primary-btn-custom"
          ></UilButton>
        </span>
        <span className={bannerstyles["uil-banner-btn-border"]}>
          <UilButton
            type="primary"
            label={<span>Angular repository</span>}
            sizeType="large"
            fitcontent={true}
            customClasses="uil-primary-btn-custom"
          ></UilButton>
        </span>
        <span className={bannerstyles["uil-banner-btn-border"]}>
          <UilButton
            type="primary"
            label={<span>Web components repository</span>}
            sizeType="large"
            fitcontent={true}
            customClasses="uil-primary-btn-custom"
          ></UilButton>
        </span>
      </>
    ),
  },
  UilTextFieldSearch: {
    placeholder: "Search",
  },
  UilToggleSwitch: {
    size: "large",
    styleType: "Square",
    type: "primary",
    leftText: "",
    rightText: "",
  },
  UilComponentDetails: {
    title: "Component availability",
  },
  UilComponentTableDetails: [
    {
      type: "basic",
      id: "table_id",
      variantType: "default_Header",
      defaultSortColumn: "Component",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              { content: "Accordion" },
              { content: "UilAccordion" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: <div>
                  <a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-accordion--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-accordion--docs"></a>
                  <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcaccordion--accordion"></a></div>
              }
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              { content: "Button" },
              { content: "UilButton" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                
                  content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-button--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-button--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcbutton--button"></a></div>)
                
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "3" }],
            data: [
              { content: "3DotMenu" },
              { content: "Uil3dotmenu" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-action-menu--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-3dotmenu--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wc-3dotmenu--dot-menu"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "4" }],
            data: [
              { content: "Badge" },
              { content: "UilBadge" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-badge--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-badge--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/example-wcbadge--status-badge"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "5" }],
            data: [
              { content: "Banner" },
              { content: "UilBanner" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-banner--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-banner--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcbanner--hero-banner"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },

          {
            metadata: [{ id: "6" }],
            data: [
              { content: "Breadcrumb" },
              { content: "UilBreadcrumb" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-breadcrumb--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-breadcrumb--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcbreadcrumb--breadcrumb"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "7" }],
            data: [
              { content: "Card" },
              { content: "UilCard" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-card--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-card--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wccard--card-default"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "8" }],
            data: [
              { content: "CheckBox" },
              { content: "UilCheckbox" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-checkbox--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-checkbox--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wccheckbox--checkbox"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },

          {
            metadata: [{ id: "10" }],
            data: [
              { content: "DateControl" },
              { content: "UilDateControl" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="red"
                      text="Unavailable"
                      iconClass="uil-dl-icon-CloseIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-date-picker--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-date-picker--docs"></a>
                <a target="_blank" className="uil-webComp-icon" ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "11" }],
            data: [
              { content: "Drawer" },
              { content: "UilDrawer" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="red"
                      text="Unavailable"
                      iconClass="uil-dl-icon-CloseIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-drawer--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-drawer--docs"></a>
                <a target="_blank" className="uil-webComp-icon"  ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "12" }],
            data: [
              { content: "Multi Select Dropdown" },
              { content: "UilDropdownMultiSeletion" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-dropdown-multiselect--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-dropdown-multiselect--docs"></a>
                <a target="_blank" className="uil-webComp-icon"  ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "13" }],
            data: [
              { content: "Dropdown Single Selection" },
              { content: "UilDropdownSingleSelection" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-dropdown-singleselect--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-dropdown-singleselect--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcdropdownsingleselection--singleselect"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "14" }],
            data: [
              { content: "Expandible Card" },
              { content: "UilExpandibleCard" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-expandable-card--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-expandablecard--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcexpandablecard--expandible-card-one"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "15" }],
            data: [
              { content: "Empty Result" },
              { content: "UilEmptyResult" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-empty-result--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-empty-result--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcemptyresult--empty-result"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "16" }],
            data: [
              { content: "Fab" },
              { content: "UilFab" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-fab--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-fab--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcfab--fab-with-icon"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "17" }],
            data: [
              { content: "File Upload" },
              { content: "UilFileUpload" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-file-upload--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-fileupload--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcfileupload--file-upload"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "18" }],
            data: [
              { content: "FootNote" },
              { content: "UilFootNote" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-footnote--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-footnote--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcfootnote--footnote"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "19" }],
            data: [
              { content: "Footer" },
              { content: "UilFooter" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-footer--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-footer--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcfooter--footer"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "20" }],
            data: [
              { content: "Header" },
              { content: "UilHeader" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-header--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-header--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcheaderbar--headerbar-baseline"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "21" }],
            data: [
              { content: "In Page Navigation" },
              { content: "UilInPageNavigation" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-in-page-navigation--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-in-page-navigation--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcinpagenavigation--in-page-navigation-container"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "22" }],
            data: [
              { content: "Label" },
              { content: "UilLabel" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-label--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-label--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wclabel--label"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "23" }],
            data: [
              { content: "List" },
              { content: "UilList" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="red"
                      text="Unavailable"
                      iconClass="uil-dl-icon-CloseIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-list--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-list--docs"></a>
                <a target="_blank" className="uil-webComp-icon"  ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "24" }],
            data: [
              { content: "Multi Toggle" },
              { content: "UilMultiToggle" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-multi-toggle-button--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-multi-toggle-button--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcmultitoggle--multi-toggle"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "25" }],
            data: [
              { content: "Pagination" },
              { content: "UilPagination" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-pagination--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-pagination--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcpagination--pagination"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "26" }],
            data: [
              { content: "PopOver" },
              { content: "UilPopover" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="red"
                      text="Unavailable"
                      iconClass="uil-dl-icon-CloseIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-pop-over--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-popover--docs"></a>
                <a target="_blank" className="uil-webComp-icon"  ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "27" }],
            data: [
              { content: "PopUp" },
              { content: "UilPopup" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-pop-up--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-popup--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcpopup--popup"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "28" }],
            data: [
              { content: "Progress" },
              { content: "UilProgress" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-progress-bar--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-progressbar--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcprogress--progress"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "29" }],
            data: [
              { content: "Radio Button" },
              { content: "UilRadioButton" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-radio-button--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-radiobutton--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcradiobutton--radio-button"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "30" }],
            data: [
              { content: "Shadow" },
              { content: "UilShadow" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-shadow--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-shadow--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcshadow--shadow"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "31" }],
            data: [
              { content: "Side Navigation Bar" },
              { content: "UilSideNavigationBar" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="red"
                      text="Unavailable"
                      iconClass="uil-dl-icon-CloseIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-side-navigation-bar--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-side-navigation-bar--docs"></a>
                <a target="_blank" className="uil-webComp-icon"  ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "32" }],
            data: [
              { content: "Slider" },
              { content: "UilSlider" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-slider--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-slider--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcslider--slider"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "33" }],
            data: [
              { content: "Spinner" },
              { content: "UilSpinner" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-spinner--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-spinner--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcspinner--spinner-small"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "34" }],
            data: [
              { content: "Tab Bar" },
              { content: "UilTabBar" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-tab-bar--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-tab-bar--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctab--default-tab-bar"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "35" }],
            data: [
              { content: "Table" },
              { content: "UilTable" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-table--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-table--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctable--table"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "36" }],
            data: [
              { content: "Tag" },
              { content: "UilTag" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-tag-chip--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-tag--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctag--tag"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "37" }],
            data: [
              { content: "TagList" },
              { content: "UilTaglist" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-tag-list--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-tag-list--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctaglist--tag"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "38" }],
            data: [
              { content: "Text Area" },
              { content: "UilTextArea" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-text-area--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-text-area--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctextarea--text-area-default"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "39" }],
            data: [
              { content: "Text Field" },
              { content: "UilTextField" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-text-field--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-text-field--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctextfield--textfield"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "40" }],
            data: [
              { content: "Text Field Search" },
              { content: "UilTextFieldSearch" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-search-box--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-search-input--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctextfieldsearch--textfieldsearch"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "41" }],
            data: [
              { content: "Time Picker" },
              { content: "UilTimePicker" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="red"
                      text="Unavailable"
                      iconClass="uil-dl-icon-CloseIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-time-picker-new--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-time-picker--docs"></a>
                <a target="_blank" className="uil-webComp-icon"  ></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "42" }],
            data: [
              { content: "Toast Message" },
              { content: "UilToaster" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-toast-message-new--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-toast-message--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctoaster--toaster-message"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "43" }],
            data: [
              { content: "ToggleSwitch" },
              { content: "UilToggleSwitch" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-toggle-switch--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-toggle-switch--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctoggleswitch--toggle-switch"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "44" }],
            data: [
              { content: "Tooltip" },
              { content: "UilTooltip" },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-tooltip--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-tooltip--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wctooltip--example"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "45" }],
            data: [
              { content: "Vertical Navigation" },
              { content: "UilVerticalNavigation" },

              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (
                  <span className="componentAvailabilityBadge">
                    <UilBadge
                      id="Badge"
                      type="text"
                      size="mediumLight"
                      color="green"
                      text="Available"
                      iconClass="uil-dl-icon-CheckIcon"
                    ></UilBadge>
                  </span>
                ),
              },
              {
                content: (<div><a target="_blank" className="uil-react-icon" href="https://designlanguage.accenture.com/storybook-static-react/index.html?path=/docs/example-vertical-nested-menu--docs"></a>
                  <a target="_blank" className="uil-angular-icon" href="https://designlanguage.accenture.com/storybook-static-angular/index.html?path=/docs/example-vertical-nested-menu--docs"></a>
                <a target="_blank" className="uil-webComp-icon" href="https://designlanguage.accenture.com/storybook-static-web/index.html?path=/story/components-wcverticalnavigation--vertical-navigation"></a></div>)
              },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Component",
            sortable: true,
            editable: true,
          },
          {
            content: "Aliases",
            sortable: false,
            editable: true,
          },
          {
            content: "Figma asset",
            sortable: false,
            editable: true,
          },
          {
            content: "React",
            editable: true,
          },
          {
            content: "Angular",
            editable: true,
          },
          {
            content: "Web component",
            editable: true,
          },
          { content: "Notes", editable: true },
        ],

        tooltipPosition: "center",
      },
    },
  ],
  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};
export default componentData;
