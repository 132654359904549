import React, { Component } from "react";
import ComponentContext, {
  ComponentContextProvider,
} from "../context/ComponentsContext";
import ComponentData from "../static/ComponentData";
import UilTable from "../../../app-components/UilTable/UilTable";
import "./components.css";
let contextType = null;

export default class ComponentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableBody: [],
      value: "",
      filteredBody: [],
    };
  }

  componentDidMount() {
    this.handleTableBody();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchValue !== this.props.searchValue) {
      const filteredList = this.filterAutocompleteList(
        this.state.tableBody,
        this.props.searchValue
      );
      this.setState({ filteredBody: filteredList });
    }
    if (prevState.tableBody !== this.state.tableBody) {
      this.handleTableBody();
    }
  }

  //Function to set initial table body
  handleTableBody = () => {
    {
      ComponentData.UilComponentTableDetails.map((Content) => {
        var generate = Content.tableData.body;
        this.setState({
          tableBody: generate,
        });
      });
    }
  };

  //Function to filter table body
  filterAutocompleteList = (list, filter) => {
    let filteredList = [];
    if (filter) {
      filteredList = list.filter((listItem) => {
        return listItem.data[0].content
          .toLowerCase()
          .includes(filter.toLowerCase());
      });
    }
    return filteredList;
  };

  render() {
    return (
      <ComponentContextProvider>
        <ComponentContext.Consumer>
          {(componentContext) => {
            contextType = componentContext;
            return (
              <React.Fragment>
                {ComponentData.UilComponentTableDetails.map((Content) => {
                  return (
                    <div style={{minHeight: 1024 + "px"}}>
                      <span>
                        <UilTable
                          type={Content.type}
                          id={Content.id}
                          variantType={Content.variantType}
                          showEditableFields={false}
                          showHorizontalScrollbar={true}
                          disabled={false}
                          selectAll={false}
                          borderless={false}
                          bottomBorder={false}
                          dotMenuListStyles=""
                          defaultSortDirection=""
                          defaultSortColumn={Content.defaultSortColumn}
                          isgreyhoverEnabled={true}
                          contentPerPage={25}
                          isPaginationVisible={false}
                          tableData={{
                            body:
                              Object.keys(this.state.filteredBody).length > 0
                                ? this.state.filteredBody
                                : Content.tableData.body,
                            head: Content.tableData.head,
                            tooltipPosition: Content.tableData.tooltipPosition,
                          }}
                        ></UilTable>
                      </span>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          }}
        </ComponentContext.Consumer>
      </ComponentContextProvider>
    );
  }
}
