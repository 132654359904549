import React from 'react';

const indentStyle = {
  marginLeft: '40px'
};

const notIndentStyle = {
  marginLeft: '0px'
};

/**
 * @visibleName Error Boundary
 */
class UilErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: ''
    };
    this.showRows = this.showRows.bind(this);
  }

  showRows(text) {
    const rows = text.split('\n');
    return rows.map((row, index) => (
      <p key={index} style={index > 0 ? indentStyle : notIndentStyle}>
        {row}
      </p>
    ));
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      //return <h1>Something went wrong</h1>;
      return (
        <div className='uil-card-span-12'>
          <h2>Error: {this.state.errorMessage.name}</h2>
          {this.showRows(this.state.errorMessage.message)}
        </div>
      );
    }

    return this.props.children;
  }
}

export default UilErrorBoundary;
