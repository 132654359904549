import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_footnote.module.css";
const UilFootNote = (props) => {
  const {
    id,
    disabled,
    type,
    footnoteText,
    errorText,
    linkButtonText,
    footnoteColor,
    customClasses,
    linkFunction,
    customPadding,
  } = props;

  const typeClass = classNames({
    [styles["uil-text-info"]]: type === "info",
    [styles["uil-text-error"]]: type === "error",
    [styles["uil-text-success"]]: type === "success",
  });

  return (
    <div className={styles["uil-footnote-label"]} id={id} style={customClasses}>
      <div
        className={styles["uil-footnote-disabled"]}
        style={footnoteColor}
        disabled={disabled}
      >
        {type != "try again" && !linkButtonText && (
          <span className={typeClass} disabled={disabled}>
            {footnoteText}
          </span>
        )}
        {type != "try again" && linkButtonText && (
          <>
            <span className={typeClass} disabled={disabled}>
              {footnoteText}
              <span
                className={styles["uil-footnote-button-link"]}
                disabled={disabled}
                style={customPadding}
              >
                <span id="link" onClick={linkFunction}>
                  {linkButtonText}
                </span>
              </span>
            </span>
          </>
        )}
        {type === "try again" && (
          <>
            <span className={styles["uil-text-info"]} disabled={disabled}>{errorText}</span>
            <span
              className={styles["uil-text-footnote-error"]}
              style={customPadding}
              disabled={disabled}
            >
              <span id="link" onClick={linkFunction}>
                {footnoteText}
              </span>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

UilFootNote.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  footnoteText: PropTypes.any,
  errorText: PropTypes.element,
  linkButtonText: PropTypes.element,
  footnoteColor: PropTypes.any,
  customClasses: PropTypes.any,
  customPadding: PropTypes.any,
  linkFunction: PropTypes.func,
};

export default UilFootNote;
