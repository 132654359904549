import React, { Component } from "react";
import StyleContext, { StyleContextProvider } from "../context/StyleContext";
import UilTable from "../../../app-components/UilTable/UilTable";
import UilCard from "../../../app-components/UilCard/UilCard";
import ComponentData from "../static/ComponentData";
import Settings from "../static/settings";
import "./Style.css";
import{Link} from "react-router-dom"
let contextType = null;

export default class Colors extends Component {
  componentDidMount() {}
  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };

  render() {
    return (
      <StyleContextProvider>
        <StyleContext.Consumer>
          {(styleContext) => {
            contextType = styleContext;
            return (
              <React.Fragment>
                <div style={{
                  display:'flex',
                  flexDirection:'row',
                  gap:'20%'
                }}> 
                <div className="styleContentSection">
                <div className="colorSections">
                  <span className="colorHeadings">
                    {Settings.ColorContent.Titles.userInterfaceTitle}
                  </span>
                  <span className="colorContent">
                    {Settings.ColorContent.Descriptions.userInterfaceDesc}
                  </span>
                </div>
              
                <div>
                  {ComponentData.UilColorDetails.map((Content) => {
                    return (
                      <div id="divGreyScale">
                        <span className="colorHeadings">{Content.title}</span>
                        {Content.description.length > 0 && (
                          <span className="colorContent">
                            {Content.description}
                          </span>
                        )}

                        <span className="tableContent">
                          <UilTable
                            type="basic"
                            id="table_id"
                            variantType="defaultHeader"
                            showEditableFields={false}
                            showHorizontalScrollbar={true}
                            disabled={false}
                            selectAll={false}
                            borderless={false}
                            bottomBorder={false}
                            dotMenuListStyles=""
                            defaultSortDirection=""
                            defaultSortColumn="Firstname"
                            isgreyhoverEnabled={false}
                            contentPerPage={3}
                            isPaginationVisible={false}
                            tableData={Content.tableData}
                          ></UilTable>
                        </span>
                      </div>
                    );
                  })}
                </div>
                </div>
                <div style={{width:230}}>
                  <Link to="/">
                  <UilCard uppersection={ComponentData.UilCard.BasicUpperSection} 
                  ></UilCard>
                  </Link>
                  </div>
                  </div>
              </React.Fragment>
            );
          }}
        </StyleContext.Consumer>
      </StyleContextProvider>
    );
  }
}
