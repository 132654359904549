export const iconDataDL = [
  "uil-dl-icon-AIP_Logo",
  // "uil-dl-icon-Acc_Digital_Logo_All_Black-mod_17px",
  "uil-dl-icon-accenture-greater-than",
  "uil-dl-icon-Arrow-left",
  "uil-dl-icon-Arrow-right",
  "uil-dl-icon-Arrow-up",
  "uil-dl-icon-Arrow-down",
  "uil-dl-icon-Arrow-left-up",
  "uil-dl-icon-Arrow-right-up",
  "uil-dl-icon-Arrow-right-down",
  "uil-dl-icon-Arrow-left-down",
  "uil-dl-icon-Direction-left",
  "uil-dl-icon-Direction-right",
  "uil-dl-icon-Turn-left",
  "uil-dl-icon-Turn-right",
  "uil-dl-icon-Back-left",
  "uil-dl-icon-Back-right",
  "uil-dl-icon-Undo",
  "uil-dl-icon-Redo",
  "uil-dl-icon-Refresh",
  "uil-dl-icon-Trending-up",
  "uil-dl-icon-Trending-down",
  "uil-dl-icon-Crossing",
  "uil-dl-icon-Chevron-left",
  "uil-dl-icon-Chevron-right",
  "uil-dl-icon-Chevron-up",
  "uil-dl-icon-Chevron-down",
  "uil-dl-icon-Chevrons-right",
  "uil-dl-icon-Chevrons-left",
  "uil-dl-icon-Chevrons-up",
  "uil-dl-icon-Chevrons-down",
  "uil-dl-icon-Sorting",
  "uil-dl-icon-Return",
  "uil-dl-icon-Shuffle",
  "uil-dl-icon-Repeat",
  "uil-dl-icon-Rotate",
  "uil-dl-icon-Plus",
  "uil-dl-icon-Minus",
  "uil-dl-icon-Check",
  "uil-dl-icon-Close",
  "uil-dl-icon-Back",
  "uil-dl-icon-Forward",
  "uil-dl-icon-Pause",
  "uil-dl-icon-Previous",
  "uil-dl-icon-Next",
  "uil-dl-icon-Video",
  "uil-dl-icon-Send",
  "uil-dl-icon-Back-alt",
  "uil-dl-icon-Play",
  "uil-dl-icon-Power",
  "uil-dl-icon-Time",
  "uil-dl-icon-History",
  "uil-dl-icon-Timer",
  "uil-dl-icon-Stopwatch",
  "uil-dl-icon-Alarm",
  "uil-dl-icon-Upload",
  "uil-dl-icon-Download",
  "uil-dl-icon-Shift",
  "uil-dl-icon-Cloud-upload",
  "uil-dl-icon-Loading",
  "uil-dl-icon-Sound-low",
  "uil-dl-icon-Sound-medium",
  "uil-dl-icon-Sound-high",
  "uil-dl-icon-Mute",
  "uil-dl-icon-Voicemail",
  "uil-dl-icon-Music",
  "uil-dl-icon-Microphone",
  "uil-dl-icon-Cellular",
  "uil-dl-icon-Battery-orizontal",
  "uil-dl-icon-Battery-vertical",
  "uil-dl-icon-Battery-full",
  "uil-dl-icon-Battery-medium",
  "uil-dl-icon-Battery-low",
  "uil-dl-icon-Battery-charging",
  "uil-dl-icon-Danger",
  "uil-dl-icon-Error",
  "uil-dl-icon-Info",
  "uil-dl-icon-Help",
  "uil-dl-icon-Spam",
  "uil-dl-icon-No-entry",
  "uil-dl-icon-Add",
  "uil-dl-icon-Remove",
  "uil-dl-icon-Success",
  "uil-dl-icon-Cancel",
  "uil-dl-icon-Not-allowed",
  "uil-dl-icon-Circle-arrow-up",
  "uil-dl-icon-Circle-arrow-down",
  "uil-dl-icon-Circle-arrow-left",
  "uil-dl-icon-Circle-arrow-right",
  "uil-dl-icon-Circle",
  "uil-dl-icon-Octagon",
  "uil-dl-icon-Esagon",
  "uil-dl-icon-Pentagon",
  "uil-dl-icon-Star",
  "uil-dl-icon-Triangle",
  "uil-dl-icon-Heart",
  "uil-dl-icon-Square-small",
  "uil-dl-icon-Square",
  "uil-dl-icon-Placeholder",
  "uil-dl-icon-Home",
  "uil-dl-icon-Home-alt",
  "uil-dl-icon-Hand_1",
  "uil-dl-icon-Hand",
  "uil-dl-icon-Thumb-up",
  "uil-dl-icon-Thumb-down",
  "uil-dl-icon-People",
  "uil-dl-icon-Person",
  "uil-dl-icon-User",
  "uil-dl-icon-Face-happy",
  "uil-dl-icon-Face-neutral",
  "uil-dl-icon-Face-sad",
  "uil-dl-icon-Chat",
  "uil-dl-icon-Chat-alt",
  "uil-dl-icon-Hide-alt",
  "uil-dl-icon-Eye",
  "uil-dl-icon-Hide",
  "uil-dl-icon-Wheelchair",
  "uil-dl-icon-Compass",
  "uil-dl-icon-Search",
  "uil-dl-icon-Lightbulb",
  "uil-dl-icon-Zoom-in",
  "uil-dl-icon-Zoom-out",
  "uil-dl-icon-Lock-alt-open",
  "uil-dl-icon-Lock-alt-closed",
  "uil-dl-icon-Lock-open",
  "uil-dl-icon-Lock-closed",
  "uil-dl-icon-Key",
  "uil-dl-icon-Theft",
  "uil-dl-icon-Table-horizontal",
  "uil-dl-icon-Table-vertical",
  "uil-dl-icon-Grid-9-circles",
  "uil-dl-icon-Grid-9-squares",
  "uil-dl-icon-Grid-small",
  "uil-dl-icon-Grid",
  "uil-dl-icon-Component",
  "uil-dl-icon-List-alt",
  "uil-dl-icon-List",
  "uil-dl-icon-Dashboard",
  "uil-dl-icon-Align-type-justify",
  "uil-dl-icon-Vertical",
  "uil-dl-icon-Stack",
  "uil-dl-icon-Filter-alt",
  "uil-dl-icon-Filter",
  "uil-dl-icon-More-horizontal",
  "uil-dl-icon-More-vertical",
  "uil-dl-icon-Burger",
  "uil-dl-icon-Bell",
  "uil-dl-icon-Feed",
  "uil-dl-icon-Pie-chart",
  "uil-dl-icon-Bar-chart",
  "uil-dl-icon-Line-chart",
  "uil-dl-icon-Activity",
  "uil-dl-icon-Keyboard",
  "uil-dl-icon-Mouse",
  "uil-dl-icon-Print",
  "uil-dl-icon-Laptop",
  "uil-dl-icon-Tv",
  "uil-dl-icon-Tablet",
  "uil-dl-icon-Smartphone",
  "uil-dl-icon-iPhone",
  "uil-dl-icon-Contact-book",
  "uil-dl-icon-Phone",
  "uil-dl-icon-Suitcase-alt",
  "uil-dl-icon-Suitcase",
  "uil-dl-icon-Credit-card",
  "uil-dl-icon-Coffee",
  "uil-dl-icon-Restaurant",
  "uil-dl-icon-Wine-glass",
  "uil-dl-icon-Glasses",
  "uil-dl-icon-Watch",
  "uil-dl-icon-News",
  "uil-dl-icon-Hourglass",
  "uil-dl-icon-Cup",
  "uil-dl-icon-Anchor",
  "uil-dl-icon-Support",
  "uil-dl-icon-Headphones",
  "uil-dl-icon-Megaphone",
  "uil-dl-icon-Typography",
  "uil-dl-icon-Typography-alt",
  "uil-dl-icon-Stats",
  "uil-dl-icon-Align-type-left",
  "uil-dl-icon-Align-type-center",
  "uil-dl-icon-Align-type-right",
  "uil-dl-icon-Envelope-alt",
  "uil-dl-icon-Envelope",
  "uil-dl-icon-At",
  "uil-dl-icon-Paperclip",
  "uil-dl-icon-Inbox",
  "uil-dl-icon-Box-alt",
  "uil-dl-icon-Box",
  "uil-dl-icon-Save",
  "uil-dl-icon-Folder-add",
  "uil-dl-icon-Folder",
  "uil-dl-icon-Book-open",
  "uil-dl-icon-Book",
  "uil-dl-icon-Notebook",
  "uil-dl-icon-Edit",
  "uil-dl-icon-New",
  "uil-dl-icon-Clipboard-alt",
  "uil-dl-icon-Calendar",
  "uil-dl-icon-Clipboard",
  "uil-dl-icon-Copy",
  "uil-dl-icon-Cards",
  "uil-dl-icon-Sticker",
  "uil-dl-icon-Page",
  "uil-dl-icon-File-success",
  "uil-dl-icon-File-error",
  "uil-dl-icon-Bin",
  "uil-dl-icon-Delete",
  "uil-dl-icon-Cut",
  "uil-dl-icon-Crop",
  "uil-dl-icon-Minimize",
  "uil-dl-icon-Maximise",
  "uil-dl-icon-Map",
  "uil-dl-icon-Location",
  "uil-dl-icon-Geofence-violation",
  "uil-dl-icon-Cursor",
  "uil-dl-icon-Bike",
  "uil-dl-icon-Plane",
  "uil-dl-icon-Train",
  "uil-dl-icon-Car",
  "uil-dl-icon-Fleet",
  "uil-dl-icon-Car-alt",
  "uil-dl-icon-Crash",
  "uil-dl-icon-Truck",
  "uil-dl-icon-Speed",
  "uil-dl-icon-Cart",
  "uil-dl-icon-Basket",
  "uil-dl-icon-Bag",
  "uil-dl-icon-Tag",
  "uil-dl-icon-Tag-alt",
  "uil-dl-icon-Signal-alt",
  "uil-dl-icon-Signal",
  "uil-dl-icon-Wifi",
  "uil-dl-icon-Rss",
  "uil-dl-icon-Radio",
  "uil-dl-icon-Bluetooth",
  "uil-dl-icon-Euro",
  "uil-dl-icon-Dollar",
  "uil-dl-icon-Yen",
  "uil-dl-icon-Film",
  "uil-dl-icon-Camera",
  "uil-dl-icon-Camera-rear",
  "uil-dl-icon-Aperture",
  "uil-dl-icon-Bolt",
  "uil-dl-icon-Settings",
  "uil-dl-icon-Tool",
  "uil-dl-icon-Controls",
  "uil-dl-icon-Link",
  "uil-dl-icon-QRcode",
  "uil-dl-icon-Image",
  "uil-dl-icon-Colors",
  "uil-dl-icon-Drop",
  "uil-dl-icon-Layers",
  "uil-dl-icon-Window",
  "uil-dl-icon-Layout-left",
  "uil-dl-icon-Layout-right",
  "uil-dl-icon-Align-left",
  "uil-dl-icon-Align-top",
  "uil-dl-icon-Align-center-horizontally",
  "uil-dl-icon-Align-center-vertically",
  "uil-dl-icon-Align-right",
  "uil-dl-icon-Align-bottom",
  "uil-dl-icon-Share-ios",
  "uil-dl-icon-Entrace",
  "uil-dl-icon-Exit",
  "uil-dl-icon-Share-android",
  "uil-dl-icon-Flag",
  "uil-dl-icon-Shield",
  "uil-dl-icon-Education",
  "uil-dl-icon-Diploma",
  "uil-dl-icon-Award",
  "uil-dl-icon-HDR",
  "uil-dl-icon-PDF",
  "uil-dl-icon-XLSX",
  "uil-dl-icon-XLS",
  "uil-dl-icon-CSV",
  "uil-dl-icon-TXT",
  "uil-dl-icon-DOCX",
  "uil-dl-icon-DOC",
  "uil-dl-icon-JPG",
  "uil-dl-icon-GIF",
  "uil-dl-icon-MP3",
  "uil-dl-icon-MP4",
  "uil-dl-icon-PNG",
  "uil-dl-icon-Globe",
  "uil-dl-icon-Thermometer",
  "uil-dl-icon-Sun-cloud",
  "uil-dl-icon-Cloud",
  "uil-dl-icon-Snow",
  "uil-dl-icon-Thunder",
  "uil-dl-icon-Rain",
  "uil-dl-icon-Umbrella",
  "uil-dl-icon-Sun",
  "uil-dl-icon-Sunrise",
  "uil-dl-icon-Moon",
  "uil-dl-icon-Sea",
  "uil-dl-icon-Coding",
  "uil-dl-icon-Bug",
  "uil-dl-icon-Hashtag",
  "uil-dl-icon-Diamond",
  "uil-dl-icon-Rocket",
  "uil-dl-icon-Stark",
  "uil-dl-icon-Shield-alt",
  "uil-dl-icon-Disc",
  "uil-dl-icon-Circle-filled",
  "uil-dl-icon-information-point",
  "uil-dl-icon-question-point",
  "uil-dl-icon-Disc-filled",
  "uil-dl-icon-Import",
  "uil-dl-icon-Distance",
  "uil-dl-icon-Crowd",
  "uil-dl-icon-Facemask",
  "uil-dl-icon-Flag-filled",
  "uil-dl-icon-Bookmark",
  "uil-dl-icon-Bookmark-filled",
  "uil-dl-icon-Heart-filled",
  "uil-dl-icon-Star-filled",
  "uil-dl-icon-Dynamic-Devices-Default",
  "uil-dl-icon-Object-Detector",
  "uil-dl-icon-Helmet-Detector",
  "uil-dl-icon-Bracelet",
  "uil-dl-icon-Industrial-Machine",
  "uil-dl-icon-Path-Checking-Detector",
  "uil-dl-icon-People-Counter",
  "uil-dl-icon-Tank",
  "uil-dl-icon-Occupancy",
  "uil-dl-icon-Circle-chevron-down",
  "uil-dl-icon-Circle-chevron-up",
  "uil-dl-icon-Circle-chevron-left",
  "uil-dl-icon-Circle-chevron-right",
  "uil-dl-icon-Fog",
  "uil-dl-icon-Haze",
  "uil-dl-icon-Sandstorm",
  "uil-dl-icon-Storm",
  "uil-dl-icon-External-link",
  "uil-dl-icon-Factory",
  "uil-dl-icon-Manufacturing",
  "uil-dl-icon-Manufacture",
  "uil-dl-icon-Folder-Open",
  "uil-dl-new-Shadow"
];
export const iconDataAPS = [
  "uil-icon-nav-arrow-back",
  "uil-icon-APS-arrow-right-filled",
  "uil-icon-APS-Disk-filled",
  "uil-icon-APS-date-range-filled",
  "uil-icon-APS-fullscreen-exit-filled",
  "uil-icon-APS-fullscreen-filled",
  "uil-icon-APS-move-filled",
  "uil-icon-APS-zoom-out-filled",
  "uil-icon-APS-delete-single-filled",
  "uil-icon-APS-delete-all-filled",
  "uil-icon-APS-Move",
  "uil-icon-APS-Accounts",
  "uil-icon-APS-Dataset",
  "uil-icon-APS-Jobs",
  "uil-icon-APS-Sources",
  "uil-icon-APS-play_widget1",
  "uil-icon-APS-Default-CS",
  "uil-icon-APS-Default-CM",
  "uil-icon-APS-Default-G",
  "uil-icon-APS-Default-R",
  "uil-icon-APS-Default-S",
  "uil-icon-APS-Focused-Pressed-CS",
  "uil-icon-APS-Focused-Pressed-CM",
  "uil-icon-APS-Focused-Pressed-G",
  "uil-icon-APS-Focused-Pressed-R",
  "uil-icon-APS-Focused-Pressed-S",
  "uil-icon-APS-Toggle-Default-CS",
  "uil-icon-APS-Toggle-Default-CM",
  "uil-icon-APS-Toggle-Default-G",
  "uil-icon-APS-Toggle-Default-R",
  "uil-icon-APS-Toggle-Default-S",
  "uil-icon-APS-CS-icon",
  "uil-icon-APS-BG-filled"
];

export const iconTags = [
  {
    key: ["arrow", "direction", "pointing"],
    values: [
      "uil-dl-icon-Arrow-left",
      "uil-dl-icon-Arrow-right",
      "uil-dl-icon-Arrow-up",
      "uil-dl-icon-Arrow-down",
      "uil-dl-icon-Arrow-left-up",
      "uil-dl-icon-Arrow-right-up",
      "uil-dl-icon-Arrow-right-down",
      "uil-dl-icon-Arrow-left-down",
      "uil-dl-icon-Direction-left",
      "uil-dl-icon-Direction-right",
      "uil-dl-icon-Turn-left",
      "uil-dl-icon-Turn-right",
      "uil-dl-icon-Back-left",
      "uil-dl-icon-Back-right",
      "uil-dl-icon-Undo",
      "uil-dl-icon-Redo",
      "uil-dl-icon-Refresh"
    ]
  },
  {
    key: ["weather", "rain", "forecast"],
    values: [
      "uil-dl-icon-Thermometer",
      "uil-dl-icon-Sun-cloud",
      "uil-dl-icon-Cloud",
      "uil-dl-icon-Snow",
      "uil-dl-icon-Thunder",
      "uil-dl-icon-Rain",
      "uil-dl-icon-Umbrella",
      "uil-dl-icon-Sun",
      "uil-dl-icon-Sunrise",
      "uil-dl-icon-Moon",
      "uil-dl-new-Shadow"
    ]
  },
  {
    key: ["office", "work"],
    values: [
      "uil-dl-icon-Envelope-alt",
      "uil-dl-icon-Envelope",
      "uil-dl-icon-At",
      "uil-dl-icon-Paperclip",
      "uil-dl-icon-Inbox",
      "uil-dl-icon-Box-alt",
      "uil-dl-icon-Save",
      "uil-dl-icon-Folder-add",
      "uil-dl-icon-Folder",
      "uil-dl-icon-Book-open",
      "uil-dl-icon-Book",
      "uil-dl-icon-Notebook",
      "uil-dl-icon-Edit",
      "uil-dl-icon-New",
      "uil-dl-icon-Clipboard-alt",
      "uil-dl-icon-Calendar",
      "uil-dl-icon-Clipboard",
      "uil-dl-icon-Sticker",
      "uil-dl-icon-Page",
      "uil-dl-icon-File-success",
      "uil-dl-icon-File-error",
      "uil-dl-icon-Bin",
      "uil-dl-icon-PDF",
      "uil-dl-icon-XLSX",
      "uil-dl-icon-XLS",
      "uil-dl-icon-CSV",
      "uil-dl-icon-TXT",
      "uil-dl-icon-DOCX",
      "uil-dl-icon-DOC",
      "uil-dl-icon-JPG",
      "uil-dl-icon-GIF",
      "uil-dl-icon-MP3",
      "uil-dl-icon-MP4",
      "uil-dl-icon-PNG"
    ]
  },
  {
    key: ["file", "extension"],
    values: [
      "uil-dl-icon-File-success",
      "uil-dl-icon-File-error",
      "uil-dl-icon-Bin",
      "uil-dl-icon-PDF",
      "uil-dl-icon-XLSX",
      "uil-dl-icon-XLS",
      "uil-dl-icon-CSV",
      "uil-dl-icon-TXT",
      "uil-dl-icon-DOCX",
      "uil-dl-icon-DOC",
      "uil-dl-icon-JPG",
      "uil-dl-icon-GIF",
      "uil-dl-icon-MP3",
      "uil-dl-icon-MP4",
      "uil-dl-icon-PNG"
    ]
  },
  {
    key: ["layout", "align"],
    values: [
      "uil-dl-icon-Layers",
      "uil-dl-icon-Window",
      "uil-dl-icon-Layout-left",
      "uil-dl-icon-Layout-right",
      "uil-dl-icon-Align-left",
      "uil-dl-icon-Align-top",
      "uil-dl-icon-Align-center-horizontally",
      "uil-dl-icon-Align-center-vertically",
      "uil-dl-icon-Align-right",
      "uil-dl-icon-Align-bottom"
    ]
  },
  {
    key: ["locked", "closed"],
    values: [
      "uil-dl-icon-Lock-alt-open",
      "uil-dl-icon-Lock-alt-closed",
      "uil-dl-icon-Lock-open",
      "uil-dl-icon-Lock-closed",
      "uil-dl-icon-Key",
      "uil-dl-icon-Theft"
    ]
  },
  {
    key: ["food", "eat", "drink"],
    values: [
      "uil-dl-icon-Cup",
      "uil-dl-icon-Coffee",
      "uil-dl-icon-Restaurant",
      "uil-dl-icon-Wine-glass"
    ]
  },
  {
    key: ["money", "currency"],
    values: [
      "uil-dl-icon-Euro",
      "uil-dl-icon-Dollar",
      "uil-dl-icon-Yen",
      "uil-dl-icon-Credit-card"
    ]
  },
  {
    key: ["time", "clock"],
    values: [
      "uil-dl-icon-Time",
      "uil-dl-icon-History",
      "uil-dl-icon-Timer",
      "uil-dl-icon-Stopwatch",
      "uil-dl-icon-Alarm",
      "uil-dl-icon-Hourglass"
    ]
  },
  {
    key: ["notification", "warning"],
    values: [
      "uil-dl-icon-Danger",
      "uil-dl-icon-Error",
      "uil-dl-icon-Info",
      "uil-dl-icon-Help",
      "uil-dl-icon-Spam",
      "uil-dl-icon-No-entry",
      "uil-dl-icon-Not-allowed"
    ]
  },
  {
    key: ["shop", "buying"],
    values: [
      "uil-dl-icon-Credit-card",
      "uil-dl-icon-Cart",
      "uil-dl-icon-Basket",
      "uil-dl-icon-Bag",
      "uil-dl-icon-Tag",
      "uil-dl-icon-Tag-alt"
    ]
  },
  {
    key: ["vehicle", "transport", "traffic"],
    values: [
      "uil-dl-icon-Bike",
      "uil-dl-icon-Plane",
      "uil-dl-icon-Train",
      "uil-dl-icon-Car",
      "uil-dl-icon-Fleet",
      "uil-dl-icon-Car-alt",
      "uil-dl-icon-Crash",
      "uil-dl-icon-Truck",
      "uil-dl-icon-Speed",
      "uil-dl-icon-Cart",
      "uil-dl-icon-Wheelchair"
    ]
  },
  {
    key: ["waves", "radio", "signal", "connection"],
    values: [
      "uil-dl-icon-Signal-alt",
      "uil-dl-icon-Signal",
      "uil-dl-icon-Wifi",
      "uil-dl-icon-Rss",
      "uil-dl-icon-Radio",
      "uil-dl-icon-Bluetooth"
    ]
  },
  {
    key: ["communication", "connect", "phone", "call", "mobile"],
    values: [
      "uil-dl-icon-Voicemail",
      "uil-dl-icon-Microphone",
      "uil-dl-icon-Cellular",
      "uil-dl-icon-Battery-orizontal",
      "uil-dl-icon-Battery-vertical",
      "uil-dl-icon-Battery-full",
      "uil-dl-icon-Battery-medium",
      "uil-dl-icon-Battery-low",
      "uil-dl-icon-Battery-charging",
      "uil-dl-icon-Laptop",
      "uil-dl-icon-Tv",
      "uil-dl-icon-Tablet",
      "uil-dl-icon-Smartphone",
      "uil-dl-icon-iPhone",
      "uil-dl-icon-Contact-book",
      "uil-dl-icon-Phone",
      "uil-dl-icon-Wifi",
      "uil-dl-icon-Rss",
      "uil-dl-icon-Radio",
      "uil-dl-icon-Bluetooth"
    ]
  },
  {
    key: ["sound", "record", "play", "audio"],
    values: [
      "uil-dl-icon-Back",
      "uil-dl-icon-Forward",
      "uil-dl-icon-Pause",
      "uil-dl-icon-Previous",
      "uil-dl-icon-Next",
      "uil-dl-icon-Video",
      "uil-dl-icon-Send",
      "uil-dl-icon-Back-alt",
      "uil-dl-icon-Play",
      "uil-dl-icon-Shuffle",
      "uil-dl-icon-Repeat",
      "uil-dl-icon-Loading",
      "uil-dl-icon-Sound-low",
      "uil-dl-icon-Sound-medium",
      "uil-dl-icon-Sound-high",
      "uil-dl-icon-Mute",
      "uil-dl-icon-Voicemail",
      "uil-dl-icon-Music",
      "uil-dl-icon-Microphone",
      "uil-dl-icon-Chat",
      "uil-dl-icon-Chat-alt",
      "uil-dl-icon-Bell",
      "uil-dl-icon-Support",
      "uil-dl-icon-Headphones",
      "uil-dl-icon-Megaphone",
      "uil-dl-icon-Film",
      "uil-dl-icon-Camera",
      "uil-dl-icon-Camera-rear",
      "uil-dl-icon-Aperture"
    ]
  },
  {
    key: ["camera", "photo"],
    values: [
      "uil-dl-icon-Film",
      "uil-dl-icon-Camera",
      "uil-dl-icon-Camera-rear",
      "uil-dl-icon-Aperture",
      "uil-dl-icon-JPG",
      "uil-dl-icon-GIF",
      "uil-dl-icon-MP3",
      "uil-dl-icon-MP4",
      "uil-dl-icon-PNG"
    ]
  },
  {
    key: ["feeling", "emotion"],
    values: [
      "uil-dl-icon-Thumb-up",
      "uil-dl-icon-Thumb-down",
      "uil-dl-icon-Face-happy",
      "uil-dl-icon-Face-neutral",
      "uil-dl-icon-Face-sad"
    ]
  },
  {
    key: ["accessory"],
    values: [
      "uil-dl-icon-Glasses",
      "uil-dl-icon-Watch",
      "uil-dl-icon-News",
      "uil-dl-icon-Basket",
      "uil-dl-icon-Bag",
      "uil-dl-icon-Credit-card",
      "uil-dl-icon-Suitcase-alt",
      "uil-dl-icon-Suitcase"
    ]
  },
  {
    key: ["computer", "devices"],
    values: [
      "uil-dl-icon-Battery-orizontal",
      "uil-dl-icon-Battery-vertical",
      "uil-dl-icon-Battery-full",
      "uil-dl-icon-Battery-medium",
      "uil-dl-icon-Battery-low",
      "uil-dl-icon-Battery-charging",
      "uil-dl-icon-Tablet",
      "uil-dl-icon-Smartphone",
      "uil-dl-icon-iPhone",
      "uil-dl-icon-Laptop",
      "uil-dl-icon-Keyboard",
      "uil-dl-icon-Mouse",
      "uil-dl-icon-Print",
      "uil-dl-icon-At",
      "uil-dl-icon-Save",
      "uil-dl-icon-File-success",
      "uil-dl-icon-File-error",
      "uil-dl-icon-Bin",
      "uil-dl-icon-PDF",
      "uil-dl-icon-XLSX",
      "uil-dl-icon-XLS",
      "uil-dl-icon-CSV",
      "uil-dl-icon-TXT",
      "uil-dl-icon-DOCX",
      "uil-dl-icon-DOC"
    ]
  }
];
