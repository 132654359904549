import React, { useState,useEffect } from "react";
import PropTypes from 'prop-types';
import { usePagination, DOTS } from './usePagination';
import styles from "./uil_pagination.module.css";
import classNames from "classnames";
import UilTextField from '../UilTextField/UilTextField';


const UilPagination = props => {
  const {
    onChange,
    totalRecordsCount,
    currentPage,
    id,
    pageSize,
    paginationSize,
    paginationView,
    multiViewStyle,
  } = props;
  const[data,setData]=useState(currentPage);
  const[total,setTotal]=useState(totalRecordsCount);
  const[isActive,setIsActive]=useState(false);
  useEffect( ()=> {
    setData(currentPage)
  },[currentPage])

  useEffect( () =>{
    if(total>=data){
      setData(currentPage)
      }
      else{
        setData(1);
        onChange(1);
      }
  },[data, pageSize, total]
 )
  useEffect (()=>{
    setTotal(Math.round(totalRecordsCount / pageSize) )
      },[pageSize,totalRecordsCount])

  const paginationRange = usePagination({
    currentPage,
    totalRecordsCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  //functionality for on click on arrow left and arrow right
  const onNext =() => {
      setData(+data +1);
      onChange(+data +1);     
  };

  const onPrevious = () => {
      setData(+data -1);
      onChange(+data -1);
  };

  const onOneViewChange = (event) => {
    setData(event.target.value)
    onChange(event.target.value);
  };
  let lastPage = paginationRange[paginationRange.length - 1];

  //declared for pagination size style and multipageview style
  const PaginationSizeStyle = `${styles["pagination-item"]} ${styles[paginationSize]}`;
  const MultipageViewStyle = `${PaginationSizeStyle} ${styles[multiViewStyle]}`;

  return (
    <>
      {/* functionality for when paginationView is selected as MultiView */}
      {(paginationView === "MultiView") && (
        <ul
          className={classNames(styles['pagination-container'])}
        >
          <li
            className={classNames(styles['pagination-arrow'], {
              [styles.disabled]: currentPage === 1
            })}
            onClick={onPrevious}
          >
            <div className={styles["arrowleft"]}/>
          </li>
          {paginationRange.map(pageNumber => {
            if (pageNumber === DOTS) {
              return <li htmlFor={id}  className= {classNames([styles["pagination-item"]] ,[styles[ "dots"]])}>{DOTS}</li>;
            }
            return (
              <li
                className={classNames(`${MultipageViewStyle}`, {
                [styles.selected]: pageNumber === currentPage
                })}
                onClick={() => {onChange(pageNumber);setData(pageNumber);}}
              >
                {pageNumber}
              </li>
            );
          })}
                 
          <li
            className={classNames(styles['pagination-arrow'], {
              [styles.disabled]: currentPage === lastPage
            })}
            onClick={onNext}
          >
            <div className={styles["arrowright"]}/>
          </li>
            
        </ul>
      )}

      {/* functionality for when paginationView is selected as OneView */}
      {(paginationView === "OneView") && (
        <ul
          className={classNames(styles['pagination-container'])}
        >
          <li
            className={classNames(styles['pagination-arrow'], {
              [styles.disabled]: parseInt(currentPage) === 1
            })}
            onClick={onPrevious}
          >
            <div className={styles["arrowleft"]} />
          </li>
          
          <li
            className={classNames(`${PaginationSizeStyle} ${styles[multiViewStyle]}`, {
              [styles.selected]:  (data===currentPage && multiViewStyle==='Branded')||isActive
            })}
            onClick={()=>setIsActive(true)}
            onBlur={()=>setIsActive(false)}>
              <UilTextField 
              value={currentPage} 
              customClasses={classNames(`${styles["uil-text-area-pagination"]}  ${styles[multiViewStyle]} `)}
              onChange={onOneViewChange}
              className={classNames(`${PaginationSizeStyle} ${MultipageViewStyle}`, {
              [styles.selected]: data ===  currentPage 
              })}
              onKeyUp={onOneViewChange}
              customStyles={{textAlign: "center", padding: 0, fontWeight: "500",fontSize: 16, cursor: "pointer" }}
            ></UilTextField>
            </li>
           
          <li className= {classNames([styles["pagination-arrow"]] ,[styles[ "pagination-text"]])}> of {total}</li>

          <li
            className={classNames(styles['pagination-arrow'], {
              [styles.disabled]: parseInt(currentPage) === lastPage
            })}
            onClick={onNext}
          >
            <div className={styles["arrowright"]} />
          </li>
        </ul>
      )}
    </>
  );
};

UilPagination.defaultProps = {
  currentPage:1,
  paginationSize: 'Small',
  paginationView: 'MultiView',
  multiViewStyle: 'Default'
};

UilPagination.propTypes = {
  onChange: PropTypes.func,                     //onChange function to change the pagenumber
  totalRecordsCount: PropTypes.any,             //totalRecordsCount is the total count of the data
  currentPage: PropTypes.any,                   //current active page
  pageSize: PropTypes.any,                      //maximum data to be shown on a page
  id: PropTypes.string,
  paginationSize: PropTypes.string,              //pagination box size small/large
  paginationView: PropTypes.string,              //pagination view as OneView or MultiView
  multiViewStyle: PropTypes.string,               //multiView style to be passed as Style1/Style2
  onPageChange:PropTypes.func,
};


export default UilPagination;