import React, { Component } from "react";
import ComponentData from "../static/ComponentData";
import ContentBlock from "../../../app-components/ContentBlock";
import UilBanner from "../../../app-components/UilBanner/UilBanner";
import backgroundColorBanner from "../../../assets/gradients/Hero_Banner_designers.svg";
import GetStarted from "./get_started";
import UilTabBar from "../../../app-components/UilTab/UilTabBar";
import "./designers.css";
import DesignerContext, {
  DesignerContextProvider,
} from "../context/DesignersContext";
import Asset_Library from "./asset_lib_page";
import DesignToken from "./design_token_page";
import withRouter from "../../withRouter";
let contextType = null;

class Designers_page extends Component {
  componentDidMount() {
    let selectedTab = this.props.params.tabid;
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  }

  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabTitle);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };

  render() {
    return (
      <DesignerContextProvider>
        <DesignerContext.Consumer>
          {(designerContext) => {
            contextType = designerContext;
            return (
              <React.Fragment>
                <div class="div-tabbar">
                  <UilTabBar
                    isMultiSelect={false}
                    onChildClick={(e) => this.onTabBarClick(e)}
                    onTabClick={(e) => this.onTabBarClick(e)}
                    preselectTab={[contextType.state.selectedTabIndex]}
                    tabOrientation="horizontal"
                    tabs={ComponentData.UilTabBar.tabs}
                    type="defaultwithoutBorder"
                  />
                </div>{" "}
                <div classname="bodySection">
                  <div className="uil-banner-section">
                  <UilBanner
                    type={ComponentData.UilBanner.type}
                    size={ComponentData.UilBanner.size}
                    title={contextType.state.tabTitle}
                    content={contextType.state.selelectedTabDesc}
                    backgroundImage={
                        backgroundColorBanner
                    }
                    customClasses={ComponentData.UilBanner.customClasses}
                    showTitle={true}
                    showContent={true}
                    showSubHeading={false}
                    leftSpacing={ComponentData.UilBanner.leftSpacing}
                    showFooter={
                      contextType.state.selectedTabIndex !== "3" &&
                      ComponentData.UilBanner.showFooter
                    }
                  ></UilBanner></div>
                  <ContentBlock >
                    <div className="styleContentSection">
                      {contextType.state.selectedTabIndex === "1" ? (
                      <GetStarted></GetStarted>
                    ) : contextType.state.selectedTabIndex === "2" ? (<Asset_Library></Asset_Library>):
                    <DesignToken></DesignToken>}
                    </div>
                  </ContentBlock>
                </div>
              </React.Fragment>
            );
          }}
        </DesignerContext.Consumer>
      </DesignerContextProvider>
    );
  }
}
export default withRouter(Designers_page);