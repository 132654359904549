import React, { Component } from "react";
import UilBanner from "../../../app-components/UilBanner/UilBanner";
import UilTabBar from "../../../app-components/UilTab/UilTabBar";
import backgroundStyleBanner from "../../../assets/gradients/Hero_Banner_styles.svg";
import ComponentData from "../static/ComponentData";
import ContentBlock from "../../../app-components/ContentBlock";
import StyleContext, { StyleContextProvider } from "../context/StyleContext";
import Colors from "./Colors";
import Icon from "./Icon";
import Typography from "./Typography";
import "./Style.css";
import withRouter from "../../withRouter";
import GoToTop from "app/goToTop";
let contextType = null;

class Style extends Component {
  componentDidMount() {
    let selectedTab = this.props.params.tabid;
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  }
  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };

  render() {
    return (
      <StyleContextProvider>
        <StyleContext.Consumer>
          {(styleContext) => {
            contextType = styleContext;
            return (
              <React.Fragment>
                <div class="div-tabbar">
                  <UilTabBar
                    isMultiSelect={false}
                    onChildClick={(e) => this.onTabBarClick(e)}
                    onTabClick={(e) => this.onTabBarClick(e)}
                    preselectTab={[contextType.state.selectedTabIndex]}
                    tabOrientation="horizontal"
                    tabs={ComponentData.UilTabBar.tabs}
                    type="defaultwithoutBorder"
                  />
                </div>{" "}
                <div classname="bodySection">
                <div className="uil-banner-section">
                  <UilBanner
                    type={ComponentData.UilBanner.type}
                    size={ComponentData.UilBanner.size}
                    title={contextType.state.selectedTab}
                    content={contextType.state.selelectedTabDesc}
                    backgroundImage={backgroundStyleBanner}
                    customClasses={ComponentData.UilBanner.customClasses}
                    showTitle={true}
                    showContent={true}
                    showSubHeading={false}
                    leftSpacing={ComponentData.UilBanner.leftSpacing}
                  ></UilBanner></div>
                  <ContentBlock section>
                    {contextType.state.selectedTabIndex === "1" ? (
                      <Colors></Colors>
                    ) : contextType.state.selectedTabIndex === "2" ? (
                      <Typography></Typography>
                    ) : (
                      <Icon></Icon>
                    )}
                  </ContentBlock>
                  <GoToTop></GoToTop>
                </div>
              </React.Fragment>
            );
          }}
        </StyleContext.Consumer>
      </StyleContextProvider>
    );
  }
}
export default withRouter(Style);
