import React, { useState, useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import styles from './uil_inpagenavigation.module.css';
import classNames from "classnames";

// Note, uil-in-page navigation height may need recalculating
// when the page is resized or page scrolled so that footer
// reduces the inpage-navigation height.
// This is done with jQuery in html reference.
const UilInPageNavigationContainer = ({
  horizontal,
  alignWithContent,
  inPageNavMenuLeftOffset,
  navbarHeight,
  contentPadding,
  arrowPadding,
  customClasses,
  customStyles,
  children,
  disabled
}) => {
  const inpageNavRef = useRef();
  const inpageNavMenuRef = useRef();
  const [curtainOpen, setCurtainOpen] = useState(false);
  const [height, setHeight]=useState();
  const [menuCarousel, setMenuCarousel] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(-1);
  const [horizontalInpageNavCollapsed, setHorizontalInpageNavCollapsed] = useState(false);

  useEffect(() => {
    resizeInPageNavigation();
  });

  const inpageNavCurtainToggleHandler = () => {
    setCurtainOpen(!curtainOpen);
  };

  const inpageNavCarouselLeftHandler = () => {
    //Scrollindex 0 means moving to left;
    setScrollIndex(0);
  };

  const inpageNavCarouselRightHandler = () => {
    // Scrolling is not needed when the menu fits to the in-page navigation area.
    // MenuCarousel if true, when the menu doesn't fit to area. If it fits, the scrolling is not needed.
    if (menuCarousel) {
      //Scrollindex 1 means moving to right;
      setScrollIndex(1);
    }
  };
  const stateFun = state =>state === styles['in_page_nav_collapsed'] || state === styles['in_page_nav_collapsed'];
  const horizontalFun = horizontalMenuCollapsed =>(!horizontal ) || horizontalMenuCollapsed;
  const resizeInPageNavigation = () => {
    let horizontalMenuCollapsed = false;
    if (horizontal) {
      const inpageNav = inpageNavRef.current;
      const state = window.getComputedStyle(inpageNav, ':before').content;
      if (stateFun(state)) {
        horizontalMenuCollapsed = true;
      }
    }

    setHorizontalInpageNavCollapsed(horizontalMenuCollapsed);

    if (horizontalFun(horizontalMenuCollapsed)) {
      // Distance from inpage-nav to top of the page.
      let topDistance = inpageNavRef.current.getBoundingClientRect().top;

      // Inpage-nav top distance must not go below navbar height.
      // If it does the inpage-nav doesn't stop to top of the page when scrolling.
      topDistance = topDistance >= navbarHeight ? topDistance : navbarHeight;

      // Height without footer
      // 12px is content area bottom padding.
      let height = window.innerHeight - topDistance - contentPadding;
      // Distance from footer to top of the page.
      let topDistanceFooter = 0;

      // Find footer from the page.
      const footer = document.getElementsByClassName(styles['uil-footer'])[0];
      if (footer !== undefined) {
        topDistanceFooter = footer.getBoundingClientRect().top;
      }

      // Find content footer on the page
      const contentFooter =
        document.getElementsByClassName(styles['uil-content-footer'])[0];
      if (contentFooter !== undefined) {
        topDistanceFooter = contentFooter.getBoundingClientRect().top;
      }

      // Find related strip on the page
      const relatedStrip =
        document.getElementsByClassName(styles['uil-related-strip'])[0];
      if (relatedStrip !== undefined) {
        topDistanceFooter = relatedStrip.getBoundingClientRect().top;
      }
      const heightFun = () =>(footer !== undefined || contentFooter !== undefined || relatedStrip !== undefined) && topDistanceFooter < window.innerHeight ? topDistanceFooter - topDistance - 12 : height;
      // prettier-ignore
      // 12px is content area bottom padding.
      height = heightFun();
      setHeight(height);
    } else if (horizontal) {
      // Get width of the horizontal in-page navigation menu.

      const inPageNavMenuWidth =
        inpageNavMenuRef.current.getBoundingClientRect().width;

      // Get the left offset of the menu (distance to left edge).
      if (inPageNavMenuLeftOffset === -1) {
        inPageNavMenuLeftOffset = inpageNavMenuRef.current.getBoundingClientRect().left;
      }else{
        console.log(inPageNavMenuLeftOffset);
      }
      // Get width of the whole in-page navigation (space available).
      const inPageNavWidth = inpageNavRef.current.getBoundingClientRect().width;

      // Check if the menu fits to the page.
      const menuFits =
        inPageNavWidth - inPageNavMenuWidth - inPageNavMenuLeftOffset - arrowPadding > 0;

      // Set in-page navigation carousel state based on the result.
      setMenuCarousel(!menuFits);

      // Restore the horizontal in-page nav height from properties.
      setHeight("fit-content");
    }else{
      console.log(horizontal);
    }
  };

  // Special filtering to get only in-page navigation link list.
  // UilInpageNavigationLinkList is the link list from library and
  // InPageNavigationLinkList is used DL reference page.
  const typeOfComponent = component => component.props.__TYPE;
  const getHorizontalChildrenWithProps = children => {
    // Taking just the first child as there should be only one in-page nav link list.
    // Possible other elements like LinkCard are filtered out.
    const filteredChild = React.Children.toArray(children).filter(
      child =>
        ['UilInpageNavigationLinkList'].indexOf(
          typeOfComponent(child)
        ) !== -1
    )[0];
    if (filteredChild && filteredChild !== undefined) {
      const enrichedChild = React.cloneElement(filteredChild, {
        scrollIndex, parentRef: inpageNavMenuRef, handleScrollIndex: checkLastItem
      });

      return enrichedChild;
    } else {
      return children;
    }
  };

  const checkLastItem = () => {
    // Set scrollindex to -1 which is default value. 0 is left anf 1 is right.
    setScrollIndex(-1);
  };
  const getHorizontalCurtainChildrenWithProps = children => {
    // Enrich the navigation list
    const enrichedChildren = [];

    // Find the navigation list child.
    React.Children.toArray(children).forEach(child => {
      if (child && child !== undefined) {
        if (['UilInpageNavigationLinkList'].indexOf(typeOfComponent(child)) !== -1) {
          // Add information about collapsed horizontal menu
          const enrichedChild = React.cloneElement(child, {
            horizontalInpageNavCollapsed
          });
          enrichedChildren.push(enrichedChild);
        } else {
          enrichedChildren.push(child);
        }
      }
    });

    return enrichedChildren;
  };

  const inpageNavColumnScroll = () => {
    //scrollIndex = -1
  };
  const alignWithContentFun = contentfun =>{
    if(contentfun){
      return styles['uil-align-with-content'];
    }else{
      return '';
    }
  };
  const customClassesFun = classesfun =>{
    if(classesfun !== undefined){
      return customClasses;
    }else{
      return '';
    };
  };
  return (
    <div
      ref={inpageNavRef}
      className={`${alignWithContentFun(alignWithContent)} ${customClassesFun(customClasses)
        } ${horizontal
          ? styles['uil-in-page-navigation-horizontal']
          : styles['uil-in-page-navigation']
        }`}
      style={{ ...customStyles }}
      // ...{ height },
    >
      {(menuCarousel) && (
        <div
          className={styles['uil-in-page-navigation-horizontal-left']}
          onClick={inpageNavCarouselLeftHandler}
        >
          <div className={styles['uil-font-icon']} style={{ fontSize: 36 }}>
            <span className={styles['uil-dl-icon-Chevron-left']}></span>
          </div>
        </div>
      )}
      <div ref={inpageNavMenuRef}
        className={classNames([styles['uil-in-page-navigation-column']],[styles['uil-dynamic-scrollbar']])}
        onScroll={inpageNavColumnScroll}>
        {/* Render only link list for horizontal in-page navigation */}
        {/* Enrich child component with the scrollIndex for horizontal menu*/}
        {horizontal && getHorizontalChildrenWithProps(children)}
        {!horizontal && children}
      </div>
      {(menuCarousel) && (
        <div
          className={styles['uil-in-page-navigation-horizontal-right']}
          onClick={inpageNavCarouselRightHandler}
        >
          <div className={styles['uil-font-icon']} style={{ fontSize: 36 }}>
            <span className={styles['uil-dl-icon-Chevron-right']}></span>
          </div>
        </div>
      )}
      <div
        className={`${styles['uil-curtain']} ${curtainOpen && !disabled ? styles.active : ''}`}
      >
        <div
          className={`${styles["uil-curtain-panel"]} ${styles['uil-curtain-panel-right']} ${styles['uil-curtain-open-100']} ${styles['uil-curtain-close-0']} ${curtainOpen && !disabled ? styles.active : '' }`}
        >
          <div className={styles['uil-curtain-open']}>
            <button
              className={classNames([styles['uil-curtain-toggle']],[styles['uil-link-icon']],[styles['uil-curtain-toggle-button-default']])}
              type='button'
              onClick={inpageNavCurtainToggleHandler}
            >
              <span className={styles['uil-in-page-navigation-toggle-icon']}></span>
            </button>
            <div
              className={styles['uil-curtain-panel-content']}
            >
              {horizontal && getHorizontalCurtainChildrenWithProps(children)}
              {!horizontal && children}
            </div>
          </div>

          <div className={styles['uil-curtain-close']}>
            <button
              className={classNames([styles['uil-curtain-toggle']],[styles['uil-link-icon']],[styles['uil-curtain-toggle-button-default']])}
              type='button'
              onClick={inpageNavCurtainToggleHandler}
              disabled={disabled}
            >
              <span className={styles['uil-in-page-navigation-toggle-icon']}></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UilInPageNavigationContainer.defaultProps = {
  horizontal: false,
  alignWithContent: false,
  inPageNavMenuLeftOffset: -1,
  navbarHeight: 56,
  contentPadding: 12,
  arrowPadding:40
};

UilInPageNavigationContainer.propTypes = {
  horizontal: PropTypes.bool,
  alignWithContent: PropTypes.bool,
  inPageNavMenuLeftOffset: PropTypes.number,
  navbarHeight: PropTypes.number,
  contentPadding: PropTypes.number,
  arrowPadding: PropTypes.number,
  customClasses: PropTypes.any,
  customStyles: PropTypes.any,
  children: PropTypes.any,
  disabled: PropTypes.bool
};

export default UilInPageNavigationContainer;