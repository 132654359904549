import acn_logo from "../../../assets/img/AccentureLogo_black.png";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
const Settings = {
  UilHeader: {
    id: "Header-Example",
    headerSize: "baseline",
    tabAlignment: "right",
    title: "App Design Language",
    headerPadding: "small",
    UilHeaderTools: [
      {
        icon: (
          <div className="uil-font-icon" style={{ paddingRight: "24px" }}>
            <span
              className="uil-dl-icon-Bell"
              style={{ cursor: "pointer", fontSize: "24px" }}
            ></span>
          </div>
        ),
      },

      {
        icon: (
          <div className="uil-font-icon" style={{ paddingRight: "24px" }}>
            <span
              className="uil-dl-icon-User"
              style={{ cursor: "pointer", fontSize: "24px" }}
            ></span>
          </div>
        ),
      },
    ],
    UilHeaderLogo: (
      <div className="uil-font-icon" style={{ marginRight: "8px" }}>
        <span>
          <a href="https://accenture.com">
            <img src={acn_logo} alt="Accenture" width="101"></img>
          </a>
        </span>
      </div>
    ),
  },
  UilSideNavBar: {
    UilSideNavTopOptions: [
      { id: "id1", icon: "uil-dl-icon-Home-alt", displayValue: "Home" },
      { id: "id2", icon: "uil-dl-icon-Eye", displayValue: "Designers" },
      { id: "id3", icon: "uil-dl-icon-Coding", displayValue: "Developers" },
      {
        id: "id4",
        icon: "uil-dl-icon-Component",
        displayValue: "Components",
      },
      { id: "id5", icon: "uil-dl-icon-Colors", displayValue: "Styles" },
    ],
    UilSideNavBottomOptions: [
      {
        id: "id6",
        icon: "uil-dl-icon-Help",
        displayValue: "FAQ",
      },
      {
        id: "id7",
        icon: "uil-dl-icon-Contact-book",
        displayValue: "Contact Us",
      },
    ],
  },
  GetStartedContent: {
    Titles: {
      whatIsTheAPPCoEDesignSystemCodelibrary:
        "What is the APP CoE Design System code library ?",
        languages:"Languages"
    },
    Descriptions: {
      whatIsTheAPPCoEDesignSystemCodelibraryDesc: (
        <span>
          The APP CoE Design System is a set of tools that allow designers and
          developers to use a single,
          <br /> 
          common language for creating APP platform applications and
          delivering user experiences that are
          <br /> 
          delightful, consistent, simple, purposeful and actionable.
          <br />
          <br />
          This design and front-end development accelerator contain reusable,
          graphical user interface (GUI)
          <br /> controls used as building blocks to create cohesive, responsive
          UI. It also offers well-defined,
          <br /> centralized, and adoptable design standards, foundations,
          guidelines for facilitating engaging user
          <br /> experiences.
        </span>
      ),
    },
  },
  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            //onClick={() => openFeedback()}
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};

export default Settings;
