import React, { useState } from "react";
import ComponentData from "../static/ComponentData";
const ComponentContext = React.createContext({});

export const ComponentContextProvider = (props) => {
  const [state, setState] = useState({
   selectedView:false,
   searchValue:"",
   uilFooterLeftArea: ComponentData.UilFooter.footerLeftArea,
   uilFooterLinks : ComponentData.UilFooter.footerLinks,
   uilFooterLogos : ComponentData.UilFooter.footerLogos,
   uilFooterMiddleArea : ComponentData.UilFooter.footerMiddleArea,
   uilFooterRightArea : ComponentData.UilFooter.footerRightArea,
  });

    //function to change view
    const setActiveView = (view) =>{
      state.selectedView = view;
      setState({
        ...state,
        view : state.selectedView
      });
    }

    //function to search table/card
    const setSearchValue = (view) =>{
      state.searchValue = view;
      setState({
        ...state,
        view : state.searchValue
      });
    }

  const componentContext = {
    state,
    setState,
    setActiveView,
    setSearchValue
  };
  return (
    <ComponentContext.Provider value={componentContext}>
      {props.children}
    </ComponentContext.Provider>
  );
};
export const ComponentContextConsumer = ComponentContext.Consumer;
export default ComponentContext;
