import React, { Component } from "react";
import UilBanner from "../../../app-components/UilBanner/UilBanner";
import UilTabBar from "../../../app-components/UilTab/UilTabBar";
import backgroundDevelopersBanner from "../../../assets/gradients/Hero_Banner_developers.svg";
import DevelopersContext, { DevelopersContextProvider } from "../context/DevelopersContext";
import GetStarted from "./getStarted";
import DesignCodeTokens from "./designCodeTokens";
import  "./developers.css";
import ComponentData from "../static/ComponentData";
import ContentBlock from "../../../app-components/ContentBlock";
import withRouter from "../../withRouter";
let contextType = null;


class Developers extends Component {
  componentDidMount() {
    let selectedTab = this.props.params.tabid;
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  }
  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };
  

  render() {
    return (
      <DevelopersContextProvider>
        <DevelopersContext.Consumer>
          {(developersContext) => {
            contextType = developersContext;
            return (
              <React.Fragment>
                <div className="div-tabbar" id="divTabBar">
                  <UilTabBar
                    isMultiSelect={false}
                    onChildClick={(e) => this.onTabBarClick(e)}
                    onTabClick={(e) => this.onTabBarClick(e)}
                    preselectTab={[contextType.state.selectedTabIndex]}
                    tabOrientation="horizontal"
                    tabs={ComponentData.UilTabBar.tabs}
                    type="defaultwithoutBorder"
                  />
                </div>{" "}
                <div classname="bodySection">
                <div className="uil-banner-section">
                  <UilBanner
                    type={ComponentData.UilBanner.type}
                    size={ComponentData.UilBanner.size}
                    title={contextType.state.selectedTabIndex === "1" ? ComponentData.UilBanner.title : ComponentData.UilTokenBanner.title}
                    content={contextType.state.selelectedTabDesc}
                    backgroundImage={backgroundDevelopersBanner}
                    customClasses={ComponentData.UilBanner.customClasses}
                    showTitle={true}
                    showContent={true}
                    showSubHeading={false}
                    leftSpacing={ComponentData.UilBanner.leftSpacing}
                  ></UilBanner></div>
                  <ContentBlock>
                  <div className="developerContentSection">
                    {contextType.state.selectedTabIndex === "1" ? (
                      <GetStarted></GetStarted>
                    ) : <DesignCodeTokens></DesignCodeTokens>}
                  </div>
                  </ContentBlock>
                </div>
              </React.Fragment>
            );
          }}
        </DevelopersContext.Consumer>
      </DevelopersContextProvider>
    );
  }
}

export default withRouter(Developers);