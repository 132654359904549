import React, { Component } from "react";
import ContentBlock from "../../../app-components/ContentBlock";
import DesignerContext, {
  DesignerContextProvider,
} from "../context/DesignersContext";
import ComponentData from "../static/ComponentData";
import asset_video from "../../../assets/videos/Connecting-Figma-Library.mov";

import UilCard from "../../../app-components/UilCard/UilCard";
import UilAccordion from "../../../app-components/UilAccordion/UilAccordion";
import Settings from "../static/settings";
import "./asset_lib_page.css";
let contextType = null;

export default class Asset_Library extends Component {
  componentDidMount() {}

  render() {
    return (
      <DesignerContextProvider>
        <DesignerContext.Consumer>
          {(designerContext) => {
            contextType = designerContext;
            return (
              <React.Fragment>
                <ContentBlock>
                  <div className="uil-content">
                    <div className="uil-page-header">
                      {ComponentData.UilAssetLib.title}
                    </div>
                    <div className="uil-page-image">
                      <video className="uil-video" controls>
                        <source src={asset_video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="uil-page-desc">
                      {ComponentData.UilAssetLib.desc}
                    </div>
                  </div>
                </ContentBlock>
              </React.Fragment>
            );
          }}
        </DesignerContext.Consumer>
      </DesignerContextProvider>
    );
  }
}
