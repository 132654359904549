import backgroundImage from "../../../assets/gradients/ACC_Gradient_Dark_6.jpg";
import bannerstyles from "../../../app-components/UilBanner/uil_banner.module.css";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import acn_logo from "../../../assets/img/AccentureLogo_black.png";
const componentData = {
  UilBanner: {
    type: "hero",
    size: "small",
    title: "Colors",
    backgroundImage: { backgroundImage },
    customClasses: "uil-custom-banner",
    showTitle: true,
    showContent: true,
    showSubHeading: false,
    leftSpacing: "small",
  },
  UilTabBar: {
    tabOrientation: "horizontal",
    tabs: [
      {
        tabId: "1",
        tabName: "Colors",
        Description: (
          <span>
            APP Design Language follows the Accenture branded color palette.
            With the
            <br />
            provided color variations and combination, UI controls are designed
            to align with
            <br />
            WCAG 2.1 color contrast guidelines.
          </span>
        ),
      },
      {
        tabId: "2",
        tabName: "Typography",
        Description: (
          <span>
            APP CoE Design System uses Graphik as the primary typeface defined
            by
            <br />
            Accenture branding. Each UI control in the library has a specific
            font type defined
            <br />
            to it by default.
          </span>
        ),
      },
      {
        tabId: "3",
        tabName: "Icons",
        Description: (
          <span>
            Access over 300 icons to help create better visual links between
            users and applications.
          </span>
        ),
      },
    ],
  },
  UilTextFieldSearch: {
    placeholder: "Search",
  },
  UilCard: {
    BasicUpperSection: (
        <div className="uil-basic-card">
          <div className="uil-basic-card-titlecheckbox">
            <h4 className="uil-basic-card-eyebrow" style={{fontSize: 14+"px"}}>SUGGESTED</h4>
            <span
              class="uil-dl-icon-Arrow-right-up-card"
              style={{ fontSize: 24 + "px" }}
            ></span>
          </div>
          <h4 className="uil-basic-card-title">Brand Space</h4>
          <span className="uil-basic-card-captions">
          Explore Accenture Brand Space<br/> for more guidelines
          </span>
        </div>
      ),
  },
  UilTableGreyScale: {
    tableData: {
      body: [
        {
          metadata: [{ id: "1" }],
          data: [
            {
              content: (
                <div
                  className="fillRectangle"
                  style={{ backgroundColor: "#000000" }}
                >
                  <span className="hexCodeContent">#000000</span>{" "}
                </div>
              ),
            },
            { content: "Black" },
          ],
          disabled: false,
          selected: false,
        },
        {
          metadata: [{ id: "2" }],
          data: [
            {
              content: (
                <div
                  className="fillRectangle"
                  style={{ backgroundColor: "#505050" }}
                >
                  <span className="hexCodeContent">#505050</span>{" "}
                </div>
              ),
            },
            { content: "Dark Grey" },
          ],
          disabled: false,
          selected: false,
        },
        {
          metadata: [{ id: "3" }],
          data: [
            {
              content: (
                <div
                  className="fillRectangle"
                  style={{ backgroundColor: "#747474" }}
                >
                  <span className="hexCodeContent">#747474</span>{" "}
                </div>
              ),
            },
            { content: "Grey Medium" },
          ],
          disabled: false,
          selected: false,
        },
        {
          metadata: [{ id: "4" }],
          data: [
            {
              content: (
                <div
                  className="fillRectangle"
                  style={{ backgroundColor: "#CCCCCC" }}
                >
                  <span className="hexCodeContent">#CCCCCC</span>{" "}
                </div>
              ),
            },
            { content: "Grey Light 1" },
          ],
          disabled: false,
          selected: false,
        },
        {
          metadata: [{ id: "5" }],
          data: [
            {
              content: (
                <div
                  className="fillRectangle"
                  style={{ backgroundColor: "#EFEFEF" }}
                >
                  <span className="hexCodeContent">#EFEFEF</span>{" "}
                </div>
              ),
            },
            { content: "Grey Light 2" },
          ],
          disabled: false,
          selected: false,
        },
        {
          metadata: [{ id: "6" }],
          data: [
            {
              content: (
                <div
                  className="fillRectangle"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <span className="hexCodeContent">#FFFFFF</span>{" "}
                </div>
              ),
            },
            { content: "White" },
          ],
          disabled: false,
          selected: false,
        },
      ],
      head: [
        {
          content: "Color Hex",
          tooltip: "",
          sortable: false,
          editable: false,
        },
        {
          content: "Color Token",
          tooltip: "",
          sortable: false,
          editable: false,
        },
      ],
      tooltipPosition: "center",
    },
  },
  UilFontTypeDetails: [
    {
      type: "regular",
      disabled: false,
      id: "card_1",
      title: "Caption",
      isCounterOption: false,
      children:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      isIconOption: true,
    },
    {
      type: "regular",
      disabled: false,
      id: "card_2",
      title: "Body",
      isCounterOption: false,
      children:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      isIconOption: true,
    },
    {
      type: "regular",
      disabled: false,
      id: "card_3",
      title: "Title",
      isCounterOption: false,
      children:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      isIconOption: true,
    },
    {
      type: "regular",
      disabled: false,
      id: "card_4",
      title: "Heading",
      isCounterOption: false,
      children:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      isIconOption: true,
    },
  ],
  UilFontStyleDetails: [
    {
      title: "Font style",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              { content: "12px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 12,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              { content: "12px-500" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "3" }],
            data: [
              { content: "12px-600" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "4" }],
            data: [
              { content: "14px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "5" }],
            data: [
              { content: "14px-500" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "6" }],
            data: [
              { content: "14px-600" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "7" }],
            data: [
              { content: "16px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "8" }],
            data: [
              { content: "16px-500" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "9" }],
            data: [
              { content: "18px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "10" }],
            data: [
              { content: "20px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 20,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "11" }],
            data: [
              { content: "24px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 24,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "12" }],
            data: [
              { content: "28px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 28,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "13" }],
            data: [
              { content: "32px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 32,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "14" }],
            data: [
              { content: "48px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 48,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "15" }],
            data: [
              { content: "60px-300" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 60,
                      fontWeight: 300,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "16" }],
            data: [
              { content: "60px-400" },
              {
                content: (
                  <span
                    style={{
                      fontFamily: "Graphik",
                      fontSize: 60,
                      fontWeight: 400,
                    }}
                  >
                    The quick brown fox jumps over the lazy dog
                  </span>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Font scale & weight",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Style",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
  ],
  UilFontTokenDetails: [
    {
      title: "Font tokens",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              { content: <span>Caption 12px-400 </span> },
              { content: "font-caption-xs-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              { content: "12px-500" },
              { content: "font-caption-xs-medium" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "3" }],
            data: [
              { content: "12px-600" },
              { content: "font-caption-xs-semibold" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "4" }],
            data: [
              { content: "14px-400" },
              { content: "font-body-sm-default" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "5" }],
            data: [{ content: "14px-500" }, { content: "font-body-sm-medium" }],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "6" }],
            data: [
              { content: "14px-600" },
              { content: "font-body-sm-semibold" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "7" }],
            data: [
              { content: "16px-400" },
              { content: "font-body-md-regular-paragraph" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "8" }],
            data: [{ content: "16px-500" }, { content: "font-body-md-medium" }],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "9" }],
            data: [
              { content: "18px-400" },
              { content: "font-title-sm-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "10" }],
            data: [
              { content: "20px-400" },
              { content: "font-title-md-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "11" }],
            data: [
              { content: "24px-400" },
              { content: "font-title-lg-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "12" }],
            data: [
              { content: "28px-400" },
              { content: "font-heading-sm-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "13" }],
            data: [
              { content: "32px-400" },
              { content: "font-heading-md-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "14" }],
            data: [
              { content: "48px-400" },
              { content: "font-heading-lg-regular" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "15" }],
            data: [
              { content: "60px-300" },
              { content: "font-heading-xl-light" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "16" }],
            data: [
              { content: "60px-400" },
              { content: "font-heading-xl-regular" },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Font Scale & Weight",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Core Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
  ],
  UilFontColorTokenDetails: [
    {
      title: "Font color tokens",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              { content: "#000000" },
              { content: "Black" },
              { content: "color-font-dark" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              { content: "#505050" },
              { content: "Grey Dark" },
              { content: "color-font-default" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "3" }],
            data: [
              { content: "#747474" },
              { content: "Grey Medium" },
              { content: "color-font-medium" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "4" }],
            data: [
              { content: "#CCCCCC" },
              { content: "Grey Light" },
              { content: "color-font-disabled" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "5" }],
            data: [
              { content: "#FFFFFF" },
              { content: "White" },
              { content: "color-font-light" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "6" }],
            data: [
              { content: "#E6192D" },
              { content: " Red" },
              { content: "color-font-error" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "7" }],
            data: [
              { content: "#128926" },
              { content: "Green" },
              { content: "color-font-success" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "8" }],
            data: [
              { content: "#D24702" },
              { content: "Orange" },
              { content: "color-font-warning" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "9" }],
            data: [
              { content: "#0041F0" },
              { content: "Blue" },
              { content: "color-font-active" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "10" }],
            data: [
              { content: "#A100FF" },
              { content: "Primary Light" },
              { content: "color-font-primaryLight" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "11" }],
            data: [
              { content: "#460073" },
              { content: "Primary Dark" },
              { content: "color-font-primaryDark" },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Color Hex",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Core Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Semantic Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
  ],

  UilColorDetails: [
    {
      title: "Grey scale",
      description:
        "Grey color range is the most common colors used to build background, pop-up, dropdowns, texts and more. These colors are essential for the cleaner look and feel of the products.",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#000000" }}
                  >
                    <span className="hexCodeContent">#000000</span>{" "}
                  </div>
                ),
              },
              { content: "Black" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#505050" }}
                  >
                    <span className="hexCodeContent">#505050</span>{" "}
                  </div>
                ),
              },
              { content: "Dark Grey" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "3" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#747474" }}
                  >
                    <span className="hexCodeContent">#747474</span>{" "}
                  </div>
                ),
              },
              { content: "Grey Medium" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "4" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#CCCCCC" }}
                  >
                    <span className="hexCodeContent">#CCCCCC</span>{" "}
                  </div>
                ),
              },
              { content: "Grey Light 1" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "5" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#EFEFEF" }}
                  >
                    <span className="hexCodeContent">#EFEFEF</span>{" "}
                  </div>
                ),
              },
              { content: "Grey Light 2" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "6" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    <span className="hexCodeContent">#FFFFFF</span>{" "}
                  </div>
                ),
              },
              { content: "White" },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Color Hex",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Color Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
    {
      title: "Primary colors",
      description:
        "We have selected the Accenture Core brand colors as our baseline to highlight details in the user interface.",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#460073" }}
                  >
                    <span className="hexCodeContent">#460073</span>{" "}
                  </div>
                ),
              },
              { content: "Primary Dark" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#A100FF" }}
                  >
                    <span className="hexCodeContent">#A100FF</span>{" "}
                  </div>
                ),
              },
              { content: "Primary Light" },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Color Hex",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Color Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
    {
      title: "Secondary colors",
      description: "",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#7500C0" }}
                  >
                    <span className="hexCodeContent">#7500C0</span>{" "}
                  </div>
                ),
              },
              { content: "Secondary Dark" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#F5E5FF" }}
                  >
                    <span className="hexCodeContent">#F5E5FF</span>{" "}
                  </div>
                ),
              },
              { content: "Secondary Light" },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Color Hex",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Color Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
    {
      title: "Status colors",
      description: "Statuses colors are important component which instantly illustrate the audience about the state of the items.",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#E6192D" }}
                  >
                    <span className="hexCodeContent">#E6192D</span>{" "}
                  </div>
                ),
              },
              { content: "Red" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#128926" }}
                  >
                    <span className="hexCodeContent">#128926</span>{" "}
                  </div>
                ),
              },
              { content: "Green" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "3" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#0041F0" }}
                  >
                    <span className="hexCodeContent">#0041F0</span>{" "}
                  </div>
                ),
              },
              { content: "Blue" },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "4" }],
            data: [
              {
                content: (
                  <div
                    className="fillRectangle"
                    style={{ backgroundColor: "#D24702" }}
                  >
                    <span className="hexCodeContent">#D24702</span>{" "}
                  </div>
                ),
              },
              { content: "Orange" },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Color Hex",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Color Token",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
  ],
  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            //onClick={() => openFeedback()}
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};
export default componentData;
