import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_footer.module.css";
import { Link } from "react-router-dom";

const UilFooter = (props) => {
  const {
    id,
    type,
    padding,
    customClasses,
    footerLeftArea,
    links,
    logos,
    footerMiddleArea,
    footerRightArea,
  } = props;

  const typeClass = classNames({
    [styles["uil-footer-padding-small"]]: padding === "small",
    [styles["uil-footer-padding-medium"]]: padding === "medium",
    [styles["uil-footer-padding-large"]]: padding === "large",
  });

  return (
    <React.Fragment>
      <div classname={"div-footer"} id={id}>
        {type === "basic" && (
          <div
            className={classNames(
              styles["uil-footer"],
              styles["uil-footer-basic"],
              typeClass,
              customClasses
            )}
          >
            <div className={styles["uil-footer-text"]}>{footerLeftArea}</div>
            <div className={styles["uil-footer-text-right"]}>
              <span className={styles["uil-footer-small-links"]}>
                {links.map((hyperlinks) => (
                  <li key={hyperlinks.name}>
                    <a href={hyperlinks.link}>{hyperlinks.name}</a>
                  </li>
                ))}
              </span>
            </div>
          </div>
        )}
        {type === "detailed" && (
          <div
            className={classNames(
              styles["uil-footer"],
              styles["uil-footer-detailed"],
              typeClass,
              customClasses
            )}
          >
            <div className={styles["uil-footer-brand-copyright"]}>
              <div className={styles["uil-footer-logos"]}>{logos}</div>
              <div className={styles["uil-footer-text"]}>{footerLeftArea}</div>
              <div className={styles["uil-footer-text-right"]}>
                <span
                  className={classNames(
                    styles["uil-footer-small-links"],
                    customClasses
                  )}
                >
                  {links.map((hyperlinks) => (
                    <span key={hyperlinks.name}>
                      <a href={hyperlinks.link}>{hyperlinks.name}</a>
                    </span>
                  ))}
                </span>
              </div>
            </div>
            <div className={styles["uil-footer-hyperlinks"]}>
              {footerMiddleArea.map((link) => (
                <li key={link.name}>
                  {link.name === "About" ? (
                     <Link to={"/designers/1"}>
                    <span
                      href={link.link}
                      className={styles["uil-footer-links"]}
                    >
                      {link.name}
                    </span></Link>
                  ) : (
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href =
                          "mailto:DesignLanguage-Support@accenture.com?subject=APP CoE Design System: Feedback/Support Request";
                        e.preventDefault();
                      }}
                    >
                      <span
                       
                        className={styles["uil-footer-links"]}
                      >
                        {link.name}
                      </span>
                    </Link>
                  )}
                </li>
              ))}
            </div>
            <div className={styles["uil-footer-helpfeedback"]}>
              {footerRightArea}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

UilFooter.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  padding: PropTypes.string,
  customClasses: PropTypes.any,
  footerLeftArea: PropTypes.any,
  links: PropTypes.array,
};

export default UilFooter;
