import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_shadow.module.css";

const UilShadow = (props) => {
  const { customClasses, shadowType, disabled, children, customStyles } = props;
  const selectedClass = classNames([styles["uil-shadow"]], {
    [styles["uil-shadow-dark"]]: shadowType === "dark",
    [styles["uil-shadow-light"]]: shadowType === "light",
    [styles["uil-shadow-lightg"]]: shadowType === "lightg",
    [styles["uil-shadow-spreaded"]]: shadowType === "spreaded",
    [styles["uil-shadow-tile"]]: shadowType === "tile",
  });
  const [styleCss, setStyleCss] = useState(selectedClass);

  useEffect(() => {
    // when in disabled state, it should not work.
    //when disabled state is off it will be enabled to choose the shadow types
    const selectedClass = classNames([styles["uil-shadow"]], {
      [styles["uil-shadow-dark"]]: shadowType === "dark",
      [styles["uil-shadow-light"]]: shadowType === "light",
      [styles["uil-shadow-lightg"]]: shadowType === "lightg",
      [styles["uil-shadow-spreaded"]]: shadowType === "spreaded",
      [styles["uil-shadow-tile"]]: shadowType === "tile",
      [styles["uil-shadow-disabled"]]: disabled === true,
    });
    setStyleCss(selectedClass);
  }, [shadowType, disabled]);

  return (
    <React.Fragment>
      <div className={classNames(styleCss, customClasses)} style={customStyles}>
        {children}
      </div>
    </React.Fragment>
  );
};
UilShadow.defaultProps = {
  shadowType: "light",
};

UilShadow.propTypes = {
  shadowType: PropTypes.string,
  disabled: PropTypes.bool,
  customStyles: PropTypes.object,
  children: PropTypes.element,
  customClasses: PropTypes.string
};

UilShadow.propDescriptions = {
  disabled: "Sets or returns whether the shadow is disabled, or not",
  customStyles:
    "It allows user to add the custom height and width of the shadow",
  shadowType: " It allows to select the different shadow types",
  children:
    "allows the user to send any component as a child to the shadow container",
};

export default UilShadow;
