import acn_logo from "../../../assets/img/AccentureLogo_black.png";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import { Link } from "react-router-dom";

import "./get-started.css";

const Settings = {
  UilHeader: {
    id: "Header-Example",
    headerSize: "default",
    tabAlignment: "right",
    title: <Link to="/">APP CoE Design System</Link>,
    headerPadding: "small",
    UilHeaderTools: [
      {
        icon: (
          <div className="uil-font-icon" style={{ paddingRight: "24px" }}>
            <span
              className="uil-dl-icon-Bell"
              style={{ cursor: "pointer", fontSize: "24px" }}
            ></span>
          </div>
        ),
      },

      {
        icon: (
          <div className="uil-font-icon" style={{ paddingRight: "24px" }}>
            <span
              className="uil-dl-icon-User"
              style={{ cursor: "pointer", fontSize: "24px" }}
            ></span>
          </div>
        ),
      },
    ],
    UilHeaderLogo: (
      <div className="uil-font-icon" style={{ marginRight: "8px" }}>
        <span>
          <a href="https://accenture.com">
            <img src={acn_logo} alt="Accenture" width="101"></img>
          </a>
        </span>
      </div>
    ),
  },
  UilSideNavBar: {
    UilSideNavTopOptions: [
      {
        id: "id1",
        icon: "uil-home-icon",
        displayValue: "Home",
        redirectTo: "/",
      },
      {
        id: "id2",
        icon: "uil-pen-icon",
        displayValue: "Designers",
        redirectTo: "/designers",
      },
      {
        id: "id3",
        icon: "uil-code-icon",
        displayValue: "Developers",
        redirectTo: "/developers",
      },
      {
        id: "id4",
        icon: "uil-component-icon",
        displayValue: "Components",
        redirectTo: "/components",
      },
      {
        id: "id5",
        icon: "uil-colors-icon",
        displayValue: "Styles",
        redirectTo: "/styles",
      },
    ],
    UilSideNavBottomOptions: [],
  },
  UilFooter: {
    footerLeftArea: (
      <div style={{ width: "max-content" }}>
        <span>
          Copyright 2001-2022<span> </span>
          <a href="https://www.accenture.com/">Accenture.</a>
          <span> </span> Accenture <br /> confidential. For internal use only.
        </span>
      </div>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span style={{ display: "flex", width: "max-content" }}>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>
          APP CoE Design System
        </span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the App COE Design System experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <Link
            to="#"
            onClick={(e) => {
              window.location.href =
                "mailto:DesignLanguage-Support@accenture.com?subject=APP CoE Design System: Feedback/Support Request";
              e.preventDefault();
            }}
          >
            <UilButton
              type="secondary"
              label="Give Feedback"
              sizeType="large"
              //onClick={() => openFeedback()}
              customClasses={styles["uil-footer-button"]}
              fitcontent={true}
              disabled={false}
            ></UilButton>
          </Link>
        </span>
      </span>
    ),
  },
};

export default Settings;
