import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_textfield_search.module.css";
import UilLabel from "../UilLabel/UilLabel";

const UilTextFieldSearch = ({
  id,
  disabled,
  label,
  placeholder,
  customClasses,
  onSearchIconClick,
  suggestionsList,
  onChange,
  value,
  isFilterVisible,
  isStarVisible,
  starIconClickhandler,
  filterIconClickhandler,
  handleEnterKeyPressed,
  closeIconHandler,
  size,
  isError,
  htmlProps,
  isFilledBackground,
  isforDropdown,
  required,
  tooltipPosition,
  tooltipText,
  labelIcon
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [borderClass, setBorderClass] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [autocompleteListToRender, setAutocompleteListToRender] =
    useState(null);
  const inputRef = useRef(null);

  // Suggestion list references for shown list
  const tempListItemRefs = useRef([]);

  useEffect(() => {
    setInputValue(value ? value : "");
  }, [value]);

  useEffect(() => {
    // Build autocomplete list to render when the list of items has changed.
    setAutocompleteListToRender(buildAutocompleteList());
    // Dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  const onFocusEventHandler = function () {
    setBorderClass((inputborder) => !inputborder);
  };
  const onBlurEventHandler = function () {
    emptyAutoCompleteList();
    setBorderClass((inputborder) => !inputborder);
  };

  const keyUpHandler = function (event) {
    if (suggestionsList) {
      const value = event.target.value;
      const filteredList = filterAutocompleteList(suggestionsList, value);
      setSuggestions(filteredList);
    }
    if(handleEnterKeyPressed){
      handleEnterKeyPressed(event);
    }
  };

  const iconClickHandler = function (event) {
    if (onSearchIconClick !== undefined) {
      const object = { event, inputValue };
      onSearchIconClick(object);
    }
  };

  const autocompleteListItemClickHandler = (item) => {
    inputRef.current.value = item;
    searchInputHandler();
  };

  const clearSearchInput = (e) => {
    inputRef.current.value = "";
    searchInputHandler();
    emptyAutoCompleteList();
    if(closeIconHandler){
      closeIconHandler();
    }
    // Call clear search click handler, if it is provided.
  };

  const searchInputHandler = (event) => {
    setInputValue(inputRef.current.value);
    if (onChange !== undefined) {
      // Call for parent handler
      onChange(event);
    }
  };

  const emptyAutoCompleteList = () => {
    setSuggestions([]);
  };

  const filterAutocompleteList = (list, filter) => {
    let filteredList = [];
    if (filter) {
      filteredList = list.filter((listItem) =>
        listItem.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return filteredList;
  };

  const buildAutocompleteList = () => {
    // Clear the autocomplete list refs.
    // New ones are created on render.
    tempListItemRefs.current = [];

    // Create autocomplete list, if there are list items to render.
    const filteredList = (
      <div className={styles["uil-search-list"]}>
      <ul >
        {suggestions.map((element) => (
          <li
            key={element}
            // className={classNames(
            //   [styles["uil-clickable"]],
            //   [styles["uil-overflow-ellipsis"]]
            // )}
            onMouseDown={() => autocompleteListItemClickHandler(element)}
          >
            {element}
          </li>
        ))}
      </ul>
      </div>
    );

    return filteredList;
  };
  const mainDivClassName = classNames(
    [styles["uil-textfield-search"]],
    customClasses,
    {
      [styles["uil-textfield-search-large"]]: size === "large",
    }
  );
  const wrapperClassName = classNames(
    [styles["uil-textfield-search-wrapper"]],
    {
      [styles["uil-textfield-search-wrapper-background"]]: isFilledBackground,
      [styles["uil-textfield-search-fordropdown"]]: isforDropdown,
      [styles["uil-textfield-search-error"]]: isError && !isforDropdown,
      [styles["uil-textfield-search-dropdownError"]]: isError && isforDropdown &&!disabled,
    }
  );
  return (
    <React.Fragment>
    
        {label &&  <UilLabel
            id={id}
            label={label}
            disabled={disabled}
            required={required}
            tooltip={tooltipText}
            iconPosition="Icon Right"
            tooltipPosition={tooltipPosition}
            customClasses={{ fontSize: 16, fontWeight: 400 }}
            icon={labelIcon}
          ></UilLabel>}
        <div id={id} className={mainDivClassName} disabled={disabled}>
          <div className={wrapperClassName} disabled={disabled}>
            <div
              className={`${styles["uil-textfield-search-icon-wrapper"]}`}
              {...(!disabled && {
                onClick: iconClickHandler,
              })}
            >
              <span
                className={`${styles["uil-textfield-search-searchIcon"]} ${
                  styles["uil-dl-icon-Search"]
                } ${disabled ? styles["disabled"] : ""}`.trim()}
              ></span>
            </div>
            <div className={styles["uil-textfield-search-field"]}>
              <input
                ref={inputRef}
                type="search"
                className={`${styles["uil-textfield-search-input"]}`}
                placeholder={placeholder}
                disabled={disabled}
                value={inputValue || ""}
                onFocus={onFocusEventHandler}
                onBlur={onBlurEventHandler}
                onChange={searchInputHandler}
                onKeyUp={keyUpHandler}
                // onKeyDown={handleEnterKeyPressed}
                {...htmlProps}
              />
            </div>
            {isforDropdown ? (
              <div
              className={`${styles["uil-textfield-search-field-close"]} ${
                inputValue?.length > 0
                  ? `${styles["uil-textfield-search-field-close-visible"]}`
                  : ""
              }`}
              {...(!disabled && {
                onClick: clearSearchInput,
              })}
            >
              <span
                className={`${styles["uil-search-cancel-icon"]} ${
                  styles["uil-dl-icon-Close"]
                }
                 ${disabled ? styles["disabled"] : ""}`}
              ></span>
            </div>
            ) : (
              <div
                className={`${styles["uil-textfield-search-field-close"]} ${
                  inputValue?.length > 0
                    ? `${styles["uil-textfield-search-field-close-visible"]}`
                    : ""
                }`}
                {...(!disabled && {
                  onClick: clearSearchInput,
                })}
              >
                <span
                  className={`${styles["uil-search-cancel-icon"]} ${
                    styles["uil-dl-icon-Close"]
                  }
                   ${disabled ? styles["disabled"] : ""}`}
                ></span>
              </div>
            )}
            {isStarVisible && (
              <div
                className={`${styles["uil-textfield-search-star"]} ${
                  borderClass
                    ? classNames(
                        [styles["uil-textfield-search-extraIcon"]],
                        [styles["uil-textfield-search-star-leftborder"]]
                      )
                    : styles["uil-textfield-search-extraIcon"]
                } ${disabled ? styles["disabled"] : ""}`}
              >
                <span
                  className={`${styles["uil-dl-icon-Star"]} ${
                    disabled
                      ? styles["disabled"]
                      : styles["uil-color-secondary"]
                  }`}
                  {...(!disabled && {
                    onClick: starIconClickhandler,
                  })}
                ></span>
              </div>
            )}

            {isFilterVisible && (
              <div
                className={classNames(
                  `${styles["uil-textfield-search-extraIcon"]} ${styles["uil-textfield-search-filter"]} ${disabled ? styles["disabled"] : ""}`
                )}
              >
                <span
                  className={`${styles["uil-dl-icon-Filter"]} ${
                    disabled
                      ? styles["disabled"]
                      : styles["uil-color-secondary"]
                  }`}
                  {...(!disabled && {
                    onClick: filterIconClickhandler,
                  })}
                ></span>
              </div>
            )}
          </div>

          {inputValue?.length >= 1 && suggestions?.length > 0 && (
          <div className={styles["uil-search-list"]}>
            
           {autocompleteListToRender}
        </div>
            
          )}
        </div>
     
    </React.Fragment>
  );
};

UilTextFieldSearch.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.element,
  placeholder: PropTypes.element,
  customClasses: PropTypes.string,
  onSearchIconClick: PropTypes.func,
  size: PropTypes.element,
  isError: PropTypes.bool,
  isforDropdown: PropTypes.bool,
  isFilledBackground: PropTypes.bool,
  suggestionsList: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFilterVisible: PropTypes.bool,
  isStarVisible: PropTypes.bool,
  starIconClickhandler: PropTypes.func,
  onChange: PropTypes.func,
  filterIconClickhandler: PropTypes.func,
  htmlProps: PropTypes.object,
  handleEnterKeyPressed: PropTypes.func,
  options: PropTypes.object
};

export default UilTextFieldSearch;
