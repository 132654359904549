import React from "react";
import PropTypes from "prop-types";
import styles from "./uil_label.module.css";
import UilTooltip from "../UilTooltip/UilTooltip";
import styled from "styled-components";
import classNames from "classnames";
const UilLabel = (props) => {
  const {
    id,
    disabled,
    label,
    iconPosition,
    required,
    tooltip,
    customClasses,
    icon,
    tooltipPosition,
    counterText,
  } = props;

  const IconAfter = styled.span`
    &::after {
      content: "${props.icon}";
    }
  `;
  const IconBefore = styled.span`
    &::before {
      content: "${props.icon}";
    }
  `;
  if (typeof icon === "string") {
    return (
      <div>
        <div
          className={styles["uil-label"]}
          id={id}
          disabled={disabled}
          style={customClasses}
        >
          {!iconPosition && !counterText && (
            <div style={customClasses}>
              {label}
              {required && (
                <span
                  className={styles["uil-icon-mandatory"]}
                  disabled={disabled}
                ></span>
              )}
            </div>
          )}

          {iconPosition && iconPosition === "Icon Left" && (
            <span className={styles["uil-dl-icon-customicon"]}>
              <span style={customClasses}>
                <span style={{ paddingRight: "8px" }}>
                  {label ? (
                    <UilTooltip
                      id={id + "tip1"}
                      position={tooltipPosition}
                      tooltipText={tooltip}
                      children={
                        <span
                          className={styles["uil-toolTipIcon"]}
                          disabled={disabled}
                        >
                          <IconBefore></IconBefore>
                        </span>
                      }
                    ></UilTooltip>
                  ) : null}
                </span>
                {label}
                {required &&
                  (label ? (
                    <span
                      className={styles["uil-icon-mandatory"]}
                      disabled={disabled}
                    ></span>
                  ) : null)}
                {counterText && (
                  <span
                    className={classNames(
                      [styles["uil-label-countertext"]],
                      [styles["uil-label-countertext-withicon"]]
                    )}
                    disabled={disabled}
                  >
                    {counterText}
                  </span>
                )}
              </span>
            </span>
          )}

          {iconPosition && iconPosition === "Icon Right" && (
            <span className={styles["uil-dl-icon-customicon"]}>
              <span style={customClasses}>
                {label}
                {required &&
                  (label ? (
                    <span
                      className={styles["uil-icon-mandatory"]}
                      disabled={disabled}
                    ></span>
                  ) : null)}
                <span style={{ paddingLeft: "8px" }}>
                  {label ? (
                    <UilTooltip
                      id={id + "tip2"}
                      position={tooltipPosition}
                      tooltipText={tooltip}
                      children={
                        <span
                          className={styles["uil-toolTipIcon"]}
                          disabled={disabled}
                        >
                          <IconAfter></IconAfter>
                        </span>
                      }
                    ></UilTooltip>
                  ) : null}
                </span>
                {counterText && (
                  <span
                    className={classNames(
                      [styles["uil-label-countertext"]],
                      [styles["uil-label-countertext-withicon"]]
                    )}
                    disabled={disabled}
                  >
                    {counterText}
                  </span>
                )}
              </span>
            </span>
          )}

          <span className={styles["uil-span-container"]}>
            {iconPosition === "No Icon" &&(
              <div style={customClasses}>
                {label}
                {required && (
                  <>
                    <span
                      className={styles["uil-icon-mandatory"]}
                      disabled={disabled}
                    ></span>
                    <span
                      className={classNames(
                        [styles["uil-label-countertext"]],
                        [styles["uil-label-countertext-mandatory"]]
                      )}
                      disabled={disabled}
                    >
                      {counterText}
                    </span>
                  </>
                )}
                {!required && counterText&& (
                <span
                  className={classNames(
                    [styles["uil-label-countertext"]],
                    [styles["uil-label-countertext-mandatory"]]
                  )}
                  disabled={disabled}
                >
                  {counterText}
                </span>
                )}
              </div>
            )}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className={styles["uil-label"]}
          id={id}
          disabled={disabled}
          style={customClasses}
        >
          {!iconPosition && !counterText && (
            <div style={customClasses}>
              {label}
              {required &&
                (label ? (
                  <span
                    className={styles["uil-icon-mandatory"]}
                    disabled={disabled}
                  ></span>
                ) : null)}
            </div>
          )}

          {iconPosition && iconPosition === "Icon Left" && (
            <span className={styles["uil-dl-icon-customicon"]}>
              <span style={customClasses}>
                {label ? (
                  <UilTooltip
                    id={id + "tip1"}
                    position={tooltipPosition}
                    tooltipText={tooltip}
                    children={
                      <span
                        className={styles["uil-toolTipIcon"]}
                        disabled={disabled}
                      >
                        {icon}
                      </span>
                    }
                  ></UilTooltip>
                ) : null}
                {label}
                {required &&
                  (label ? (
                    <span
                      className={styles["uil-icon-mandatory"]}
                      disabled={disabled}
                    ></span>
                  ) : null)}
                {counterText && (
                  <span
                    className={classNames(
                      [styles["uil-label-countertext"]],
                      [styles["uil-label-countertext-withicon"]]
                    )}
                    disabled={disabled}
                  >
                    {counterText}
                  </span>
                )}
              </span>
            </span>
          )}

          {iconPosition && iconPosition === "Icon Right" && (
            <span className={styles["uil-dl-icon-customicon"]}>
              <span style={customClasses}>
                {label}
                {required &&
                  (label ? (
                    <span
                      className={styles["uil-icon-mandatory"]}
                      disabled={disabled}
                    ></span>
                  ) : null)}
                {label ? (
                  <UilTooltip
                    id={id + "tip1"}
                    position={tooltipPosition}
                    tooltipText={tooltip}
                    children={
                      <span
                        className={styles["uil-toolTipIcon"]}
                        disabled={disabled}
                      >
                        {icon}
                      </span>
                    }
                  ></UilTooltip>
                ) : null}
                {counterText && (
                  <span
                    className={classNames(
                      [styles["uil-label-countertext"]],
                      [styles["uil-label-countertext-withicon"]]
                    )}
                    disabled={disabled}
                  >
                    {counterText}
                  </span>
                )}
              </span>
            </span>
          )}

          <span className={styles["uil-span-container"]}>
            {iconPosition === "No Icon" &&(
              <div style={customClasses}>
                {label}
                {required && (
                  <>
                    <span
                      className={styles["uil-icon-mandatory"]}
                      disabled={disabled}
                    ></span>
                    {counterText && (<span
                      className={classNames(
                        [styles["uil-label-countertext"]],
                        [styles["uil-label-countertext-mandatory"]]
                      )}
                      disabled={disabled}
                    >
                      {counterText}
                    </span>)}
                  </>
                )}
                {!required && (
                  <span
                    className={styles["uil-label-countertext"]}
                    disabled={disabled}
                  >
                    {counterText}
                  </span>
                )}
              </div>
            )}
          </span>
        </div>
      </div>
    );
  }
};

UilLabel.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.element,
  iconPosition: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.element,
  tooltip: PropTypes.element,
  tooltipPosition: PropTypes.string,
  counterText: PropTypes.element,
  customClasses: PropTypes.any,
};

export default UilLabel;
