import React, { useState, useRef, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import UilTooltip from "../UilTooltip/UilTooltip.jsx";
import UilCheckbox from "../UilCheckbox/UilCheckbox.jsx";
import styles from "./uil_table.module.css";
import UilPagination from "../UilPagination/UilPagination.jsx";
import UilRadioButton from "../UilRadioButton/UilRadioButton.jsx";
import UilTextField from "../UilTextField/UilTextField.jsx";
import { cloneDeep } from "lodash";
const UilTable = ({
  type = "basic",
  id,
  disabled = false,
  selectAll = false,
  borderless = false,
  bottomBorder = false,
  variantType,
  showHorizontalScrollbar,
  customClasses,
  tableData,
  changeHandler = undefined,
  sortHandler = undefined,
  defaultSortDirection,
  defaultSortColumn,
  rightHandIcons,
  showEditableFields = false,
  isWithRadioButton,
  isgreyhoverEnabled,
  isPaginationVisible,
  TableHeader,
  TableFooter,
  contentPerPage,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [sortConfig, setSortConfig] = useState(undefined);
  const [sortedBodyData, setSortedBodyData] = useState(undefined);
  const [data, setData] = useState(tableData);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [itemId, setitemId] = useState("");

  const handleHover = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  // Reference to table body
  const tableBodyRef = createRef();

  const sortIconStyle = () => {
    return {
      display: "inline",
      paddingLeft: "8px",
      fontSize: "20px",
      verticalAlign: "bottom",
      // position: "sticky",
    };
  };
  // Return true, if data has not changed. False if changed.
  function customDataCompare(prevData, data) {
    const prevDataSelected = [];
    const dataSelected = [];
    const prevDataMetadataIds = [];
    const dataMetadataIds = [];
    let dataLengthChanged = false;
    let dataOrderChanged = false;
    const prevDataFun = (prevData, data) => prevData && data;
    if (prevDataFun()) {
      prevData.body.forEach((element) => {
        if (element.selected) {
          prevDataSelected.push(element.metadata[0].id);
        }
        if (element.metadata) {
          prevDataMetadataIds.push(element.metadata[0].id);
        }
      });
      data.body.forEach((element) => {
        if (element.selected) {
          dataSelected.push(element.metadata[0].id);
        }
        if (element.metadata) {
          dataMetadataIds.push(element.metadata[0].id);
        }
      });
      dataLengthChanged = prevData.body.length !== data.body.length;
      // Check items are in same order. If applications sorted items the selectedRows must be updated. True if match => equals.
      if (!dataLengthChanged) {
        dataOrderChanged = !prevDataMetadataIds.every(
          (id, index) => dataMetadataIds[index] === id
        );
      }
    }
    // compare arrays
    const dataChanged = (!prevData && data) || (prevData && !data);
    return (
      !dataChanged &&
      !dataLengthChanged &&
      prevDataSelected.length === dataSelected.length &&
      prevDataSelected.every((id) => dataSelected.includes(id)) &&
      !dataOrderChanged
    );
  }

  // This will return current data object in the dataRef.current and after
  // that replace that with a clone of the new data given as parameter value.
  // Without cloning the comparison doesn't work since the data property is
  // referring to the same object between calls.
  function usePreviousData(value) {
    const dataRef = useRef();
    useEffect(() => {
      // If data has changed, do deep copy for next comparison.
      if (type !== "basic" && !customDataCompare(dataRef.current, data)) {
        dataRef.current = cloneDeep(value);
      }
    }, [value]);
    return dataRef.current;
  }

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function updateSelectedRows(prevData, data) {
    if (!customDataCompare(prevData, data)) {
      const newSelectedRows = [];
      data.body.forEach((element, index) => {
        if (element.selected) {
          newSelectedRows.push(index);
        }
      });

      setSelectedRows(newSelectedRows);
    }
  }

  const prevData = usePreviousData(data);

  // Update selected rows after render
  useEffect(() => {
    if (type !== "basic") {
      updateSelectedRows(prevData, data);
    }
  }, [data, prevData, updateSelectedRows, type]);

  const typeClass = classNames(styles["uil-table"], {
    [styles["uil-table-no-border"]]: borderless,
    [styles["uil-table-bottom-border"]]: bottomBorder,
    [styles.disabled]: disabled,
  });

  const multiSelectCheckboxChangeHandler = (_event, rowIndex) => {
    const selectedRow = [rowIndex];
    const newSelected = selectedRows
      .filter((x) => !selectedRow.includes(x))
      .concat(selectedRow.filter((x) => !selectedRows.includes(x)));
    setSelectedRows(newSelected);
    // Pass selected row data to parent
    if (changeHandler) {
      const arrayData = [];
      // Use sorted data, if table is sorted.
      let tableBodyData = data.body;
      if (sortConfig && sortedBodyData) {
        tableBodyData = sortedBodyData;
      }
      tableBodyData.forEach((item, index) => {
        if (newSelected.includes(index)) {
          arrayData.push(item);
        }
      });
      changeHandler(arrayData);
    }
  };

  const multiSelectAllCheckboxChangeHandler = (event) => {
    if (!disabled) {
      const selectAll = event.target.checked;
      const newSelected = [];
      if (selectAll) {
        for (let index = 0; index < data.body.length; index++) {
          if (!data.body[index].disabled) {
            newSelected.push(index);
          }
        }
      }

      setSelectedRows(newSelected);
      setAllRowsSelected(newSelected.length !== 0);

      // Pass selected row data to parent
      if (changeHandler) {
        const arrayData = [];
        data.body.forEach((item, index) => {
          if (newSelected.includes(index)) {
            arrayData.push(item);
          }
        });
        changeHandler(arrayData);
      }
    }
  };

  const handleSortClick = (sortBy) => {
    // Set the column to sort by.
    let direction = "ascending";
    if (sortConfig && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (sortConfig && sortConfig.direction === "descending") {
      direction = "ascending";
    }
    setSortConfig({ sortBy, direction });
    // Notify parent application of sorting.
    // In this case sorting is the responsibility of the application.
    sortHandlerfunc(sortBy, direction);
  };

  const sortHandlerfunc = (sortBy, direction) => {
    // Sort data and update the table.
    let sortableItems = [...data.body];
    let items = [data];
    const columnIndex = data.head.findIndex(
      (element) => element.content === sortBy
    );

    sortableItems.sort((row1, row2) => {
      if (row1.data[columnIndex].content < row2.data[columnIndex].content) {
        return direction === "ascending" ? -1 : 1;
      }
      if (row1.data[columnIndex].content > row2.data[columnIndex].content) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    items[0].body = sortableItems;
    setData(items[0]);
  };

  /** utilty function to calculate range */
  const calculateRange = (tableData, rowsPerPage) => {
    const range = [];
    const num = Math.ceil(tableData.length / rowsPerPage);
    for (let i = 1; i <= num; i++) {
      range.push(i);
    }
    return range;
  };
  /** utilty function to slice data on basis of page number */
  const sliceData = (tableData, page, rowsPerPage) => {
    return tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };
  /** custom hook to display pagination in component*/
  const useTable = (tableData, page, rowsPerPage) => {
    const [tableRange, setTableRange] = useState([]);
    const [slice, setSlice] = useState([]);

    useEffect(() => {
      const range = calculateRange(tableData, rowsPerPage);
      setTableRange([...range]);

      const slice = sliceData(tableData, page, rowsPerPage);
      setSlice([...slice]);
    }, [setTableRange, page, rowsPerPage, tableData]);

    return { slice, range: tableRange };
  };

  const headerCells = [];
  let headerCellIndex = 0;
  // Array of refs for header row
  const headerCellRefs = useRef([]);
  headerCellRefs.current = data.head
    ? Array(data.head.length)
        .fill()
        .map(() => createRef())
    : null;

  // Check if head is defined before using it
  data.head &&
    data.head.forEach((element, i) => {
      const headerCellIndexCount = (headerCellIndex += 1);
      const cell = (
        <th
          // first data class is optional class and not needed everytime
          className={element.sortable ? styles["uil-clickable"] : ""}
          key={Math.random()}
          ref={headerCellRefs.current[headerCellIndex - 1]}
          onClick={
            element.sortable
              ? () => handleSortClick(element.content)
              : undefined
          }
        >
          <div
            style={{
              marginRight: "8px",
              display: "inline-flex",
              float: "left",
              width: "max-content",
            }}
          >
            {element.tooltip ? (
              <UilTooltip
                id={element.content}
                position={data.tooltipPosition}
                tooltipText={element.tooltip}
                event="hover"
                customChildrenClass={
                  disabled
                    ? classNames(
                        styles["uil-table-head-content"],
                        styles["disabled"]
                      )
                    : styles["uil-table-head-content"]
                }
                children={element.content}
              ></UilTooltip>
            ) : (
              element.content
            )}
            {!disabled && (
              <span
                // Sort icon style depends on the icon (sorted or not)
                style={sortIconStyle(
                  sortConfig && sortConfig.sortBy === element.content
                )}
                className={
                  sortConfig && sortConfig.sortBy === element.content
                    ? sortConfig.direction === "ascending"
                      ? styles["uil-dl-icon-ascarrow"]
                      : styles["uil-dl-icon-descarrow"]
                    : element.sortable
                    ? styles["uil-dl-icon-sort"]
                    : undefined
                }
              ></span>
            )}
            {disabled && (
              <span
                // Sort icon style depends on the icon (sorted or not)
                style={sortIconStyle(
                  sortConfig && sortConfig.sortBy === element.content
                )}
                className={
                  sortConfig && sortConfig.sortBy === element.content
                    ? sortConfig.direction === styles["ascending"]
                      ? classNames(
                          styles["uil-dl-icon-ascarrow"],
                          styles["disabled"]
                        )
                      : classNames(
                          styles["uil-dl-icon-descarrow"],
                          styles["disabled"]
                        )
                    : element.sortable
                    ? classNames(styles["uil-dl-icon-sort"], styles["disabled"])
                    : undefined
                }
              ></span>
            )}
          </div>
        </th>
      );

      headerCells.push(cell);
    });
  const emptyCell = <th></th>;
  headerCells.push(emptyCell);

  const columnGroup = (
    <React.Fragment>
      {data.colgroup && (
        <colgroup>
          {data.colgroup.map((col, colIndex) => (
            <col key={colIndex} span={col.span} style={col.style}></col>
          ))}
        </colgroup>
      )}
    </React.Fragment>
  );
  const Header = (
    <React.Fragment>
      {data.head && (
        <thead>
          <tr
            className={classNames(
              styles["uil-table-row"],
              styles["uil-table-heading"]
            )}
            disabled={disabled}
          >
            {type === "multi" &&
              (selectAll ? (
                <th className={styles.rowcheck} style={{ padding: "0px" }}>
                  <div
                    className={classNames(
                      styles["uil-checkbox"],
                      styles["uil-table-heading"],
                      styles["uil-table-header-checkbox"]
                    )}
                  >
                    {!isWithRadioButton && (
                      <UilCheckbox
                        type="checkbox"
                        id={id + "-head"}
                        checked={allRowsSelected}
                        disabled={disabled}
                        onChange={(event) =>
                          multiSelectAllCheckboxChangeHandler(event)
                        }
                      />
                    )}
                  </div>
                </th>
              ) : (
                <th className={styles["rowcheck"]}></th>
              ))}
            {headerCells}
          </tr>
        </thead>
      )}
    </React.Fragment>
  );

  // Array of refs for body row
  const bodyCellRefs = useRef([[]]);

  const getSortedBodyData = (bodyData) => {
    const sortableItems = [...bodyData];

    if (sortConfig) {
      const columnIndex = data.head.findIndex(
        (element) => element.content === sortConfig.sortBy
      );

      sortableItems.sort((row1, row2) => {
        if (row1.data[columnIndex].content < row2.data[columnIndex].content) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (row1.data[columnIndex].content > row2.data[columnIndex].content) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    // Need to refresh the selected rows?
    const newSelectedRows = [];
    sortableItems.forEach((element, index) => {
      if (element.selected) {
        newSelectedRows.push(index);
      }
    });

    const selectedRowsNotChanged =
      selectedRows.length === newSelectedRows.length &&
      selectedRows.every(function (element) {
        return newSelectedRows.indexOf(element) !== -1;
      });

    if (!selectedRowsNotChanged) {
      setSelectedRows(newSelectedRows);
    }

    // Did the table order change due to sorting?
    let tableOrderChanged = false;
    let tableDataBeforeSort = bodyData;
    if (sortedBodyData) {
      tableDataBeforeSort = sortedBodyData;
    }

    // Compare data array lengths
    if (tableDataBeforeSort.length !== sortableItems.length) {
      tableOrderChanged = true;
    }

    // Compare data array metadata to find out if the data order has changed.
    if (!tableOrderChanged) {
      tableDataBeforeSort.forEach((element, index) => {
        if (
          element.metadata &&
          element.metadata[0].id !== sortableItems[index].metadata[0].id
        ) {
          tableOrderChanged = true;
        }
      });
    }

    return [sortableItems, tableOrderChanged];
  };

  const getDefaultSortBodyData = (bodyData) => {
    const sortableItems = [...bodyData];
    if (sortConfig === undefined) {
      if (defaultSortColumn && defaultSortDirection) {
        const columnIndex = tableData.head.findIndex(
          (element) => element.content === defaultSortColumn
        );
        sortableItems.sort((a, b) => {
          if (a.data[columnIndex].content === null) return 1;
          if (b.data[columnIndex].content === null) return -1;
          if (
            a.data[columnIndex].content === null &&
            b.data[columnIndex].content === null
          )
            return 0;
          return (
            a.data[columnIndex].content
              .toString()
              .localeCompare(b.data[columnIndex].content.toString(), "en", {
                numeric: true,
              }) * (defaultSortDirection === "ascending" ? 1 : -1)
          );
        });
      }
    }
    return sortableItems;
  };

  const handleChange = (rowIndex, colIndex, event) => {
    let editableTable = data;
    const newData = editableTable.body.map((item, index) => {
      if (index === rowIndex) {
        let currentItem = item;
        currentItem.data[colIndex].content = event.target.value;
        return currentItem;
      }
      return item;
    });
    editableTable.body = newData;
    setData(editableTable);
    setSortedBodyData(newData);
  };

  // Get sorted table body, if sortHandler is not provided.
  let [sortedBody, tableOrderChanged] = [
    getDefaultSortBodyData(data.body),
    false,
  ];
  if (!sortHandler) {
    [sortedBody, tableOrderChanged] = getSortedBodyData(data.body);
  }

  // Store the sorted data for using instead of original data, since rows are not in the same order after sorting.
  if (tableOrderChanged) {
    setSortedBodyData(sortedBody);
  }
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data.body, page, contentPerPage);

  const tableMainData = isPaginationVisible ? slice : sortedBody;

  const body = (
    <tbody ref={tableBodyRef}>
      {tableMainData.map((row, rowIndex) => {
        let rowCellIndex = 0;
        const rowIndexCount = (rowIndex += 1);
        const changeHandlerFun = () => type === "basic" && changeHandler;
        const rowIndexFun = () => !row.content || !row.content;
        const rowContentFun = () =>
          row.content || changeHandler ? styles["uil-clickable"] : "";
        const disabledFun = () => disabled || row.disabled;
        const rowDisabledFun = () => !row.disabled && !disabled;
        // Create refs for the cells in the row
        bodyCellRefs.current[rowIndex] = Array(row.data.length)
          .fill()
          .map(() => createRef());
        return (
          <React.Fragment key={"body-row-fragment" + rowIndexCount}>
            <tr
              onMouseEnter={() => handleHover(rowIndex)}
              onMouseLeave={handleMouseLeave}
              className={`${styles["uil-table-row"]} ${rowContentFun()} ${
                isgreyhoverEnabled
                  ? styles["uil-table-grey-row"]
                  : styles["uil-table-lightpink-row"]
              }`}
              disabled={disabledFun()}
              bodyrowindex={rowIndex - 1}
              onClick={(event) => {
                if (rowDisabledFun()) {
                  if (
                    changeHandlerFun() &&
                    rowIndexFun() &&
                    !(
                      event.target.tagName.toLowerCase() === "td" &&
                      event.target.className &&
                      event.target.className.includes(styles["uil-clickable"])
                    )
                  ) {
                    // Check if change handler is added to basic table.
                    // Then check if row is not expanding or expanding happens only from arrow
                    // check if click hit the expanding row arrow. If not then call changeHandler
                    changeHandler([row]);
                  } else {
                    console.log(type);
                  }
                }
              }}
            >
              {type === "multi" && (
                <td className={styles.rowcheck}>
                  <div
                    className={classNames(
                      styles["uil-checkbox"],
                      styles["uil-checkbox-table"],
                      styles["uil-checkbox-table-coloumn"]
                    )}
                  >
                    {isWithRadioButton ? (
                      <UilRadioButton
                        id={rowIndex}
                        onChange={(e) => setitemId(rowIndex)}
                        value={row}
                        checked={rowIndex === itemId}
                      />
                    ) : (
                      <UilCheckbox
                        type="checkbox"
                        id={id + "-body-row-" + (rowIndex - 1)}
                        checked={selectedRows.includes(rowIndex - 1)}
                        disabled={disabled}
                        bodyrowindex={rowIndex - 1}
                        onChange={(event) => {
                          if (!row.disabled && !disabled) {
                            multiSelectCheckboxChangeHandler(
                              event,
                              rowIndex - 1
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                </td>
              )}
              {row.data.map((cell) => {
                const contentString = cell.content;
                //? ReactDOMServer.renderToString(cell.content):'';
                // Extract the text content from the string using a regular expression
                const contentText = contentString;
                const cellFun = (rowCellIndex += 1);
                return (
                  <td
                    key={"row-" + (rowIndex - 1) + "-cell-" + cellFun}
                    style={{
                      ...cell.style,
                    }}
                    ref={bodyCellRefs.current[rowIndex - 1][rowCellIndex - 1]}
                  >
                    {cell.tooltip ? (
                      data.head[cellFun - 1].editable && showEditableFields ? (
                        <UilTooltip tooltipText={cell.tooltip}>
                          <UilTextField
                            customClasses={styles["uil-table-textfield"]}
                            onChange={(e) =>
                              !disabled &&
                              handleChange(rowIndex - 1, cellFun - 1, e)
                            }
                            value={
                              Array.isArray(contentText)
                                ? `${cell.content[0]} +${cell.content.length}`
                                : contentText
                            }
                            disabled={disabled}
                          ></UilTextField>
                        </UilTooltip>
                      ) : (
                        <UilTooltip
                          id={rowIndex + "" + cellFun}
                          position="top"
                          customClasses={
                            row.data.length - 1 === row.data.lastIndexOf(cell)
                              ? styles["uil-table-action-col"]
                              : ""
                          }
                          tooltipText={cell.tooltip}
                        >
                          {cell.content}
                        </UilTooltip>
                      )
                    ) : data.head[cellFun - 1].editable &&
                      showEditableFields ? (
                      <UilTextField
                        customClasses={styles["uil-table-textfield"]}
                        onChange={(e) =>
                          !disabled &&
                          handleChange(rowIndex - 1, cellFun - 1, e)
                        }
                        value={
                          Array.isArray(contentText)
                            ? `${cell.content[0]} +${cell.content.length}`
                            : contentText
                        }
                        disabled={disabled}
                      ></UilTextField>
                    ) : (
                      <span
                        className={
                          row.data.length - 1 === row.data.lastIndexOf(cell)
                            ? styles["uil-table-action-col"]
                            : ""
                        }
                      >
                        {cell.content}
                      </span>
                    )}
                  </td>
                );
              })}
              <td>
                {hoverIndex === rowIndex ? (
                  <span>{rightHandIcons}</span>
                ) : (
                  <span style={{ transition: "none", opacity: 0 }}>
                    {rightHandIcons}
                  </span>
                )}
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </tbody>
  );

  const tableClasses = classNames(
    `${typeClass} ${
      variantType === "defaultHeader"
        ? `${styles["uil-table-defaultHeader"]}`
        : `${styles["uil-table-largeHeader"]}`
    }  ${customClasses}`,
    {
      [styles["horizontal-scrollbar"]]: showHorizontalScrollbar,
    }
  );
  const horizontalscrollbar = showHorizontalScrollbar
    ? "horizontal-scrollbar"
    : "";

  function getPageNumber(data) {
    setPage(data);
  }

  return (
    <div
      className={
        `${styles["table-container"]}` +
        " " +
        (showHorizontalScrollbar && !disabled
          ? `${styles[horizontalscrollbar]}`
          : classNames(
              `${styles[horizontalscrollbar]}`,
              styles["disabled-scrollbar"]
            ))
      }
    >
      {TableHeader && (
        <div className={styles["uil-table-headercell"]}>{TableHeader}</div>
      )}
     {/* <div className={styles["table-box"]}>  */}
        <table
          disabled={disabled}
          className={tableClasses}
          
        >
          {columnGroup}
          {Header}
          {body}
        </table>
        {/* </div> */}
     
      {isPaginationVisible && (
        <div className={styles["uil-table-navigation"]}>
          <UilPagination
            onChange={getPageNumber}
            pageSize={1}
            currentPage={page}
            totalRecordsCount={range.length}
          />
        </div>
      )}
      {TableFooter && <>{TableFooter}</>}
    </div>
  );
};

UilTable.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  tableData: PropTypes.object,
  changeHandler: PropTypes.func,
  sortHandler: PropTypes.func,
  defaultSortDirection: PropTypes.string,
  defaultSortColumn: PropTypes.string,
  customClasses: PropTypes.any,
  borderless: PropTypes.bool,
  variantType: PropTypes.string,
  bottomBorder: PropTypes.bool,
  rightHandIcons: PropTypes.node,
  showEditableFields: PropTypes.bool,
  isWithRadioButton: PropTypes.bool,
  isgreyhoverEnabled: PropTypes.bool,
  isPaginationVisible: PropTypes.bool,
  TableHeader: PropTypes.any,
  TableFooter: PropTypes.any,
  contentPerPage: PropTypes.number,
};

export default UilTable;
