import { React, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_banner.module.css";

const UilBanner = (props) => {
  const {
    id,
    type,
    title,
    icon,
    content,
    readMore,
    readLess,
    onClick,
    backgroundImage,
    leftSpacing,
    showFooter,
    showContent,
    showSubHeading,
    showTitle,
    customClasses,
  } = props;

  const [isReadMore, setIsReadMore] = useState(false);

  const sizeClass = classNames(styles["uil-banner"], customClasses, {
    [styles["uil-banner-large"]]: props.size === "large",
    [styles["uil-banner-medium"]]: props.size === "medium",
    [styles["uil-banner-small"]]: props.size === "small",
  });

  const typeClass = classNames({
    [styles["uil-banner-navigation"]]: type === "navigation",
    [styles["uil-banner-hero"]]: type === "hero",
  });

  const imageClass =
    type === "hero" && !!backgroundImage
      ? {
          backgroundImage: `url(${backgroundImage})`,
        }
      : {};

  const readMoreOnClick = () => {
    if (content?.length >= 400) setIsReadMore(!isReadMore);
  };

  return (
    <div
      id={id}
      className={classNames(`${styles[leftSpacing]} ${sizeClass}`)}
      style={imageClass}
    >
      <div className={classNames(`${typeClass}`)}>
        {type === "navigation" && (
          <div
            className={styles["uil-dl-icon-Arrow-left"]}
            onClick={onClick}
          ></div>
        )}

        <div>
          {showTitle ? (
            <div className={classNames(styles["uil-banner-title"])}>
              {title}
            </div>
          ) : null}

          {showContent ? (
            <div className={styles["uil-banner-content"]}>
              {content}
            </div>
          ) : null}

          {showSubHeading ? (
            <div className={styles["uil-banner-subheading"]}>
              {isReadMore ? readLess : readMore}

              <span
                className={styles["uil-dl-icon-Circle-chevron-right"]}
                
              >
                {icon}
              </span>
            </div>
          ) : null}

          <div className={styles["uil-banner-btn"]}>{showFooter}</div>
        </div>
      </div>
    </div>
  );
};

UilBanner.propTypes = {
  id: PropTypes.string,
  title: PropTypes.element,
  content: PropTypes.element,
  customClasses: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.any,
  backgroundImage: PropTypes.element,
  leftSpacing: PropTypes.element,
  readMore: PropTypes.element,
  readLess: PropTypes.element,
  showFooter: PropTypes.any,
  showTitle: PropTypes.bool,
  showContent: PropTypes.bool,
  showSubHeading: PropTypes.bool,
};

UilBanner.defaultProps = {
  type: "large",
};

export default UilBanner;
