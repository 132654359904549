import React, { Component } from "react";
import StyleContext, { StyleContextProvider } from "../context/StyleContext";
import UilTable from "../../../app-components/UilTable/UilTable";
import UilAccordion from "../../../app-components/UilAccordion/uil_accordion";
import UilInPageNavigationContainer from "../../../app-components/UilInpageNavigation/UilInpageNavContainer";
import UilInpageNavigationLinkList from "../../../app-components/UilInpageNavigation/UilInpageNavigationLinkList";
import UilCard from "../../../app-components/UilCard/UilCard";
import ComponentData from "../static/ComponentData";
import Settings from "../static/settings";
import "./Style.css";
import ContentBlock from "../../../app-components/ContentBlock";
import Home from "../../home/content/home";
let contextType = null;

export default class Typography extends Component {
  componentDidMount() {}
  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };

  render() {
    return (
      <StyleContextProvider>
        <StyleContext.Consumer>
          {(styleContext) => {
            contextType = styleContext;
            return (
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20%",
                  }}
                >
                  <div className="styleContentSection">
                    <div className="typoSections" id="fontfamily">
                      <span className="typoContent" style={{ paddingTop: 0 }}>
                        {Settings.TypographyContent.Descriptions.TypographyDesc}
                      </span>
                    </div>
                    <ContentBlock section>
                      <div className="typoSections" id="fontfamily">
                        <span className="typoHeadings">
                          {Settings.TypographyContent.Titles.fontFamilyTitle}
                        </span>
                        <span className="typoContent">
                          {
                            Settings.TypographyContent.Descriptions
                              .fontFamilyDesc
                          }
                        </span>
                      </div>
                    </ContentBlock>
                    <ContentBlock>
                      <div id="fontstyle">
                        {ComponentData.UilFontStyleDetails.map((Content) => {
                          return (
                            <div id="divGreyScale">
                              <span className="typoHeadings">
                                {Content.title}
                              </span>

                              <span className="tableContent">
                                <UilTable
                                  type="basic"
                                  id="table_id"
                                  variantType="defaultHeader"
                                  showEditableFields={false}
                                  showHorizontalScrollbar={true}
                                  disabled={false}
                                  selectAll={false}
                                  borderless={false}
                                  bottomBorder={false}
                                  dotMenuListStyles=""
                                  defaultSortDirection=""
                                  defaultSortColumn="Firstname"
                                  isgreyhoverEnabled={false}
                                  contentPerPage={3}
                                  isPaginationVisible={false}
                                  tableData={Content.tableData}
                                  customClasses={"tablefontstyle"}
                                ></UilTable>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </ContentBlock>

                    <ContentBlock section>
                      <div className="typoSections" id="fonttype">
                        <span className="typoHeadings">Font types</span>
                        <span className="typoContent">
                          {ComponentData.UilFontTypeDetails.map((Content) => {
                            return (
                              <div>
                                <span className="tableContent">
                                  <UilAccordion
                                    type={Content.type}
                                    disable={Content.disabled}
                                    id={Content.id}
                                    title={Content.title}
                                    isCounterOption={Content.isCounterOption}
                                    children={Content.children}
                                    isIconOption={Content.isIconOption}
                                  ></UilAccordion>
                                </span>
                              </div>
                            );
                          })}
                        </span>
                      </div>
                    </ContentBlock>

                    <ContentBlock>
                      <div id="fonttokens">
                        {ComponentData.UilFontTokenDetails.map((Content) => {
                          return (
                            <div id="divGreyScale">
                              <span className="typoHeadings">
                                {Content.title}
                              </span>

                              <span className="tableContent">
                                <UilTable
                                  type="basic"
                                  id="table_id"
                                  variantType="defaultHeader"
                                  showEditableFields={false}
                                  showHorizontalScrollbar={true}
                                  disabled={false}
                                  selectAll={false}
                                  borderless={false}
                                  bottomBorder={false}
                                  dotMenuListStyles=""
                                  defaultSortDirection=""
                                  defaultSortColumn="Firstname"
                                  isgreyhoverEnabled={false}
                                  contentPerPage={3}
                                  isPaginationVisible={false}
                                  tableData={Content.tableData}
                                ></UilTable>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </ContentBlock>

                    <ContentBlock section>
                      <div id="fontcolortokens">
                        {ComponentData.UilFontColorTokenDetails.map(
                          (Content) => {
                            return (
                              <div id="divGreyScale">
                                <span className="typoHeadings">
                                  {Content.title}
                                </span>

                                <span className="tableContent">
                                  <UilTable
                                    type="basic"
                                    id="table_id"
                                    variantType="defaultHeader"
                                    showEditableFields={false}
                                    showHorizontalScrollbar={true}
                                    disabled={false}
                                    selectAll={false}
                                    borderless={false}
                                    bottomBorder={false}
                                    dotMenuListStyles=""
                                    defaultSortDirection=""
                                    defaultSortColumn="Firstname"
                                    isgreyhoverEnabled={false}
                                    contentPerPage={3}
                                    isPaginationVisible={false}
                                    tableData={Content.tableData}
                                  ></UilTable>
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </ContentBlock>

                    <ContentBlock section>
                      <div className="typoSections" style={{ minHeight: 500 }}>
                        <span className="typoHeadings">
                          {
                            Settings.TypographyContent.Titles
                              .tokenApplicationTitle
                          }
                        </span>
                        <span className="typoContent">
                          {
                            Settings.TypographyContent.Descriptions
                              .tokenApplicationDesc
                          }
                        </span>
                      </div>
                    </ContentBlock>
                  </div>
                  <div className="style-typography-inPageContainer">
                    {/* <div>
                      <UilInPageNavigationContainer>
                        <UilInpageNavigationLinkList
                          linkArray={[
                            {
                              linkName: "Font family",
                              linkPath: "#fontfamily",
                            },
                            {
                              linkName: "Font style",
                              linkPath: "#fontstyle",
                            },
                            {
                              linkName: "Font type",
                              linkPath: "#fonttype",
                            },
                            {
                              linkName: "Font tokens",
                              linkPath: "#fonttokens",
                            },
                            {
                              linkName: "Font color tokens",
                              linkPath: "#fontcolortokens",
                            },
                          ]}
                        ></UilInpageNavigationLinkList>
                      </UilInPageNavigationContainer>
                    </div> */}
                    <div className="typo-brandspace-card">
                      <UilCard
                        uppersection={ComponentData.UilCard.BasicUpperSection}
                        clickHandler={() => {
                          return <Home></Home>;
                        }}
                      ></UilCard>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </StyleContext.Consumer>
      </StyleContextProvider>
    );
  }
}
