import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_button.module.css";

const UilButton = (props) => {
  const [styleClass, setStyleClass] = useState("default");
  useEffect(() => {
    const typeClass = classNames(props.customClasses, [styles.uil_button], {
      [styles.uil_btn_square]: props.styleType === "square",
      [styles.uil_btn_round]: props.styleType === "round",
      [styles.uil_btn_default]: props.type === "default",
      [styles.uil_btn_primary]: props.type === "primary",
      [styles.uil_btn_secondary]: props.type === "secondary",
      [styles.uil_btn_link]: props.styleType === "link",
      [styles.uil_btn_fullwidth]: props.sizeType === "fullWidth",
      [styles.uil_btn_large]: props.sizeType === "large",
      [styles.uil_btn_medium]: props.sizeType === "medium",
      [styles.disabled]: disabled,
    });
    setStyleClass(typeClass)
  }, []);


  const populateStyleClass = (stylecss) => {
    const typeClass = classNames(props.customClasses, stylecss, [styles.uil_button], {
      [styles.uil_btn_square]: props.styleType === "square",
      [styles.uil_btn_round]: props.styleType === "round",
      [styles.uil_btn_default]: props.type === "default",
      [styles.uil_btn_primary]: props.type === "primary",
      [styles.uil_btn_secondary]: props.type === "secondary",
      [styles.uil_btn_link]: props.styleType === "link",
      [styles.disabled]: disabled,
    });
    setStyleClass(typeClass);
  }

  const setButtonStyle = () => {
    let selectedStyle = ''
    if (props.iconPosition === 'none') {
      if (props.sizeType === 'fullWidth') {
        (fitcontent ? selectedStyle = [styles.uil_btn_fitcontent_large] : selectedStyle = [styles.uil_btn_fullwidth])
      }
      else if (props.sizeType === 'medium') {
        fitcontent ? selectedStyle = [styles.uil_btn_fitcontent_medium] : selectedStyle = [styles.uil_btn_medium]
      }
      else if (props.sizeType === 'large') {
        fitcontent ? selectedStyle = [styles.uil_btn_fitcontent_large] : selectedStyle = [styles.uil_btn_large]
      }
    }
    else if (props.iconPosition != '') {
      if (props.iconPosition === 'op_iconPositionRight' || props.iconPosition === 'op_iconPositionLeft') {
        if (props.sizeType === 'fullWidth') {
          (fitcontent ? selectedStyle = [styles.uil_btn_fitcontent_large] : selectedStyle = [styles.uil_btn_withicon_fullwidth])
        }
        else if (props.sizeType === 'medium') {
          fitcontent ? selectedStyle = [styles.uil_btn_fitcontent_medium] : selectedStyle = [styles.uil_btn_withicon_medium]
        }
        else if (props.sizeType === 'large') {
          fitcontent ? selectedStyle = [styles.uil_btn_fitcontent_large] : selectedStyle = [styles.uil_btn_withicon_large]
        }

      }
      else if (iconPosition === 'op_iconOnly') {
        if (props.sizeType === 'fullWidth') { selectedStyle = [styles.uil_btn_icononly_fullwidth] }
        else if (props.sizeType === 'large') { selectedStyle = [styles.uil_btn_icononly_large] }
        else if (props.sizeType === 'medium') { selectedStyle = [styles.uil_btn_icononly_medium] }
      }
    }
    populateStyleClass(selectedStyle);
  }

  useEffect(() => {
    setButtonStyle()
  }, [props.iconPosition, props.sizeType, props.styleType, props.type, props.disabled, props.fitcontent]);

  const {
    id,
    label,
    icon,
    iconPosition,
    disabled,
    fitcontent,
    onClick,
    onKeydown,
  } = props;
  if (typeof (icon) === 'string') {
    var iconStyle = styles[icon] === undefined ? icon : styles[icon];
    return (
      <React.Fragment>
        <button
          id={id}
          className={styleClass}
          onClick={onClick && !disabled ? onClick : null}
          onKeyDown={onKeydown && !disabled ? onKeydown : null}
        >
          {iconPosition === "op_iconPositionLeft" && <span className={iconStyle}></span>}
          {iconPosition !== "op_iconOnly" && <span > {label}</span>}
          {iconPosition === "op_iconPositionRight" && <span className={iconStyle}></span>}
          {iconPosition === "op_iconOnly" && <span className={iconStyle}></span>}
        </button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <button
          id={id}
          className={styleClass}
          onClick={onClick && !disabled ? onClick : null}
          onKeyDown={onKeydown && !disabled ? onKeydown : null}
        >
          <span> {iconPosition === "op_iconPositionLeft" && <span>{icon}</span>}
            {iconPosition !== "op_iconOnly" && <span > {label}</span>}
            {iconPosition === "op_iconPositionRight" && <span>{icon}</span>}
            {iconPosition === "op_iconOnly" && <span>{icon}</span>}
          </span>
        </button>
      </React.Fragment>
    );

  }
};
UilButton.defaultProps = {
  disabled: false,
  fitcontent: false,
  sizeType: "uil-button-default",
  type: "default",
  iconPosition: 'none'
};

UilButton.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  sizeType: PropTypes.string,
  styleType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onKeydown: PropTypes.func,
  customClasses: PropTypes.any,
  fitcontent: PropTypes.bool
};

UilButton.propDescriptions = {
  type: "Type of the button. Possible values are default,primary, secondary and link",
  id: "Unique identifier for button",
  label: "Label or title for the button",
  icon: "used to pass classname of the icon",
  iconPosition: "Sets position of icon for button",
  sizeType: "Size Type of button - fullWdith, large, medium ",
  fitcontent: "Sets fitcontent for button",
  styleType: "Style Type of button - Square / Round",
  disabled: "Sets or returns whether the button is disabled, or not",
  onClick: "onclick Function of button.",
  onKeydown: "shows onkeydown action",
  customClasses: "class for customizing the button"

};

export default UilButton;