import React, { createRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_checkbox_radio.module.css";
import UilLabel from "../UilLabel/UilLabel.jsx";
import UilFootNote from "../UilFootNote/UilFootNote.jsx";

const uilCheckbox = (props) => {
  const {
    htmlProps,
    checked,
    id,
    value,
    disabled,
    title,
    footnoteText,
    label,
    allowClickPropagation,
    showLeft,
    customClasses,
    labelIcon,
    required,
    footnoteType,
    iconPosition,
    tooltip,
    tooltipPosition,
    errorText,
  } = props;

  const boxRef = createRef();
  const labelRef = createRef();

  const labelClickHandler = (event) => {
    if (!allowClickPropagation) {
      event.stopPropagation();
    }
  };

  const inputClickHandler = (event) => {
    if (!allowClickPropagation) {
      event.stopPropagation();
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const renderCheckBox = () => {
    return (
      <>
        <span
          className={classNames(
            [styles["uil-checkbox-main-span"]],
            [styles["uil-checkbox-span"]]
          )}
        >
          {!showLeft && label && (
            <span className={styles["uil-checkbox-sub-main"]}>
              <span
                className={styles["uil-form-item-title-text"]}
                ref={labelRef}
                disabled={props.disabled}
              >
                {label}
              </span>
            </span>
          )}
          <span
            className={classNames(
              [styles["uil-modified-checkbox"]],
              [styles["uil-checkbox"]],
              disabled ? [styles["disabled"]] : ""
            )}
          >
            <input
              {...htmlProps}
              disabled={disabled}
              type="checkbox"
              id={id}
              value={value}
              checked={checked}
              onChange={props.onChange}
              onClick={inputClickHandler}
            ></input>
            <label
              className={classNames(styles["uil-form-item-checkbox"], customClasses)}
              disabled={disabled}
              ref={boxRef}
              htmlFor={id}
              onClick={labelClickHandler}
            ></label>
          </span>
          {showLeft && label && (
            <span
              className={styles["uil-form-item-title-text"]}
              ref={labelRef}
              disabled={disabled}
            >
              {label}
            </span>
          )}
        </span>
        {footnoteText && (

        <span>
            <span
              className={classNames(
                disabled ? [styles["cursor-label-footnote"]] : ""
              )}
            >
              <UilFootNote
                footnoteText={footnoteText}
                type={footnoteType}
                tooltip={tooltip}
                tooltipPosition={tooltipPosition}
                disabled={disabled}
                errorText={errorText}
              ></UilFootNote>
            </span>
        </span>
          )}
      </>
    );
  };
  return (
    <React.Fragment>
      {title && (
        <div
          className={classNames([styles["uil-radio-container"]], customClasses)}
        >
          {
            <span
              className={classNames(
                disabled ? [styles["cursor-label-footnote"]] : ""
              )}
            >
              <UilLabel
                label={title}
                icon={labelIcon}
                iconPosition={iconPosition}
                required={required}
                tooltip={tooltip}
                tooltipPosition={tooltipPosition}
                disabled={disabled}
              />
            </span>
          }
          {renderCheckBox()}
        </div>
      )}
      {!title && renderCheckBox()}
    </React.Fragment>
  );
};

uilCheckbox.defaultProps = {
  disabled: false,
  checked: false,
};

uilCheckbox.propTypes = {
  htmlProps: PropTypes.object,
  value: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.any,
  footnoteText: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  allowClickPropagation: PropTypes.bool,
  showLeft: PropTypes.bool,
  customClasses: PropTypes.string,
  labelIcon: PropTypes.any,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.any,
  footnoteType: PropTypes.string,
  errorText: PropTypes.string,
};

uilCheckbox.propDescriptions = {
  value: "Sets or returns the value of the value attribute of the check box",
  checked: "Sets or returns the checked state of a check box",
  id: "Unique identifier for check box",
  disabled: "Sets or returns whether the check box is disabled, or not",
  title: "title for the check box",
  footnoteText: "Helper label for the check box",
  label: "title of the check box",
  onChange: "Function to return value of check box.",
};

export default uilCheckbox;
