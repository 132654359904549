import React, { useState, useRef, useEffect } from "react";
import IconPreviewBlock from "./IconPreviewBlock";
import "./iconRef.css";
import UilTextFieldSearch from "../UilTextFieldSearch/UilTextFieldSearch";
import { iconDataDL, iconDataAPS, iconTags } from "./IconData";

const searchBoxStyle = {
  width: "60%",
  margin: "auto",
};
const searchTextBoxStyle = {
  width: "100%",
};
const notFoundContentStyle = {
  justifyContent: "center",
  display: "flex",
  width: "100%",
};

const IconPreviewSection = ({ iconSet }) => {
  const [iconDisplaySet, setIconDisplaySet] = useState(() => {
    let initialState = [];
    if (iconSet === "DL") {
      initialState = iconDataDL;
    } else if (iconSet === "APS") {
      initialState = iconDataAPS;
    }
    return initialState;
  });
  const [iconSearchSet, setIconSearchSet] = useState(() => {
    let initialState = [];
    if (iconSet === "DL") {
      initialState = iconDataDL;
    } else if (iconSet === "APS") {
      initialState = iconDataAPS;
    }
    return initialState;
  });

  const [showCopyCodeToast, setShowCopyCodeToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastIcon, setToastIcon] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const handleCopyCode = (item) => {
    navigator.clipboard.writeText(item).then(
      () => {
        setToastMessage("Class name has been copied!");
        setToastIcon("uil-dl-icon-Thumb-up uil-color-white");
        setShowCopyCodeToast(true);
        setTimeout(() => {
          setShowCopyCodeToast(false);
        }, 3000);
      },
      () => {
        setToastMessage("Class name copy failed!");
        setToastIcon("uil-dl-icon-Thumb-down uil-color-white");
        setShowCopyCodeToast(true);
        setTimeout(() => {
          setShowCopyCodeToast(false);
        }, 3000);
      }
    );
  };

  useEffect(() => {
    filterIcons(searchValue);
  }, [searchValue]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const filterIcons = (searchValue) => {
    if (searchValue && searchValue.length > 0) {
      const result = iconDisplaySet.filter((item) =>
        item.toLowerCase().includes(searchValue)
      );
      // Check tag list as well
      const tagKeys = [];
      iconTags.map((item) => {
        const foundKeys = item.key.filter((key) =>
          key.toLowerCase().startsWith(searchValue)
        );
        if (foundKeys.length > 0) {
          tagKeys.push(item);
        }
      });
      // Combine results fron filters
      const tagItems = [];
      tagKeys.map((item) => {
        item.values.map((item) => tagItems.push(item));
      });
      const comb_result = result.concat(tagItems);
      // Remove duplicates from list
      const uniq_result = [...new Set(comb_result)];
      setIconSearchSet(uniq_result);
    } else {
      // If no value then show original list of icons
      setIconSearchSet(iconDisplaySet);
    }
  };

  const handleClose = () => {
    setSearchValue("");
  };
  return (
    <React.Fragment>
      <div class="uil-icon-seach-section-wrapper">
        <UilTextFieldSearch
          placeholder="Search"
          // onChange={(e)=>searchHandler(e)}
          handleEnterKeyPressed={(e) => searchHandler(e)}
          closeIconHandler={() => handleClose()}
        ></UilTextFieldSearch>
      </div>
      {iconSearchSet.length == 0 && (
        <div className="uil-dls-font-title-02" style={notFoundContentStyle}>
          No icons found with that search. &#129335;
        </div>
      )}
      <div class="uil-icon-section-wrapper">
        {iconSearchSet.map((item, index) => (
          <IconPreviewBlock
            title={item}
            key={index}
            onCopyIcon={() => handleCopyCode(item)}
          ></IconPreviewBlock>
        ))}
      </div>
    </React.Fragment>
  );
};
export default IconPreviewSection;
