import backgroundImage from "../../../assets/gradients/ACC_Gradient_Dark_6.jpg";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import UilTag from "../../../app-components/UilTag/UilTag";
import acn_logo from "../../../assets/img/AccentureLogo_black.png";

const componentData = {
  UilBanner: {
    type: "hero",
    size: "medium",
    title: "APP CoE Design System",
    content: (
      <span>
        A shared language for designers and developers to accelerate creation of{" "}
        <br />
        applications and deliver user experiences that are delightful,
        consistent, simple,
        <br /> purposeful and actionable.
        <br />
        <br />
        Standardized Design Language and components built on industry latest{" "}
        <br />
        framework. Accessibility compliant and is extensible per custom need.
      </span>
    ),

    backgroundImage: { backgroundImage },
    customClasses: "uil-banner-styles",
    showTitle: true,
    showContent: true,
    showSubHeading: false,
    leftSpacing: "small",
  },
  
  UilCard: [
    {
      cardId: "1",
      cardHeader: "SUGGESTED",
      cardTitle: "Styles",
      cardIcon: "uil-dl-icon-Arrow-right-up",
      description:
        "Here you will find all the specific information on the design system colors, typography and icons.",
        redirectTo: "/styles"
    },
    {
      cardId: "2",
      cardHeader: "SUGGESTED",
      cardTitle: "Design tokens",
      cardIcon: "uil-dl-icon-Arrow-right-up",
      description:
        "Here you will find all the specific information on the design system tokens.",
        redirectTo: "/designers/3"
    },
  ],
  uppersection: <setBasicUpperSection />,

  UilTabBar: {
    tabOrientation: "horizontal",
    tabs: [
      {
        tabId: "1",
        tabName: "Get Started",
        tabTitle: "Start using our design system",
        Description: (
          <span>
            Learn more and access the assets provided to help designers design
            applications with the APP CoE Design System.
          </span>
        ),
      },
      {
        tabId: "2",
        tabName: "Figma Asset Library",
        tabTitle: "Connect our asset library",
        Description: (
          <span>
            A collection of UI elements, components, fonts, styles and other
            visual elements to take your designs to the next level.
            <br></br> <br></br>
          </span>
        ),
      },
      {
        tabId: "3",
        tabName: "Design Tokens",
        tabTitle: "Using our design tokens",
        Description: (
          <span>
            A shared language between design and development to better
            communicate detailed information about how to build and customise
            the user interfaces.
          </span>
        ),
      }
      
    ],
  },

  UilContentBlock: {
    title1: "What is the APP CoE Design System?",
    description: (
      <span>
        The APP CoE Design System is a set of components backed up by code. This
        allows designers and developers to use a single, common language/system
        for creating APP platform applications. Delivering user experiences that
        are applealing, consistent, purposeful and actionable.
        <br /> <br />
        This design and front-end development accelerator contain reusable,
        graphical user interface (GUI) controls used as building blocks to
        create a cohesive, responsive UI. It also offers well-defined,
        centralized, and adoptable design standards, foundations, guidelines for
        facilitating engaging user experiences.
        <br />
        <br />
        Our Design System is an evolving ecosystem based on universal principles
        and guiding patterns shared across product design and development.{" "}
      </span>
    ),
    title2: "Guiding principles",
  },
  UilAccordion: [
    {
      accordionId: "1",
      accordionTitle: "User-relevant, User-focused",
      accordionContent:
        "Applications have been created to solve people’s needs and business problems. We refuse to pile up features. We don’t do design by committee. Every type of user comes with their own set of goals and beliefs, and we intentionally address and answer them. Thus, every new feature should be informed by user research with real human beings to understand the feature’s utility.",
    },
    {
      accordionId: "2",
      accordionTitle: "Simple and coherent",
      accordionContent: "No matter where users are in their workflow. Every user interface should offer a cohesive and coherent experience relevant to the task at hand.Language, information architecture, patterns, and components must work together seamlessly to intuitively allow people to accomplish their tasks.",
    },
    {
      accordionId: "3",
      accordionTitle: "Effective over ornamental",
      accordionContent: "While our solutions should look great and express thoughtful and elegant execution, pretty design for the sake of visual effect is not the end goal.Every single visual design and UI development decision should serve a specific purpose that goes beyond aesthetically delighting the user.Everything rendered on the screen should be there for only one reason. It is to produce valuable and actionable outcomes – not features – for users.",
    },
    {
      accordionId: "4",
      accordionTitle: "Address complexity",
      accordionContent: "Simplicity is hard to grasp. Especially in the complex domains of analytics, AI, and IoT in which we operate.Rather than merely hiding complexity, we tame it through, careful, considered, and user-centered design, which flows consistently across all aspects of a user interface.Confusion is often the outcome of bad design. Teaching people how to master tools is the outcome of good design. We strive for the latter.",
    },
    {
      accordionId: "5",
      accordionTitle: "Actionable insight over crushing amounts of data",
      accordionContent: "Data tells stories and offers a very specific vision of the world. Rather than just pouring data into interfaces because we have access to it, we prefer to tell a story people can easily understand and act on for positive business impact.",
    },
  ],
  UilAssetLib: {
    title: "Figma asset library",
    desc: (
      <span>
        Learn how to enable our APP CoE Design System Figma asset library in
        your Figma projects.
        <br />
        <br />
        Note, you will first have to become a member of our APP Figma design
        team and pillar within Figma to be able to access this asset library.
      </span>
    ),
  },
  UilDesignToken: {
    title: "What are design tokens?",
    desc: (
      <span>
        Design tokens are entities that store raw, indivisible design values
        like colors or sizes. They are the core pieces of a design system that
        can be transformed for use on any platform, replacing hard-coded values.
        <br /> <br />
        Design tokens acts as a single source of truth for all visual styles
        like colors, fonts, spacing etc. that are being used across all the
        components.{" "}
      </span>
    ),
  },
  UilTokens: {
    title: "Token tiers",
  },
  UilTokenCard: [
    {
      cardId: "1",
      cardTitle: "Core Token",
      cardDesc:
        "Core Tokens are the first layer of the design tokens that store the raw values of colors, typography, spacing, effects, etc that build the basis of the design system.",
      customClasses: "uil-token-card-1",
      icon1: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),

      tag1: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text={
              <div className="uil-tag1">
                <div
                  className="filledRectangle"
                  style={{ backgroundColor: "#E6192D" }}
                >
                  <span className="hexCode">#E6192D</span>
                </div>
              </div>
            }
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      icon1: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),

      tag2: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text="Red"
            closeIcon={false}
          ></UilTag>
        </span>
      ),
    },
    {
      cardId: "2",
      cardTitle: "Semantic Token",
      cardDesc:
        "Semantic tokens communicate the contexts, usages or purposes when using them. They are inherited from core tokens.",
      customClasses: "uil-token-card-2",

      tag1: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text={
              <div className="uil-tag1">
                <div
                  className="filledRectangle"
                  style={{ backgroundColor: "#E6192D" }}
                >
                  <span className="hexCode">#E6192D</span>
                </div>
              </div>
            }
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      icon1: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),
      tag2: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text="Red"
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      icon3: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),
      tag3: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text="color-error"
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      
    },
    {
      cardId: "3",
      cardTitle: "Component Token",
      cardDesc:
        "Component specific tokens are the values of elements that comprise a component. Component specific tokens are inherited from Semantic tokens whenever possible, but not a must.",
      tag1: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text={
              <div className="uil-tag1">
                <div
                  className="filledRectangle"
                  style={{ backgroundColor: "#E6192D" }}
                >
                  <span className="hexCode">#E6192D</span>
                </div>
              </div>
            }
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      icon1: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),
      tag2: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text="Red"
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      icon3: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),
      tag3: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text="color-error"
            closeIcon={false}
          ></UilTag>
        </span>
      ),
      icon4: (
        <div>
          <span className="uil-dl-icon-Arrow-down"> </span>
        </div>
      ),
      tag4: (
        <span>
          <UilTag
            customClasses="uil-tag-round-styles"
            shape="round"
            type="none"
            size="medium"
            text="color-textarea-border-errorerror"
            closeIcon={false}
          ></UilTag>
        </span>
      ),
    },
  ],

  UilAccordionHeading: {
    title: "Why should we use tokens?",
  },
  UilAccordionSection: [
    {
      accordionId: "1",
      accordion_title: "Consistency",
      accordion_desc:
        "Design tokens help make the design system become the single source of truth across all products or all platforms. Moreover, design tokens make maintenance and update easier.",
    },
    {
      accordionId: "2",
      accordion_title: "Scalability",
      accordion_desc: "Sample data",
    },
    {
      accordionId: "3",
      accordion_title: "Flexibility",
      accordion_desc: "Sample data",
    },
  ],
  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};
export default componentData;
