import backgroundImage from "../../../assets/gradients/ACC_Gradient_Dark_6.jpg";
import bannerstyles from "../../../app-components/UilBanner/uil_banner.module.css";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import acn_logo from "../../../assets/img/AccentureLogo_black.png";
import Design_Ideology from "../../../assets/img/Design_Ideology.png";
import Components from "../../../assets/img/Components.png";
import Styles from "../../../assets/img/Styles.png";
import Tokens from "../../../assets/img/Tokens.png";
import { Link } from "react-router-dom";
import "../content/home.css";

const componentData = {
  UilBanner_1: {
    type: "hero",
    size: "medium",
    title: "APP CoE Design System",
    content: (
      <span>
        A shared language for designers and developers to accelerate creation of{" "}
        <br />
        applications and deliver user experiences that are delightful,
        consistent, simple,
        <br /> purposeful and actionable.
        <br />
        <br />
        Standardized Design Language and components built on industry latest{" "}
        <br />
        framework. Accessibility compliant and is extensible per custom need.
      </span>
    ),

    backgroundImage: { backgroundImage },
    customClasses: "uil-custom-banner",
    showTitle: true,
    showContent: true,
    showSubHeading: false,
    leftSpacing: "small",
    showFooter: (
      <span className={bannerstyles["uil-banner-btn-border"]}>
        <Link to="/components">
          <UilButton
            type="primary"
            label={<span>Get Started</span>}
            sizeType="large"
            fitcontent={true}
            customClasses="uil-primary-btn-custom"
          ></UilButton>
        </Link>
      </span>
    ),
  },

  UilCard: [
    {
      cardId: "1",
      cardIcon: "uil-dl-pen-icon",
      cardTitle: "Designers",
      description:
        "Here you will find all the most relevant information for designers to better understand and design with Design Language.",
      link: "/designers",
    },
    {
      cardId: "2",
      cardIcon: "uil-dl-code-icon",
      cardTitle: "Developers",
      description:
        "Here you will find all the most relevant information for developers to better understand and develop with Design Language.",
      link: "/developers",
    },
  ],
  uppersection: <setBasicUpperSection />,
  UilImgCard: [
    {
      cardTitle: "Design Ideology",
      link: "/designers",
      image: { Design_Ideology },
      description:
        "Find out more about our guiding design ideology and why this design language will serve you best.",
    },
    {
      cardTitle: "Components",
      image: { Components },
      link: "/components",
      description:
        "Start using our ready made components to rapidly build your new tools & application.",
    },
    {
      cardTitle: "Styles",
      image: { Styles },
      link: "/styles",
      description:
        "Start building out wireframes with our provided Figma libraries.",
    },
    {
      cardTitle: "Tokens",
      image: { Tokens },
      link: "/designers/3",
      description:
        "Learn more about tokens to make your product scalable, flexible & consistent.",
    },
  ],
  UilTag: [
    {
      tagName: "Design tokens",
      link: "/designers/3",
    },
    {
      tagName: "Code tokens",
      link: "/developers/2",
    },
    {
      tagName: "Components",
      link: "/components",
    },
    {
      tagName: "Code repositories",
      link: "/components",
    },
    {
      tagName: "Figma asset library",
      link: "/designers/2",
    },
    {
      tagName: "Accessibility",
      link: "",
    },
    {
      tagName: "Color",
      link: "/styles/1",
    },
    {
      tagName: "Typography",
      link: "/styles/2",
    },
    {
      tagName: "Guiding principles",
      link: "/designers/3",
    },
  ],
  UilTabBar: {
    tabOrientation: "horizontal",
    tabs: [
      {
        tabId: "1",
        tabName: "Colors",
        Description: (
          <span>
            The UI controls are designed so that with the provided variations
            and combinations, APP CoE Design System is aligned with WCAG 2.1
            color contrast guidelines.
            <br></br> <br></br>
            APP Design Language follows the Accenture branded color palette. For
            more information about the company branding space.
          </span>
        ),
      },
      {
        tabId: "2",
        tabName: "Typography",
        Description: (
          <span>
            When using the APP CoE Desing System UI controls from the library,
            each UI control has a specific font type defined to it by default.
            <br></br> <br></br>
            Design accessibility is taken care of with the font styles. When
            selecting the fonts and color combinations, we follow the minimum
            color contrast ratio for normal and large test on a background, as
            described in the WCAG 2.0 Level AA.
            <br></br> <br></br>
            APP CoE Design System is using Graphik for the UI controls as
            defined by the Accenture branding. For more information about the
            company´s branding space.
          </span>
        ),
      },
      {
        tabId: "3",
        tabName: "Icons",
        Description: (
          <span>
            Access over 300 icons to help create better visual links between
            users and applications.
            <br></br> <br></br>
            The APP CoE Design System is using icons as a font package. It is an
            easy and flexible way to define a color and icon size in the code.
            In code, you start by defining the font class following with the
            font size, the name of an icon and optional text. All icons in the
            Design Language begin with prefix “uil-dl-icon”.
          </span>
        ),
      },
    ],
  },
  UilBanner_2: {
    title: "Choose your path",
    subHeader: (
      <span>
        Provided here is a Designer or Developer specific path,each has been
        tailored to better aid your individual roles and usage of
        <br /> Design Language
      </span>
    ),
  },
  UilBanner_3: {
    title: "Just want to explore",
    subHeader:
      "Feel free to explore and get to know Design Language better. Here we have provided a few point of interest to get you started.",
  },
  UilBanner_4: {
    title: " Still not sure where to start?",
    subHeader:
      "If you are still unsure where to start with, we suggest another set of topics which could be of interest.",
    label: "We suggest:",
  },
  UilBanner_5: {
    title: " Connect and collaborate",
    subHeader: (
      <span>
        If you are interested in collaborating or contributing to the growth of
        the Design
        <br /> Language, connect with us to get started.
      </span>
    ),
    showButton: (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = "mailto:DesignLanguage-Support@accenture.com??subject=APP CoE Design System: Feedback/Support Request";
          e.preventDefault();
        }}
      >
        <span>
          <UilButton
            type="primary"
            label={<span className="uil-connect-btn">Connect with us</span>}
            sizeType="large"
            fitcontent={true}
            customClasses="uil-primary-btn-custom"
          ></UilButton>
        </span>
      </Link>
    ),
  },

  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};
export default componentData;
