import React, { useState } from "react";
import ComponentData from "../static/ComponentData";
const StyleContext = React.createContext({});

export const StyleContextProvider = (props) => {
  const [state, setState] = useState({
   selectedTab : "Colors",
   selectedTabIndex : "1",
   selelectedTabDesc : ComponentData.UilTabBar.tabs[0].Description,
   uilFooterLeftArea: ComponentData.UilFooter.footerLeftArea,
   uilFooterLinks : ComponentData.UilFooter.footerLinks,
   uilFooterLogos : ComponentData.UilFooter.footerLogos,
   uilFooterMiddleArea : ComponentData.UilFooter.footerMiddleArea,
   uilFooterRightArea : ComponentData.UilFooter.footerRightArea,
  });


  //Setting the Banner Title.
  const setBannerTitle = (selectedTabTitle) =>{
    state.selectedTab = selectedTabTitle;
    setState({
      ...state,
      selectedTab : state.selectedTab
    });
  }

    //Setting the Banner Description.
    const setBannerDescription = (selectedTabDesc) =>{
      state.selelectedTabDesc = selectedTabDesc;
      setState({
        ...state,
        selelectedTabDesc : state.selelectedTabDesc
      });
    }

    //Setting the Banner Index.
    const setBannerIndex = (selectedTabIndex) =>{
      state.selectedTabIndex = selectedTabIndex;
      setState({
        ...state,
        selectedTabIndex : state.selectedTabIndex
      });
    }

  const styleContext = {
    state,
    setState,
    setBannerTitle,
    setBannerDescription,
    setBannerIndex
  };
  return (
    <StyleContext.Provider value={styleContext}>
      {props.children}
    </StyleContext.Provider>
  );
};
export const StyleContextConsumer = StyleContext.Consumer;
export default StyleContext;
