import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_tabbar.module.css";

const UilTabBar = ({
  type,
  id,
  tabs,
  preselectTab,
  textOnly,
  defaultwithoutBorder,
  isMultiSelect,
  children,
  tabOrientation,
  onChildClick,
  onTabClick
}) => {

  const typeClass = classNames({
    [styles["uil-tabbar-default"]]: type === "default",
    [styles["uil-tabbar-mandatory"]]: type === "mandatory",
    [styles["uil-tabbar-default-without-border"]]: type === "defaultwithoutBorder",
    [styles["uil-tabbar-textonly"]]: type === "textOnly"
  });

  const [selectedTabs, setSelectedTabs] = useState([]);
  const [childrenTabActive, setChildrenTabActive] = useState(false);

  useEffect(() => {
    if (preselectTab && preselectTab.length > 0) {
      if (isMultiSelect) {
        setSelectedTabs(preselectTab);
      } else {
        setSelectedTabs([preselectTab[0]]);
      }
    } else {
      setSelectedTabs([]);
    }
  }, [preselectTab]);

  const handleTabClick = (tabId) => {
    let arr = []
    if (!tabId.disabled) {
      if (isMultiSelect) {
        if (selectedTabs.includes(tabId)) {
          setSelectedTabs(selectedTabs.filter((id) => id !== tabId));
          arr.push(...selectedTabs.filter((id) => id !== tabId));
        } else {

          setSelectedTabs([...selectedTabs, tabId]);
          arr.push(...[...selectedTabs, tabId]);
        }
      } else {
        if (selectedTabs.includes(tabId)) {
          setSelectedTabs([]);
          arr.length = 0;
        } else {
          setSelectedTabs([tabId]);
          arr.push(tabId);
        }
      }
      // setChildrenTabActive(false);
      onTabClick(arr, tabId);
    }
  };

  const handleClick = () => {
    setChildrenTabActive(!childrenTabActive);
    onChildClick();
  };

  return (
    <React.Fragment>
      <div className="tab-bar">
      <div className={tabOrientation === 'horizontal' ? `${styles['divTabBarHorizontal']}` : `${styles['divTabBarVertical']}`}>
        {tabs != undefined &&
          tabs.map((tab, index) => {
            return (
              <>
                <button id={id}
                  key={tab.tabId}
                  className={`${selectedTabs.includes(tab.tabId) ? typeClass + ' ' + `${styles['selected']}` : typeClass}${tab.selected ? " " + `${styles['selected']}` : ''}`}
                  {...(tab && {
                    onClick: () => handleTabClick(tab.tabId)
                  })}
                >
                  <span>
                    {tab.tabName}
                    {tab && type == "mandatory" && (
                      <span
                        className={`${styles['uil-tabbar-mandatorymark']}`}
                      >
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                  {textOnly && (
                    <span className={`${styles['uil-tabbar-textonly']}`}>
                      {" "}
                    </span>
                  )}
                  {defaultwithoutBorder && (
                    <span className={`${styles['uil-tabbar-default-without-border']}`}>
                      {" "}
                    </span>
                  )}
                </button>
              </>
            );
          }
          )}
      </div>
      </div>
    </React.Fragment>
  );
};

UilTabBar.defaultProps = {
  type: "default",
  tabs: undefined,
};

UilTabBar.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  preselectTab: PropTypes.array,
  children: PropTypes.node,
  onChildClick: PropTypes.func,
  tabs: PropTypes.array,
  tabOrientation: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default UilTabBar;
