import React, { Component } from "react";
import ContentBlock from "../../../app-components/ContentBlock";
import DesignerContext, {
  DesignerContextProvider,
} from "../context/DesignersContext";
import ComponentData from "../static/ComponentData";
import UilCard from "../../../app-components/UilCard/UilCard";
import UilAccordion from "../../../app-components/UilAccordion/UilAccordion";
import Settings from "../static/settings";
import UilTag from "../../../app-components/UilTag/UilTag"
import "./design_token_page.css";
let contextType = null;

export default class DesignToken extends Component {
  componentDidMount() {}

  render() {
    return (
      <DesignerContextProvider>
        <DesignerContext.Consumer>
          {(designerContext) => {
            contextType = designerContext;
            return (
              <React.Fragment>
                <ContentBlock>
                  <div className="uil-design-content">
                    <div className="uil-design-header">
                      {ComponentData.UilDesignToken.title}
                      <div className="uil-design-desc">
                        {ComponentData.UilDesignToken.desc}
                      </div>
                    </div>
                    <div className="uil-token-tiers">
                      {ComponentData.UilTokens.title}
                    </div>
                    <div className="uil-token-cards">
                      {ComponentData.UilTokenCard.map((Content) => {
                        return (
                          <UilCard
                            isSelected={false}
                            uppersection={
                              <div className={`${Content.customClasses}`}>
                                <div className="uil-token-card-header">
                                  {Content.cardTitle}
                                </div>

                                <p className="uil-token-card-caption">
                                  {Content.cardDesc}
                                </p>
                                <div className="uil-token-tag">
                                  <div className="uil-tag-round-styles">{Content.tag1}</div>
                                  <div className="uil-tag-icon">{Content.icon1}</div>
                                  {Content.tag2}
                                  <div className="uil-tag-icon">{Content.icon1 && Content.icon3}</div>
                                  {Content.tag3}
                                  <div className="uil-tag-icon">{Content.icon1 && Content.icon3 && Content.icon4}</div>
                                  {Content.tag4}
                                </div>
                              </div>
                            }
                          ></UilCard>
                        );
                      })}
                    </div>

                    <div className="uil-accordion-section">
                      <div className="uil-section-title">
                        {ComponentData.UilAccordionHeading.title}
                        <div className="uil-accordion-style">
                          {ComponentData.UilAccordionSection.map((Content) => {
                            return (
                              <UilAccordion
                                type="regular"
                                disabled={false}
                                id="card_example"
                                title={Content.accordion_title}
                                isIconOption={true}
                                children={Content.accordion_desc}
                              ></UilAccordion>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </ContentBlock>
              </React.Fragment>
            );
          }}
        </DesignerContext.Consumer>
      </DesignerContextProvider>
    );
  }
}
