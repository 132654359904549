import React, { Component } from "react";
import UilBanner from "../../../app-components/UilBanner/UilBanner";
import backgroundImage from "../../../assets/gradients/Hero_Banner_styles.svg";
import UilCard from "../../../app-components/UilCard/UilCard";
import ContentBlock from "../../../app-components/ContentBlock";
import UilTag from "../../../app-components/UilTag/UilTag";
import Design_Ideology from "../../../assets/img/Design_Ideology.png";
import Components from "../../../assets/img/Components.png";
import Styles from "../../../assets/img/Styles.png";
import Tokens from "../../../assets/img/Tokens.png";
import ComponentData from "../static/ComponentData";
import HomeContext, { HomeContextProvider } from "../context/HomeContext";
import { Link } from "react-router-dom";
import "./home.css";
import GoToTop from "app/goToTop";

let contextType = null;
const imageArr = [Design_Ideology, Components, Styles, Tokens];

export default class HomePage extends Component {
  componentDidMount() {}

  render() {
    return (
      <HomeContextProvider>
        <HomeContext.Consumer>
          {(homeContext) => {
            contextType = homeContext;
            return (
              <React.Fragment>
                <ContentBlock>
                  <UilBanner
                    type="hero"
                    size="medium"
                    title={ComponentData.UilBanner_1.title}
                    content={ComponentData.UilBanner_1.content}
                    backgroundImage={backgroundImage}
                    customClasses={ComponentData.UilBanner_1.customClasses}
                    showTitle={ComponentData.UilBanner_1.showTitle}
                    showContent={ComponentData.UilBanner_1.showContent}
                    showSubHeading={ComponentData.UilBanner_1.showSubHeading}
                    leftSpacing={ComponentData.UilBanner_1.leftSpacing}
                    showFooter={ComponentData.UilBanner_1.showFooter}
                  ></UilBanner>
                </ContentBlock>
                <ContentBlock>
                  <div class="body-class">
                    <div className="body-header">
                      {ComponentData.UilBanner_2.title}
                    </div>
                    <div className="body-sub-header">
                      {ComponentData.UilBanner_2.subHeader}
                    </div>

                    <div className="uil-card-section-home">
                      {ComponentData.UilCard.map((Content) => {
                        return (
                          <Link to={Content.link}>
                          <UilCard
                            isSelected={false}
                            uppersection={
                              <div className="uil-basic-cards">
                                <h4
                                  className={`uil-card-titles ${Content.cardIcon}`}
                                >
                                  {Content.cardTitle}
                                </h4>
                                <p className="uil-card-caption">
                                  {Content.description}
                                </p>
                                
                                  <span class="uil-dl-icon-Arrow-rightUp"></span>{" "}
                                
                              </div>
                            }
                          ></UilCard></Link>  
                        );
                      })}
                    </div>
                  </div>
                </ContentBlock>
                <ContentBlock section>
                  <span className="image-card-section-header">
                    {ComponentData.UilBanner_3.title}
                  </span>
                  <span className="image-card-section-sub-header">
                    {ComponentData.UilBanner_3.subHeader}
                  </span>

                  <div class="uil-img-section">
                    {ComponentData.UilImgCard.map((Content, index) => {
                      return (
                        <div className="uil-image-styles">
                          <Link to={Content.link}>  
                            <UilCard
                              uppersection={
                                <div
                                  className="uil-image-cards"
                                  style={{
                                    backgroundImage: `url(${imageArr[index]})`,
                                  }}
                                >
                                  <div className="uil-image-card-desc">
                                    <h4 className="uil-image-card-header">
                                      {Content.cardTitle}
                                    </h4>
                                  </div>
                                </div>
                              }
                              bodysection={
                                <div className="uil-image-card-desc">
                                  <p className="uil-image-card-captions">
                                    {Content.description}
                                  </p>
                                </div>
                              }
                            ></UilCard>
                          </Link>
                        </div>
                      );
                    })}
                  </div>

                  {/*  */}
                </ContentBlock>
                <ContentBlock section>
                  <div className="uil-tag-section">
                    <span className="uil-tag-section-header">
                      {ComponentData.UilBanner_4.title}
                    </span>
                    <p className="uil-tag-section-subheader">
                      {ComponentData.UilBanner_4.subHeader}
                    </p>

                    <div className="uil-tag-header">
                      {ComponentData.UilBanner_4.label}
                    </div>
                    <div className="uil-tag-styles">
                      {ComponentData.UilTag.map((Content) => {
                        return (
                          <Link to={Content.link}>
                            <UilTag
                              customClasses=""
                              shape="square"
                              type="none"
                              size="medium"
                              text={Content.tagName}
                              closeIcon={false}
                            ></UilTag>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </ContentBlock>
                <ContentBlock>
                  <div className="uil-connect-section">
                    <span className="uil-connect-header">
                      {ComponentData.UilBanner_5.title}
                    </span>
                    <p className="uil-connect-subheader">
                      {ComponentData.UilBanner_5.subHeader}
                    </p>
                    <p className="uil-banner5-button">
                      {ComponentData.UilBanner_5.showButton}
                    </p>
                  </div>
                 
                </ContentBlock>
                <GoToTop></GoToTop>
              </React.Fragment>
            );
          }}
        </HomeContext.Consumer>
      </HomeContextProvider>
    );
  }
}
