import React, { useState } from "react";
import ComponentData from "../static/ComponentData";
const HomeContext = React.createContext({});

export const HomeContextProvider = (props) => {
  const [state, setState] = useState({
    tabTitle: "Start using our design system",
    selectedTabIndex: "1",
    selelectedTabDesc: ComponentData.UilTabBar.tabs[0].Description,
    uilFooterLeftArea: ComponentData.UilFooter.footerLeftArea,
    uilFooterLinks: ComponentData.UilFooter.footerLinks,
    uilFooterLogos: ComponentData.UilFooter.footerLogos,
    uilFooterMiddleArea: ComponentData.UilFooter.footerMiddleArea,
    uilFooterRightArea: ComponentData.UilFooter.footerRightArea,
  });

  //Setting the Banner Title.
  const setBannerTitle = (selectedTabTitle) => {
    state.tabTitle = selectedTabTitle;
    setState({
      ...state,
      tabTitle: state.tabTitle,
    });
  };

  //Setting the Banner Description.
  const setBannerDescription = (selectedTabDesc) => {
    state.selelectedTabDesc = selectedTabDesc;
    setState({
      ...state,
      selelectedTabDesc: state.selelectedTabDesc,
    });
  };

  //Setting the Banner Index.
  const setBannerIndex = (selectedTabIndex) => {
    state.selectedTabIndex = selectedTabIndex;
    setState({
      ...state,
      selectedTabIndex: state.selectedTabIndex,
    });
  };

  const homeContext = {
    state,
    setState,
    setBannerTitle,
    setBannerDescription,
    setBannerIndex,
  };
  return (
    <HomeContext.Provider value={homeContext}>
      {props.children}
    </HomeContext.Provider>
  );
};
export const HomeContextConsumer = HomeContext.Consumer;
export default HomeContext;
