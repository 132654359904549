import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_checkbox_radio.module.css"

const UilRadioButton = (props) => {
  const {
    autofocus,
    checked,
    id,
    name,
    value,
    disabled,
    titleText,
     allowClickPropagation,
    customClasses
  } = props;

  const labelClickHandler = (event) => {
    if (!allowClickPropagation) {
      event.stopPropagation();
    }
  };

  const inputClickHandler = (event) => {
    if (!allowClickPropagation) {
      event.stopPropagation();
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <React.Fragment>
      <div className={styles["uil-radio-container"]}>
        {titleText && <label className={styles["uil-form-item-title-radiotext"]} disabled={disabled}>{titleText} </label>}
        <div className={`${styles["uil-radio"]} ${disabled ? styles["disabled"]: ""}`}>
          <input
            autoFocus={autofocus}
            disabled={disabled}
            type="radio"
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={props.onChange}
            onClick={inputClickHandler}
          ></input>
          <label htmlFor={id} onClick={labelClickHandler} disabled={disabled}></label>
        </div>      
      </div>
    </React.Fragment>
  );
};
UilRadioButton.defaultProps = {
  disabled: false,
  checked: false,
};

UilRadioButton.propTypes = {
  autofocus: PropTypes.bool,
  value: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  allowClickPropagation: PropTypes.bool,
  customClasses: PropTypes.string,
};

UilRadioButton.propDescriptions = {
  autofocus:
    "Sets or returns whether a radio button should automatically get focus when the page loads",
  value: "Sets or returns the value of the value attribute of the radio button",
  checked: "Sets or returns the checked state of a radio button",
  id: "Unique identifier for radio button",
  name: "Sets or returns the value of the name attribute of a radio button",
  disabled: "Sets or returns whether the radio button is disabled, or not",
  onChange: "Function to handle change of radio button.",
  customClasses: "class for customizing the Radio button"
};

export default UilRadioButton;