// exports.creds = {
// 	tenant: "ebc73e2f-016b-470f-863a-98a2258835ce",
// 	clientID: "4d85f68c-b1e4-4294-bedf-72f560812ba1",
// 	redirectUri: "http://localhost:3000/auth/callback"
// };

//APP COE - app-design-language
// exports.creds = {
// 	tenant: "ebc73e2f-016b-470f-863a-98a2258835ce",
// 	clientID: "799cd7b1-1195-4df0-a77a-182788168fd6",
// 	redirectUri: "http://localhost:3000/auth/callback"
// };


//Accenture ESO - app-design-language
exports.creds = {
	tenant: "e0793d39-0939-496d-b129-198edd916feb",
	clientID: "71c2d8e5-b800-4e63-ad9b-3622e3d1881a",
	redirectUri: "https://designlanguage.accenture.com/auth/callback"
};