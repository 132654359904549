import acn_logo from "../../../assets/img/AccentureLogo_black.png";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
const Settings = {
  UilHeader: {
    id: "Header-Example",
    headerSize: "baseline",
    tabAlignment: "right",
    title: "App Design Language",
    headerPadding: "small",
    UilHeaderTools: [
      {
        icon: (
          <div className="uil-font-icon" style={{ paddingRight: "24px" }}>
            <span
              className="uil-dl-icon-Bell"
              style={{ cursor: "pointer", fontSize: "24px" }}
            ></span>
          </div>
        ),
      },

      {
        icon: (
          <div className="uil-font-icon" style={{ paddingRight: "24px" }}>
            <span
              className="uil-dl-icon-User"
              style={{ cursor: "pointer", fontSize: "24px" }}
            ></span>
          </div>
        ),
      },
    ],
    UilHeaderLogo: (
      <div className="uil-font-icon" style={{ marginRight: "8px" }}>
        <span>
          <a href="https://accenture.com">
            <img src={acn_logo} alt="Accenture" width="101"></img>
          </a>
        </span>
      </div>
    ),
  },
  UilSideNavBar: {
    UilSideNavTopOptions: [
      { id: "id1", icon: "uil-dl-icon-Home-alt", displayValue: "Home" },
      { id: "id2", icon: "uil-dl-icon-Eye", displayValue: "Designers" },
      { id: "id3", icon: "uil-dl-icon-Coding", displayValue: "Developers" },
      {
        id: "id4",
        icon: "uil-dl-icon-Component",
        displayValue: "Components",
      },
      { id: "id5", icon: "uil-dl-icon-Colors", displayValue: "Styles" },
    ],
    UilSideNavBottomOptions: [
      {
        id: "id6",
        icon: "uil-dl-icon-Help",
        displayValue: "FAQ",
      },
      {
        id: "id7",
        icon: "uil-dl-icon-Contact-book",
        displayValue: "Contact Us",
      },
    ],
  },
  ColorContent: {
    Titles: {
      userInterfaceTitle: "User Interface Colors",
      greyScale: "Grey Scale",
      primaryColors: "Primary Colors",
      secondaryColors: "Secondary Colors",
      statusColors: "Status Colors",
    },
    Descriptions: {
      userInterfaceDesc:
        " The range of white, light and dark greys builds the UI backgrounds, containers, pop-ups, drop-downs and more. These colors are essential to deliver a clean and crisp look as well as feel to the products and applications built by using APP Design Language UI controls.",
      greyScaleDesc:
        "Grey color range is the most common colors used to build background, pop-up, dropdowns, texts and more. These colors are essential for the cleaner look and feel of the products.",
      primaryDesc:
        "We have selected the Accenture Core brand colors as our baseline to highlight details in the user interface.",
      statusDesc:
        "Statuses colors are important component which instantly illustrate the audience about the state of the items.",
    },
  },
  TypographyContent: {
    Titles: {
      fontFamilyTitle: "Font family",
      tokenApplicationTitle: "Token application",
    },
    Descriptions: {
      fontFamilyDesc:<span>Graphik is a sophisticated typeface that is perfectly suited for whatever style of expression is needed. Its 
        <br/>purposeful, elegant plainness allows it to move effortlessly between being a central design element or 
        <br/>playing a supporting role in a wide range of projects and applications.</span>,
      tokenApplicationDesc: (
        <span>
          Text styles are combined with color token to meet the various
          requirement. E.g. text style
          <br /> token “font-caption-sm-regular” combined with font color token
          “color-font-error” will give <br />
          the error font with red color.
        </span>
      ),
      TypographyDesc: (
        <span>
          Design accessibility is taken care of with the font styles. When
          selecting the fonts and color
          <br /> combinations, we follow the minimum color contrast ratio for
          normal and large text on a background, as
          <br /> described in the WCAG 2.0 Level AA.{" "}
        </span>
      ),
    },
  },
  IconContent: {
    Descriptions: {
      IconDesc: (
        <span>
          The APP CoE Design System is using icons as a font package. It is an
          easy and flexible way to define a color and icon size in the
          <br />
          code. In code, you start by defining the font class following with the
          font size, the name of an icon and optional text.
          <br />
          All icons in the Design Language begin with prefix “uil-dl-icon”.
        </span>
      ),
    },
  },
  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            //onClick={() => openFeedback()}
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};

export default Settings;
