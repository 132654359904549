import acn_logo from "../../../assets/img/AccentureLogo_black.png";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton"

const componentData = {
  UilFooter: {
    footerLeftArea: (
      <span >
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea : [
        { name: "About", link: "#" },
        { name: "Contact Us", link: "#" },
        { name: "FAQ", link: "#" },
      ],
      footerRightArea : 
      <span style={{ display: "contents" }}>
      <span className={styles["uil-footer-feedback"]}>
        Feedback
      </span>
      <span className={styles["uil-footer-text"]}>
        Help us make the App COE Design System experience
        better. If you have a comment, idea or even a
        question, let us know.
      </span>
      <span>
        <UilButton
          type="secondary"
          label="Give Feedback"
          sizeType="large"
          //onClick={() => openFeedback()}
          customClasses={styles["uil-footer-button"]}
          fitcontent={true}
        ></UilButton>
      </span>
    </span>
  },
};
export default componentData;
