import React, { Component } from "react";
import Settings from "../static/settings";
import GetStartedContext, {
  GetStartedContextProvider,
} from "../context/GetStartedContext";
import UilSideNavigationBar from "../../../app-components/UilSideNavigationBar/UilSideNavigationBar";
import classNames from "classnames";
import UilFooter from "../../../app-components/UilFooterNew/UilFooter";
import Designers from "../../designers/content/designers";
import Developers from "../../developers/content/developers";
import Home from "../../home/content/home";
import Styles from "../../styles/content/style";
import Components from "../../components/content/components";
import { Routes, Route, useHistory } from "react-router-dom";
import GoToTop from "app/goToTop";

let contextType = null;

export default class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: props.selected,
    };
  }
  componentDidMount() {
    contextType.setSelectedMenu(this.state.menu);
  }

  onSideNavMenuClick = (selectedMenu) => {
    if (selectedMenu) {
      contextType.setSelectedMenu(selectedMenu.displayValue);
    }
  };

  render() {
    return (
      <GetStartedContextProvider>
        <GetStartedContext.Consumer>
          {(getStartedContext) => {
            contextType = getStartedContext;
            return (
              <React.Fragment>
                <div>
                  <div className="side-navbar">
                    <UilSideNavigationBar
                      id="sideNav"
                      disabled={false}
                      clickHandler={(e) => this.onSideNavMenuClick(e)}
                      mode="light"
                      navOptions={contextType.state.uilSideNavTopOptions}
                      navbottomOptions={
                        contextType.state.uilSideNavBottomOptions
                      }
                    ></UilSideNavigationBar>
                  </div>
                  <div id="div-Content">
                    <Routes>
                      <Route exact path="/" element={<Home></Home>}></Route>

                      <Route
                        exact
                        path="/styles"
                        element={<Styles></Styles>}
                      ></Route>
                      <Route
                        exact
                        path="/components"
                        element={<Components></Components>}
                      ></Route>
                      <Route
                        exact
                        path="/designers"
                        element={<Designers></Designers>}
                      ></Route>
                      <Route
                        exact
                        path="/developers"
                        element={<Developers></Developers>}
                      ></Route>
                      <Route
                        exact
                        path="/designers/:tabid"
                        element={<Designers></Designers>}
                      ></Route>
                      <Route
                        exact
                        path="/developers/:tabid"
                        element={<Developers></Developers>}
                      ></Route>
                      <Route
                        exact
                        path="/styles/:tabid"
                        element={<Styles></Styles>}
                      ></Route>
                    </Routes>

                    <footer className={classNames("uil-footer")}>
                      <UilFooter
                        id="footer"
                        type="detailed"
                        padding="none"
                        footerLeftArea={
                          <div style={{ width: "max-content" }}>
                            {Settings.UilFooter.footerLeftArea}
                          </div>
                        }
                        links={Settings.UilFooter.footerLinks}
                        logos={Settings.UilFooter.footerLogos}
                        footerMiddleArea={Settings.UilFooter.footerMiddleArea}
                        footerRightArea={Settings.UilFooter.footerRightArea}
                      ></UilFooter>
                    </footer>
                    <GoToTop></GoToTop>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </GetStartedContext.Consumer>
      </GetStartedContextProvider>
    );
  }
}
