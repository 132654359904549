import React from "react";
import { BrowserRouter } from "react-router-dom";
import  UilErrorBoundary  from "app-components/UilErrorBoundary/UilErrorBoundary";
import BasePageRenderer from "app/BasePageRenderer";
import { loginRequest } from "./authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser'
import { useEffect, useState } from "react";
import "./App.css";

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const request = {
    ...loginRequest,
    account: accounts[0]
  };

 /* useEffect(() => {
    let userName = accounts[0] && accounts[0].name;
    console.log("User Name In----->", userName);
    sessionStorage.setItem("userName", userName)
    instance.acquireTokenSilent(request).then((response) => {
      console.log("acquireTokenSilent Inside--->", response);
      sessionStorage.setItem('accessToken', response.accessToken)
      setAccessToken(response.accessToken);
    }).catch((e) => {
      console.log("Error while acquireTokenSilent Out....", e);
      instance.acquireTokenPopup(request).then((response) => {
        sessionStorage.setItem('accessToken', response.accessToken)
        setAccessToken(response.accessToken);
      });
    });
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
    window.setInterval(()=>{
      instance.acquireTokenSilent(request).then((response) => {
        console.log("acquireTokenSilent Inside--->", response);
        sessionStorage.setItem('accessToken', response.accessToken)
        setAccessToken(response.accessToken);
      }).catch((e) => {
        console.log("Error while acquireTokenSilent Out....", e);
        instance.acquireTokenPopup(request).then((response) => {
          sessionStorage.setItem('accessToken', response.accessToken)
          setAccessToken(response.accessToken);
        });
      });
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
      }
    },2400000)
   
  }, [inProgress, instance, isAuthenticated, accessToken, accounts, request]);
*/
  return (
    <div>
     <UilErrorBoundary>
        <BrowserRouter>
          <BasePageRenderer></BasePageRenderer>
          <div id="uil-portal"></div>
        </BrowserRouter>
        </UilErrorBoundary>
     
    </div>
  );
}

export default App;
