import React, { Component } from "react";
import StyleContext, { StyleContextProvider } from "../context/StyleContext";
import ContentBlock from "../../../app-components/ContentBlock";
import IconPreviewSection from "../../../app-components/IconRef/IconPreviewSection";
import UilAlert from "../../../app-components/UilAlert/UilAlert";
import ComponentData from "../static/ComponentData";
import "./Style.css";
import Settings from "../static/settings";
let contextType = null;

export default class Icon extends Component {
  componentDidMount() {}
  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };

  render() {
    return (
      <StyleContextProvider>
        <StyleContext.Consumer>
          {(styleContext) => {
            contextType = styleContext;
            return (
              <React.Fragment>
                <div className="styleContentSection">
                <div
                  id="divGreyScale"
                  style={{ minHeight: 1024, minWidth: 1024 }}
                >
                  <ContentBlock>
                    <div className="colorSections">
                    <span className="colorContent">
                      {Settings.IconContent.Descriptions.IconDesc}
                    </span>
                    </div>
                    <div>
                    <UilAlert id='alert-id'
                        alertType='informational'
                        isClose={false}
                        isTitle={true}
                        isKnowMore={false}
                        isLeftIcon={true}
                        subtitle='Outlined icon and background icon with different sizes can be achieved using classes. Check this out in storybook.'
                        title='NOTE:'
                        />
                    </div>
                  </ContentBlock>
                  <ContentBlock >
                  <div style={{marginBottom:100}}>
                    <IconPreviewSection iconSet="DL"></IconPreviewSection>
                    </div>
                  </ContentBlock>
                </div>
                </div>
              </React.Fragment>
            );
          }}
        </StyleContext.Consumer>
      </StyleContextProvider>
    );
  }
}
