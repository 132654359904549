import React, { Component } from "react";
import ContentBlock from "../../../app-components/ContentBlock";
import DesignerContext, {
  DesignerContextProvider,
} from "../context/DesignersContext";
import ComponentData from "../static/ComponentData";
import UilCard from "../../../app-components/UilCard/UilCard";
import UilAccordion from "../../../app-components/UilAccordion/UilAccordion";
import { Link } from "react-router-dom";
import Settings from "../static/settings";
import "./get_started.css";
import withRouter from "../../withRouter";
import GoToTop from "app/goToTop";
let contextType = null;

 class GetStarted extends Component {
  componentDidMount() {}

  render() {
    return (
      <DesignerContextProvider>
        <DesignerContext.Consumer>
          {(designerContext) => {
            contextType = designerContext;
            return (
              <React.Fragment>
                <ContentBlock>
                  <div className="uil-content-block">
                    <div className="uil-desc-section">
                      <div className="uil-content-block-header">
                        {ComponentData.UilContentBlock.title1}
                      </div>
                      <div className="uil-content-block-desc">
                        {ComponentData.UilContentBlock.description}
                      </div>
                      <div className="uil-cards-section">
                        {ComponentData.UilCard.map((Content) => {
                          return (
                            <Link to={Content.redirectTo}>
                            <UilCard
                              isSelected={false}
                              uppersection={
                                <div className="uil-base-card">
                                  <div className="uil-card-header">
                                    {Content.cardHeader}
                                    
                                      <span
                                        className={`uil-arrow-icon ${Content.cardIcon}`}
                                      ></span>
                                    
                                  </div>
                                  <h4 className={`uil-card-title `}>
                                    {Content.cardTitle}
                                  </h4>
                                  <p className="uil-basic-card-caption">
                                    {Content.description}
                                  </p>
                                </div>
                              }
                            ></UilCard></Link>
                          );
                        })}
                      </div>
                    </div>

                    <div className="uil-header-2">
                      {ComponentData.UilContentBlock.title2}
                      <div className="uil-accordion-styles">
                        {ComponentData.UilAccordion.map((Content) => {
                          return (
                            <UilAccordion
                              type="regular"
                              disabled={false}
                              id="card_example"
                              title={Content.accordionTitle}
                              isIconOption={true}
                              children={Content.accordionContent}
                            ></UilAccordion>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </ContentBlock>
                <GoToTop></GoToTop>
              </React.Fragment>
            );
          }}
        </DesignerContext.Consumer>
      </DesignerContextProvider>
    );
  }
}
export default withRouter(GetStarted)
