import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./uil_tooltipnew.css";

const UilTooltip = (props) => {
  const { id, position, tooltipText, children, delay,customClasses } = props;

  let timeout;
  const [active, setActive] = useState(false);
  const [currentPostion, seCurrentPostion] = useState("");

  const ref = useRef(null);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 0);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  useEffect(() => {
    seCurrentPostion(position);
  }, [position]);
  useEffect(() => {
    if (active) {
      const { x, y } = ref.current.getBoundingClientRect();
      if (y < 0 && currentPostion === "top") {
        seCurrentPostion("bottom");
      }
      if (x < 0 && currentPostion === "left") {
        seCurrentPostion("right");
      }
    }
  }, [currentPostion, active]);

  return (
    <div
      id={id}
      className={`Tooltip-Wrapper ${customClasses}`}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <div ref={ref} className={`Tooltip-Tip ${currentPostion || "top"}`}>
          {/* Content */}
          {tooltipText}
        </div>
      )}
    </div>
  );
};

UilTooltip.propTypes = {
  id: PropTypes.string,
  position: PropTypes.string,
  tooltipText: PropTypes.element,
  children: PropTypes.any,
  delay: PropTypes.number,
};

export default UilTooltip;