import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_badge.module.css";

const UilBadge = (props) => {
  const {
    id,
    type,
    size,
    color,
    text,
    digit,
    onClick,
    iconClass,
    customColor,
  } = props;

  const sizeClass = classNames(styles["uil-badge"], {
    [styles["uil-badge-smallDark"]]: size === "smallDark",
    [styles["uil-badge-smallLight"]]: size === "smallLight",
    [styles["uil-badge-mediumDark"]]: size === "mediumDark",
    [styles["uil-badge-mediumLight"]]: size === "mediumLight",
  });
  const typeClass = classNames({
    [styles["uil-badge-status"]]: type === "status",
    [styles["uil-badge-singleDigit"]]: type === "singleDigit",
    [styles["uil-badge-multipleDigit"]]: type === "multipleDigit",
    [styles["uil-badge-text"]]: type === "text",
  });
  const colorClass = classNames({
    [styles["uil-badge-primary"]]: color === "primary",
    [styles["uil-badge-secondary"]]: color === "secondary",
    [styles["uil-badge-blue"]]: color === "blue",
    [styles["uil-badge-green"]]: color === "green",
    [styles["uil-badge-orange"]]: color === "orange",
    [styles["uil-badge-red"]]: color === "red",
    [styles["uil-badge-grey"]]: color === "grey",
  });
 // Function to lighten a background color based on colorCode and value
function lightenBackgroundColor(colorCode, value) {
  var isHashCode = false;
  
  // Check if colorCode starts with "#" and set flag accordingly
  if (colorCode[0] == "#") {
  colorCode = colorCode.slice(1);
  isHashCode = true;
  }
  // Parse colorCode as a base-16 number
  var num = parseInt(colorCode, 16);
  
  // Extract red component and add value
  var r = (num >> 16) + value;
  if (r > 255) r = 255; //  red component doesn't exceed 255
  else if (r < 0) r = 0; //  red component doesn't fall below 0
  
  // Extract green component and add value
  var b = ((num >> 8) & 0x00FF) + value;
  if (b > 255) b = 255; // green component doesn't exceed 255
  else if (b < 0) b = 0; // green component doesn't fall below 0
  
  // Extract blue component and add value
  var g = (num & 0x0000FF) + value;
  if (g > 255) g = 255; // blue component doesn't exceed 255
  else if (g < 0) g = 0; //  blue component doesn't fall below 0
  
  // Convert the modified color components back to a hexadecimal string
  return (isHashCode ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  
  // Function to define badge style based on customColor and size
  const badgeStyle = () => {
  let colorsObject = {};
  // Check if customColor has a length greater than 1
  if (customColor?.length > 1) {
  //  for setting background color
  if (type !== "text" || size === 'smallDark' || size === 'mediumDark') {
  colorsObject.background = customColor;
  return colorsObject;
  }
  // for setting color and lightened background color
  else if (size === 'mediumLight' || size === 'smallLight') {
  colorsObject.color = customColor;
  colorsObject.background = lightenBackgroundColor(customColor, 150);
  return colorsObject;
  }
  }
  // Return an empty colorsObject if customColor has a length less than or equal to 1
  else {
  return colorsObject;
  }
  }
  return (
    <div id={id} onClick={onClick} className={classNames(`${typeClass}`)}>
      <div className={classNames(`${colorClass}`)} style={badgeStyle()}>
        {type === "singleDigit" ? (
          digit
        ) : type === "multipleDigit" ? (
          <span>
            {digit}
            <span className={iconClass}></span>
          </span>
        ) : (
          ""
        )}
        <div className={classNames(`${sizeClass}`)} style={badgeStyle()}>
          {type === "text" ? <span className={iconClass}>{text}</span> : ""}
        </div>
      </div>
    </div>
  );
};

UilBadge.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.element,
  digit: PropTypes.element,
  iconClass: PropTypes.string,
  customColor: PropTypes.string,
};

export default UilBadge;
