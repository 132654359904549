import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import UilBasePage from "../../app-components/UilBasePage/UilBasePage";
import UilHeaderBar from "../../app-components/UilHeaderBar/UilHeaderBar";
import GettingStared from "../get-started/content/getstarted.jsx";
import Settings from "../get-started/static/settings";
import acn_logo from "../../assets/img/AccentureLogo.png";
import GoToTop from "../goToTop.js"
const root = {
  backgroundColor: "#FFF",
};

export default class BasePageRenderer extends UilBasePage {
  render() {
    return (
      <div className={cx("wrapper")}>
        <div className="uil-curtain">
          <div className="uil-curtain-wrapper">
            <div className="uil-curtain-prize">
              <div className="divHeader">
                <UilHeaderBar
                  id={Settings.UilHeader.id}
                  headerSize={Settings.UilHeader.headerSize}
                  tabAlignment={Settings.UilHeader.tabAlignment}
                  title={Settings.UilHeader.title}
                  headerPadding={Settings.UilHeader.headerPadding}
                  tools={[
                    {
                      icon: (
                        <div
                          className="uil-font-icon"
                          style={{ paddingRight: "24px" }}
                        >
                          <span
                            className="uil-dl-icon-Bell"
                            style={{ cursor: "pointer", fontSize: "24px" }}
                          ></span>
                        </div>
                      ),
                    },

                    {
                      icon: (
                        <div
                          className="uil-font-icon"
                          style={{ paddingRight: "24px" }}
                        >
                          <span
                            className="uil-dl-icon-User"
                            style={{ cursor: "pointer", fontSize: "24px" }}
                          ></span>
                        </div>
                      ),
                    },
                  ]}
                  ishamburgerIcon={false}
                  islogo={true}
                  IsWithBottomBorder={false}
                  logo={
                    <div
                      className="uil-font-icon"
                      style={{ marginRight: "8px" }}
                    >
                      <span>
                          <img src={acn_logo} alt="Accenture" width="101" style={{position: "relative", bottom: 5 + "px"}}></img>
                      </span>
                    </div>
                  }
                ></UilHeaderBar>
              </div>
              <div className="content-wrapper" style={{marginBottom:"-16px"}}>
                <div
                  onScroll={this.handleScroll}
                  id="content-wrap"
                  className={cx("content-footer-wrapper uil-dynamic-scrollbar")}
                  ref={this.contentFooterWrapperRef}
                >
                  <div>
                    <div></div>

                    <GettingStared ></GettingStared>
                    <GoToTop></GoToTop>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uil-scrollbar-area">
          <div
            className="uil-scrollbar-thumb"
            onMouseDown={this.dragThumbMouseDown}
            ref={this.customScrollbarThumbRef}
          ></div>
        </div>
      </div>
    );
  }
}
BasePageRenderer.propTypes = {
  title: PropTypes.string,
};
