import React, { useState } from "react";
import ComponentData from "../static/ComponentData";
const DevelopersContext = React.createContext({});

export const DevelopersContextProvider = (props) => {
  const [state, setState] = useState({
   selectedTab : "Get started",
   selectedTabIndex : "1",
   activeCard:{},
   selelectedTabDesc : ComponentData.UilTabBar.tabs[0].Description,
   uilFooterLeftArea: ComponentData.UilFooter.footerLeftArea,
   uilFooterLinks : ComponentData.UilFooter.footerLinks,
   uilFooterLogos : ComponentData.UilFooter.footerLogos,
   uilFooterMiddleArea : ComponentData.UilFooter.footerMiddleArea,
   uilFooterRightArea : ComponentData.UilFooter.footerRightArea,
  });


  //Setting the Banner Title.
  const setBannerTitle = (selectedTabTitle) =>{
    state.selectedTab = selectedTabTitle;
    setState({
      ...state,
      selectedTab : state.selectedTab
    });
  }

    //Setting the Banner Description.
    const setBannerDescription = (selectedTabDesc) =>{
      state.selelectedTabDesc = selectedTabDesc;
      setState({
        ...state,
        selelectedTabDesc : state.selelectedTabDesc
      });
    }

    //Setting the Banner Index.
    const setBannerIndex = (selectedTabIndex) =>{
      state.selectedTabIndex = selectedTabIndex;
      setState({
        ...state,
        selectedTabIndex : state.selectedTabIndex
      });
    }

    const setActiveCard = (card) =>{
      state.activeCard = card;
      setState({
        ...state,
        card : state.setActiveCard
      });
    }
  const developersContext = {
    state,
    setState,
    setBannerTitle,
    setBannerDescription,
    setBannerIndex,
    setActiveCard
  };
  return (
    <DevelopersContext.Provider value={developersContext}>
      {props.children}
    </DevelopersContext.Provider>
  );
};
export const DevelopersContextConsumer = DevelopersContext.Consumer;
export default DevelopersContext;
