import React, { Component } from "react";
import backgroundComponentsBanner from "../../../assets/gradients/Hero_Banner_components.svg";
import ComponentContext, {
  ComponentContextProvider,
} from "../context/ComponentsContext";
import ComponentData from "../static/ComponentData";
import ContentBlock from "../../../app-components/ContentBlock";
import UilBanner from "../../../app-components/UilBanner/UilBanner";
import UilTextFieldSearch from "../../../app-components/UilTextFieldSearch/UilTextFieldSearch";
import ComponentTable from "./componentTable";
import bannerstyles from "../../../app-components/UilBanner/uil_banner.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import "./components.css";
import GoToTop from "app/goToTop";
let contextType = null;

export default class Components extends Component {
  componentDidMount() {}
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      searchValue: "",
    };
  }

  toggleSwitchChange = () => {
    this.state.checked = !this.state.checked;
    contextType.setActiveView(this.state.checked);
  };

  handleKeyDown = (e) => {
    this.state.searchValue = e.target.value;
    contextType.setSearchValue(this.state.searchValue);
  };

  handleClose = () => {
    this.state.searchValue = "";
    contextType.setSearchValue(this.state.searchValue);
  };

  render() {
    return (
      <ComponentContextProvider>
        <ComponentContext.Consumer>
          {(componentContext) => {
            contextType = componentContext;
            return (
              <React.Fragment>
                <UilBanner
                  type={ComponentData.UilBanner.type}
                  size={ComponentData.UilBanner.size}
                  title={ComponentData.UilBanner.title}
                  content={ComponentData.UilBanner.content}
                  backgroundImage={backgroundComponentsBanner}
                  customClasses={ComponentData.UilBanner.customClasses}
                  showTitle={true}
                  showContent={true}
                  showSubHeading={false}
                  leftSpacing={ComponentData.UilBanner.leftSpacing}
                  showFooter={  <>
                    <span className={bannerstyles["uil-banner-btn-border"]}>
                    <a href="storybook-static-react/index.html" target="_blank">
                      <UilButton
                        type="primary"
                        label={<span>React repository</span>}
                        sizeType="large"
                        fitcontent={true}
                        iconPosition="op_iconPositionRight"
                        icon="uil-dl-icon-Arrow-right-up-button"
                        customClasses="uil-primary-btn-custom"
                      ></UilButton> </a>
                    </span>
                    <span className={bannerstyles["uil-banner-btn-border"]}>
                    <a href="storybook-static-angular/index.html" target="_blank">
                      <UilButton
                        type="primary"
                        label={<span>Angular repository</span>}
                        sizeType="large"
                        iconPosition="op_iconPositionRight"
                        icon="uil-dl-icon-Arrow-right-up-button"
                        fitcontent={true}
                        customClasses="uil-primary-btn-custom"
                      ></UilButton></a>
                    </span>
                    <span className={bannerstyles["uil-banner-btn-border"]}>
                    <a href="storybook-static-web/index.html" target="_blank">
                      <UilButton
                        type="primary"
                        label={<span>Web components repository</span>}
                        sizeType="large"
                        iconPosition="op_iconPositionRight"
                        icon="uil-dl-icon-Arrow-right-up-button"
                        fitcontent={true}
                        customClasses="uil-primary-btn-custom"
                      ></UilButton></a>
                    </span>
                  </>}
                ></UilBanner>

                <ContentBlock section>
                  <div className="componentContentSection">
                    <div id="divGreyScale">
                      <span className="colorHeadings">
                        {ComponentData.UilComponentDetails.title}
                      </span>
                      <span className="searchTable">
                        <span>
                          <UilTextFieldSearch
                            placeholder={ComponentData.UilTextFieldSearch.placeholder}
                            handleEnterKeyPressed={(e) => this.handleKeyDown(e)}
                            closeIconHandler={()=>this.handleClose()}
                          />
                        </span>
                        {/* <span>
                          <UilToggleSwitch
                            size={ComponentData.UilToggleSwitch.size}
                            styleType={ComponentData.UilToggleSwitch.styleType}
                            type={ComponentData.UilToggleSwitch.type}
                            leftText={ComponentData.UilToggleSwitch.leftText}
                            rightText={ComponentData.UilToggleSwitch.rightText}
                            checked={contextType.state.selectedView}
                            onChange={() => this.toggleSwitchChange()}
                            customClasses="component-toggleSwitch"
                          ></UilToggleSwitch>
                        </span> */}
                      </span>
                      <span>
                        {/* {contextType.state.selectedView ? (
                          <ComponentCard></ComponentCard>
                        ) : ( */}
                          <ComponentTable
                            searchValue={contextType.state.searchValue}
                          ></ComponentTable>
                        {/* )} */}
                      </span>
                    </div>
                  </div>
                </ContentBlock>
                <GoToTop></GoToTop>
              </React.Fragment>
            );
          }}
        </ComponentContext.Consumer>
      </ComponentContextProvider>
    );
  }
}
