import backgroundImage from "../../../assets/gradients/ACC_Gradient_Dark_6.jpg";
import styles from "../../../app-components/UilFooterNew/uil_footer.module.css";
import UilButton from "../../../app-components/UilButton/UilButton";
import UilBadge from "../../../app-components/UilBadge/UilBadge";
import UilTag from "../../../app-components/UilTag/UilTag";
import acn_logo from "../../../assets/img/AccentureLogo_black.png";
const componentData = {
  UilBanner: {
    type: "hero",
    size: "small",
    title: "Start using our code library",

    backgroundImage: { backgroundImage },
    customClasses: "uil-custom-banner",
    showTitle: true,
    showContent: true,
    showSubHeading: false,
    leftSpacing: "small",
  },
  UilTokenBanner: {
    title: "Using our code design tokens",
  },
  UilTabBar: {
    tabOrientation: "horizontal",
    tabs: [
      {
        tabId: "1",
        tabName: "Get started",
        Description: (
          <span>
            Learn more and access the assets provided to help developers build
            applications with the
            <br /> APP CoE Design System.
          </span>
        ),
      },
      {
        tabId: "2",
        tabName: "Design code tokens",
        Description: (
          <span>
            A shared language between design and development to better
            communicate
            <br />
            detailed information about how to build and customise the user
            interfaces.
          </span>
        ),
      },
    ],
  },
  UilExpandibleCard: [
    {
      id: "react",
      link :"/DL_React_Library_Integration.zip",
      title: (
        <div style={{ display: "flex", gap: 8 }}>
          <span className="cardTitle">React</span>
          <span className="cardTitleBadge">
            {
              <UilBadge
                id="Badge"
                type="text"
                size="mediumLight"
                color="primary"
                text="Storybook"
              ></UilBadge>
            }
          </span>
        </div>
      ),
      content: (
        <div>
          The React package includes styling files (core theme), React library
          source folder (react-uicomp-lib). To showcase the React component
          functionality, we have integrated the components with Storybook.{" "}
          <br />
          <br />
          For the detailed Integration guide and component documentation, please
          follow the below link.
        </div>
      ),
      secondaryButtonLabel: "View",
    },
    {
      id: "angular",
      link :"/DL_Angular_Library_Integration.zip",
      title: (
        <div style={{ display: "flex", gap: 8 }}>
          <span className="cardTitle">Angular</span>
          <span className="cardTitleBadge">
            {" "}
            {
              <UilBadge
                id="Badge"
                type="text"
                size="mediumLight"
                color="primary"
                text="Storybook"
              ></UilBadge>
            }
          </span>
        </div>
      ),
      content: (
        <div>
          The Angular package includes styling files (core theme), Angular library
          source folder (angular-uicomp-lib). To showcase the Angular component
          functionality, we have integrated the components with Storybook.{" "}
          <br />
          <br />
          For the detailed Integration guide and component documentation, please
          follow the below link.
        </div>
      ),
      secondaryButtonLabel: "View",
    },
    {
      id: "web",
      link :"/DL_WebComponents_Library_Integration.zip",
      title: (
        <div style={{ display: "flex", gap: 8 }}>
          <span className="cardTitle">Web Components</span>
          <span className="cardTitleBadge">
            {" "}
            {
              <UilBadge
                id="Badge"
                type="text"
                size="mediumLight"
                color="orange"
                text={<span>Coming soon</span>}
              ></UilBadge>
            }
          </span>
        </div>
      ),
      content: (
        <div>
          The Web Components package includes styling files (core theme), Web Components library
          source folder (web-uicomp-lib). To showcase the Web Components component
          functionality, we have integrated the components with Storybook.{" "}
          <br />
          <br />
          For the detailed Integration guide and component documentation, please
          follow the below link.
        </div>
      ),
      secondaryButtonLabel: "View",
    },
  ],
  UilTableTokenization: [
    {
      title: "Background Color Token",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: "var(--color-bg-primaryLight)",
              },
              {
                content: (
                  <UilButton
                    type="secondary"
                    label="Button"
                    sizeType="large"
                    customClasses="uil-bglight-button"
                    fitcontent={true}
                  ></UilButton>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: "var(--color-bg-secondaryDark)",
              },
              {
                content: (
                  <UilButton
                    type="secondary"
                    label="Button"
                    sizeType="large"
                    customClasses="uil-bgDark-button"
                    fitcontent={true}
                  ></UilButton>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Tokens",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Component",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
    {
      title: "Font Color Token",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: "var(--color-font-red)",
              },
              {
                content: (
                  <UilButton
                    type="secondary"
                    label="Button"
                    sizeType="large"
                    customClasses="uil-fontred-button"
                    fitcontent={true}
                  ></UilButton>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: "var(--color-font-green)",
              },
              {
                content: (
                  <UilButton
                    type="secondary"
                    label="Button"
                    sizeType="large"
                    customClasses="uil-fontgreen-button"
                    fitcontent={true}
                  ></UilButton>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Tokens",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Component",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
    {
      title: "Border Color Token",
      tableData: {
        body: [
          {
            metadata: [{ id: "1" }],
            data: [
              {
                content: "var(--color-border-default)",
              },
              {
                content: (
                  <UilButton
                    type="secondary"
                    label="Button"
                    sizeType="large"
                    customClasses="uil-bordergrey-button"
                    fitcontent={true}
                  ></UilButton>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
          {
            metadata: [{ id: "2" }],
            data: [
              {
                content: "var(--color-border-error)",
              },
              {
                content: (
                  <UilButton
                    type="secondary"
                    label="Button"
                    sizeType="large"
                    customClasses="uil-bordererror-button"
                    fitcontent={true}
                  ></UilButton>
                ),
              },
            ],
            disabled: false,
            selected: false,
          },
        ],
        head: [
          {
            content: "Tokens",
            tooltip: "",
            sortable: false,
            editable: false,
          },
          {
            content: "Component",
            tooltip: "",
            sortable: false,
            editable: false,
          },
        ],
        tooltipPosition: "center",
      },
    },
  ],
  UilFooter: {
    footerLeftArea: (
      <span>
        Copyright 2001-2022<span> </span>
        <a href="https://www.accenture.com/">Accenture.</a>
        <span> </span> Accenture confidential. For internal use only.
      </span>
    ),
    footerLinks: [
      {
        name: "Terms of use",
        link: "https://www.accenture.com/us-en/about/terms-of-use",
      },
      {
        name: "Privacy Statement",
        link: "https://in.accenture.com/protectingaccenture/data-security/5422-2/",
      },
    ],
    footerLogos: (
      <span>
        <a href="https://accenture.com">
          <img
            className={styles["uil-footer-logo-image"]}
            src={acn_logo}
            alt="Accenture"
            width="101"
          ></img>
        </a>
        <span className={styles["uil-footer-appcoe"]}>APP CoE</span>
      </span>
    ),
    footerMiddleArea: [
      { name: "About", link: "#" },
      { name: "Contact Us", link: "#" },
      { name: "FAQ", link: "#" },
    ],
    footerRightArea: (
      <span style={{ display: "contents" }}>
        <span className={styles["uil-footer-feedback"]}>Feedback</span>
        <span className={styles["uil-footer-text"]}>
          Help us make the Transformation Office experience better. If you have
          a comment, idea or even a question, let us know.
        </span>
        <span>
          <UilButton
            type="secondary"
            label="Give Feedback"
            sizeType="large"
            //onClick={() => openFeedback()}
            customClasses={styles["uil-footer-button"]}
            fitcontent={true}
          ></UilButton>
        </span>
      </span>
    ),
  },
};
export default componentData;
