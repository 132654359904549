import React, { useState, useEffect, useRef } from 'react';

// Just enough distance from the top of the page to the first item on the page.

const UilInpageNavigationLinkList = ({
  // Just enough distance from the top of the page to the first item on the page.
  inPageNavHighlightDistance = 270,
  customLinkStyle,
  // 0 - No menu scrolling. Left most item visible.
  scrollIndex = 0,
  // True - Horizontal menu collapsed to curtain
  horizontalInpageNavCollapsed = false,
  // Horizontal in-page nav height
  listHeight = 108,
  // refernce to parent component UilInpageNavContainer
  parentRef,
  // callback to emit changes in scrolling
  handleScrollIndex,
  children,
  linkArray,
  disabled
}) => {
  const [activeLink, setActiveLink] = useState('');

  const itemsRef = useRef([]);
  // UseRef hook to keep a value for the whole lifetime of the component.
  // Value is accessible by re-attached event handlers, which useState value is not.
  const clickedRef = useRef(false);

  const generateLinks=()=>{
    let links=[];
    for (let link = 0; link < Object.keys(linkArray).length; link++){
      links.push(<a href={linkArray[link].linkPath} disabled={disabled}>{linkArray[link].linkName}</a>);
    }
    return links;
  }
  children=generateLinks()
 
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, React.Children.toArray(children).length);
  }, [children]);
  useEffect(() => {
    if (scrollIndex > -1) {
      if (parentRef) {
        // Check moving to left
        if (scrollIndex === 0) {

          itemsRef.current.map(ref => {
            const activeItemLeftSide = ref.offsetLeft;
            const parentLeftSide = parentRef.current.offsetLeft + parentRef.current.scrollLeft;
            if (parentLeftSide >= activeItemLeftSide) {
              parentRef.current.scrollTo(ref.offsetLeft - parentRef.current.offsetLeft, 0);
            }
          });
        } else if (scrollIndex === 1) {
          // Check moving to right
          let moveIndex = -1;
          let itemFound = false;
          itemsRef.current.map((ref, index) => {
            if (!itemFound) {
              const activeItemLeftSide = ref.offsetLeft;
              const parentLeftSide = parentRef.current.offsetLeft + parentRef.current.scrollLeft;
              if (parentLeftSide === activeItemLeftSide) {
                // if these values are equal then move to next item
                moveIndex = index + 1;
                itemFound = true;
              } else if (parentLeftSide < activeItemLeftSide) {
                // If only part of item is visible then move it to be visible
                moveIndex = index;
                itemFound = true;
              }else{
                //default els not required
              }
            }
          });
          if (moveIndex > -1) {
            const moveRef = itemsRef.current[moveIndex];
            parentRef.current.scrollTo(moveRef.offsetLeft - parentRef.current.offsetLeft, 0);
          }
        }else{
          //default else not required
        }
      }
      isLastItemVisible();
    }
  }, [scrollIndex]);
  useEffect(() => {
    isLastItemVisible();
  });
  useEffect(() => {
    // Find active link from children
    let activeIndex = -1;
    const activeChild = React.Children.toArray(children).filter((child, index) => {
      if (child.props.href === activeLink) {
        activeIndex = index;
        return child;
      }
    });
    // Then compare to reference array of list items
    // If active item is not visible entirely then scroll it so that it can be seen.
    if (parentRef && activeChild.length > 0) {
      const activeRef = itemsRef.current[activeIndex];
      const activeItemRightSide = activeRef.offsetLeft + activeRef.offsetWidth;
      const activeItemLeftSide = activeRef.offsetLeft;
      const parentRightSide = parentRef.current.offsetLeft + parentRef.current.offsetWidth + parentRef.current.scrollLeft;
      const parentLeftSide = parentRef.current.offsetLeft + parentRef.current.scrollLeft;
      if (parentRightSide < activeItemRightSide) {
        parentRef.current.scrollBy(activeItemRightSide - parentRightSide, 0);
      } else if (parentLeftSide > activeItemLeftSide) {
        parentRef.current.scrollTo(activeRef.offsetLeft - parentRef.current.offsetLeft, 0);
      }
      else{
        console.log(parentLeftSide);
      }
    }
  }, [activeLink]);
  const inpageNavLinkClickHandler = event => {
    clickedRef.current = true;
    setActiveLink(event.target.getAttribute('href'));
  };
  const updateInpageNavLinkList = () => {
    // Reverse in-page navigation list
    children
      .slice()
      .reverse()
      .some(child => {
        // Ignore possible invalid children
        if (child && child !== undefined) {
          // Find element with the ID
          const element = document.getElementById(
            child.props.href.substring(1)
          );
          if (
            element &&
            element.getBoundingClientRect().top <
            (horizontalInpageNavCollapsed
              ? inPageNavHighlightDistance - listHeight
              : inPageNavHighlightDistance)
          ) {
            setActiveLink(child.props.href);
            // Found (test pass, break)
            return true;
          } else {
            return false;
          }
        }
      });
  };

  const scrollListener = () => {
    // Do not refresh in-page nav, if scrolling due to click event.
    if (!clickedRef.current) {
      updateInpageNavLinkList();
    } else {
      // Clear the clicked info
      clickedRef.current = false;
    }
  };

  // Effect for registering/unregistering a scroll event handler
  useEffect(() => {
    window.addEventListener('scroll', scrollListener, true);
    return () => {
      window.removeEventListener('scroll', scrollListener, true);
    };
  });

  const isLastItemVisible = () => {
    if (itemsRef.current && parentRef) {
      const lastItem = itemsRef.current[itemsRef.current.length - 1];
      const lastItemRightSide = lastItem.offsetLeft + lastItem.offsetWidth;
      const parentRightSide = parentRef.current.offsetLeft + parentRef.current.offsetWidth + parentRef.current.scrollLeft;
      if (handleScrollIndex) {
        handleScrollIndex(!(parentRightSide < lastItemRightSide));
      }
    }
  };

  // Initialization
  const initInPageNav = () => {
    updateInpageNavLinkList();
    return () => { /* This is intentional. Sonar requires content to empty method */ };
  };
  const useMountEffect = func => useEffect(func, []);
  useMountEffect(initInPageNav);

  return (
    <ul className='uil-single-row-list'>
      {children.map((child, index) => {
        if (child && child !== undefined) {
          return (
            <li
              ref={element => itemsRef.current[index] = element}
              className={child.props.href === activeLink ? 'active' : ''}
              key={child.props.href}
              disabled={disabled}
             
            /*style={index < scrollIndex ? { display: 'none' } : {}}*/
            >
              {React.cloneElement(child, {
                onClick: inpageNavLinkClickHandler,
                style: customLinkStyle
              })}
              
            </li>
          );
        } else {
          // Nothing to render
          return null;
        }
      })}
    </ul>
  );
};

UilInpageNavigationLinkList.defaultProps = {
  // Private property to identify the component in in-page navigation children.
  __TYPE: 'UilInpageNavigationLinkList'
};

export default UilInpageNavigationLinkList;