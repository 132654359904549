import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './uil_alert.css'
import styles from './uil_alert.module.css';
import UilButton from '../UilButton/UilButton';

const UilAlert = ({ 
  title, 
  id, 
  onClose,
  isClose,
  subtitle,
  alertType,
  customClasses,
  isTitle,
  isKnowMore,
  onClick,
  isLeftIcon}) => {
  const [showAlert, setShowAlert] = useState(true);

  //function to handle closing of Alert on close icon
  const deleteAlert = () => {
    setShowAlert(false);
    onClose();
  };

  return (
    <>
      {showAlert && (
        <div className={`${styles["notification-container"]} uil-alert-${alertType} ${styles[customClasses]}`} >
          <div
            id={id}
            className={`${styles["uil-alert-maincontainer"]} ${alertType}`}
          >
            <div className={styles["uil-alert-innerSection"]}>
              <div className={styles["uil-alert-datasection"]}>
                {isLeftIcon &&
                <span
                  className={`uil-alert-notificationIcon uil-dl-icon-${alertType}`}
                  style={{fontSize: 16+ "px"}}
                ></span> }
                <div className={styles["uil-alert-textSection"]}>
                  
                    <>
                      {isTitle &&
                        <h4 className={`${styles["uil-alert-title"]}`}>
                          {title}
                        </h4>
                      }
                      {subtitle && (
                      <div>
                        <p className={`${styles["uil-alert-subtitle"]}`}>
                          {subtitle} {isKnowMore && (<UilButton
                          type="default"
                          label="Know More"
                          styleType="link"
                          onClick={onClick}
                          customClasses="uil-alert-button"></UilButton>)}
                        </p>
                       </div>
                      )}
                    </>             
                </div>
                <div className={styles["uil-alert-action"]}>
                  {isClose && (
                  <span className={`uil-alert-cross-icon uil-dl-icon-close-${alertType}`} onClick={deleteAlert} ></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

UilAlert.propTypes = {
  alertType: PropTypes.string,
  autoCloseTime: PropTypes.number,
  isClose:PropTypes.bool,
  isTitle:PropTypes.bool,
  isKnowMore:PropTypes.bool,
  isLeftIcon:PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  captions: PropTypes.string,
  onClose: PropTypes.func,
  customClasses: PropTypes.string,
};

export default UilAlert;