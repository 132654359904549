import React, { Component } from "react";
import DevelopersContext, {
  DevelopersContextProvider,
} from "../context/DevelopersContext";
import UilTable from "../../../app-components/UilTable/UilTable";
import ComponentData from "../static/ComponentData";
import "./developers.css";
import ContentBlock from "../../../app-components/ContentBlock";
let contextType = null;

export default class DesignCodeTokens extends Component {
  componentDidMount() {}
  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };

  render() {
    return (
      <DevelopersContextProvider>
        <DevelopersContext.Consumer>
          {(developersContext) => {
            contextType = developersContext;
            return (
              <React.Fragment>
                <div style={{ width: 800, minHeight: 1024 }}>
                  <ContentBlock section>
                    <div className="colorSections">
                      <div className="colorHeadings">Tokenization</div>
                      <div className="colorContent">
                        Tokens are used to create extensive web applications. It
                        saves duplicated code and confusion between multiple
                        teams working on the same code. It helps in providing
                        uniformity to the code.<br/> <br/> <br/>  All the tokens are saved in
                        single root css file, any change with respective to any
                        token is reflected to all the components where that
                        token is used.
                      </div>
                    </div>
                  </ContentBlock>

                  {ComponentData.UilTableTokenization.map((Content) => {
                    return (
                      <ContentBlock>
                        <div className="colorHeadings">{Content.title}</div>
                        <div id="divGreyScale">
                          <span className="tableContent">
                            <UilTable
                              type="basic"
                              id="table_id"
                              variantType="defaultHeader"
                              showEditableFields={false}
                              showHorizontalScrollbar={true}
                              disabled={false}
                              selectAll={false}
                              borderless={false}
                              bottomBorder={false}
                              dotMenuListStyles=""
                              defaultSortDirection=""
                              defaultSortColumn="Firstname"
                              isgreyhoverEnabled={false}
                              contentPerPage={3}
                              isPaginationVisible={false}
                              tableData={Content.tableData}
                            ></UilTable>
                          </span>
                        </div>
                      </ContentBlock>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          }}
        </DevelopersContext.Consumer>
      </DevelopersContextProvider>
    );
  }
}
