import React, { useState } from "react";
import Settings from "../static/settings";
import ComponentData from "../static/ComponentData"
import componentData from "../static/ComponentData";
const GetStartedContext = React.createContext({});

export const GetStartedContextProvider = (props) => {
  const [state, setState] = useState({
    uilHeaderTools : Settings.UilHeader.UilHeaderTools,
    uilHeaderLogo : Settings.UilHeader.UilHeaderLogo,
    uilSideNavTopOptions : Settings.UilSideNavBar.UilSideNavTopOptions,
    uilSideNavBottomOptions : Settings.UilSideNavBar.UilSideNavBottomOptions,
    uilFooterLeftArea: ComponentData.UilFooter.footerLeftArea,
    uilFooterLinks : ComponentData.UilFooter.footerLinks,
    uilFooterLogos : componentData.UilFooter.footerLogos,
    uilFooterMiddleArea : componentData.UilFooter.footerMiddleArea,
    uilFooterRightArea : componentData.UilFooter.footerRightArea,
    selectedMenu : "Home"
  });

  //Setting the Tools Prop - UilHeader.
  const setSelectedMenu = (selectedNavMenu) =>{
    state.selectedMenu = selectedNavMenu;
    setState({
      ...state,
      selectedMenu : state.selectedMenu
    });
  }

  const getStartedContext = {
    state,
    setState,
    setSelectedMenu
  };
  return (
    <GetStartedContext.Provider value={getStartedContext}>
      {props.children}
    </GetStartedContext.Provider>
  );
};
export const GetStartedConsumer = GetStartedContext.Consumer;
export default GetStartedContext;
