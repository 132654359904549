import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_textbox.module.css";
import UilLabel from "../UilLabel/UilLabel";
import UilFootNote from "../UilFootNote/UilFootNote";

const UilTextField = ({
  icon,
  iconSide,
  required,
  disabled,
  label,
  placeholder,
  footnoteText,
  footnoteType,
  errorText,
  tooltipText,
  tooltipPosition,
  value,
  id,
  customClasses,
  maxLength,
  isPreventEnter,
  onKeyUpHandler,
  inputAttributes,
  displayCounter,
  onChange,
  onClick,
  ref,
  isError,
  inputType,
  onBlur,
  labelIcon,
  customStyles,
  keyPressHandler
}) => {


  const counter =  `${value.length} / ${maxLength}`;

  const WrapperClass = classNames("uil-textField-Wrapper", customClasses, {
    "uil-textField-Wrapper-disabled": disabled,
    "uil-textField-Wrapper-error": isError && !disabled,
  });


  const keyUpHandler = function (event) {
    if (onKeyUpHandler !== undefined) {
      onKeyUpHandler(event);
    }
  };
 
  

 const mainClassName = classNames("uil-textField");
  return (
    <div className={mainClassName}>
      {label && (
        <UilLabel
          id={id}
          label={label}
          disabled={disabled}
          required={required}
          tooltip={tooltipText}
          tooltipPosition={tooltipPosition}
          iconPosition="Icon Right"
          customStyles={{ fontSize: 16, fontWeight: 400 }}
         // icon="\e994"
          icon={labelIcon}
          {...(displayCounter && {
            counterText: counter
          })}
        >
        </UilLabel>
      )}

      <div className={WrapperClass} >
        {iconSide === "op_iconLeft" && (
          <span
            className={
              disabled
                ? `${styles["uil-textField-lefticon"]} ${styles["disabled"]}`
                : `${styles["uil-textField-lefticon"]}`
            }
          >
            <span className={icon}></span>
          </span>
        )}

        <input
          ref={ref}
          className='uil-textField-inputClass'
          style={customStyles}
          id={id}
          type={inputType}
          value={value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          {...(onClick && {
            onClick: onClick,
          })}
          onKeyUp={keyUpHandler}
          onKeyPress={keyPressHandler}
          maxLength={maxLength}
          {...inputAttributes}
        />
        {iconSide === "op_iconRight" && (
          <span
            className={
              disabled
                ? styles["uil-textField-righticon"] +
                  " " +
                  `${styles["disabled"]}`
                : styles["uil-textField-righticon"]
            }
          >
            <span className={icon}></span>
          </span>
        )}
      </div>

      {footnoteText && (
        <UilFootNote
          type={footnoteType}
          id={id}
          disabled={disabled}
          footnoteText={footnoteText}
          customStyles={{ fontSize: 12 }}
          customPadding={{ paddingLeft: 16 }}
          errorText={errorText}
        />
      )}
    </div>
  );
};

UilTextField.propTypes = {
  icon: PropTypes.element,
  iconSide: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.element,
  placeholder: PropTypes.string,
  footnoteText: PropTypes.element,
  footnoteType: PropTypes.string,
  tooltipText: PropTypes.element,
  errorText: PropTypes.element,
  tooltipPosition: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  customClasses: PropTypes.string,
  maxLength: PropTypes.number,
  isPreventEnter: PropTypes.bool,
  onKeyUpHandler: PropTypes.func,
  inputAttributes: PropTypes.object,
  displayCounter: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  ref: PropTypes.func,
  isError: PropTypes.bool,
  inputType: PropTypes.string,
  onBlur: PropTypes.func,
  labelIcon: PropTypes.element
};

export default UilTextField;