import React from 'react';

class UilBasePage extends React.Component {
  constructor() {
    super();
    this.state = {
      shadowHeader: false,
      isPanelActive: false,
      hasPanel: false,
      verticalMenuInfo: {
        verticalMenuEmpty: false,
        verticalMenuCollapsed: false
      },
      horizontalMenuInfo: {
        horizontalMenuEmpty: false,
        horizontalMenuCollapsed: false
      },
      verticalMenuRef: undefined,
      isSearchActive: false,
      horizontalMenuRef: undefined,
      notifications: []
    };

    // ref element to reference the curtain toggle DOM element
    this.toggleRef = null;
    // ref element to reference the curtain panel DOM element
    this.panelRef = null;

    this.curtainToggleHandler = this.curtainToggleHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateVerticalMenuInfo = this.updateVerticalMenuInfo.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.onMergeMenus = this.onMergeMenus.bind(this);
    this.onUnmergeMenus = this.onUnmergeMenus.bind(this);
    this.updateHorizontalMenuInfo = this.updateHorizontalMenuInfo.bind(this);
    this.searchStatusChanged = this.searchStatusChanged.bind(this);
    this.scrollFooterAccordions = this.scrollFooterAccordions.bind(this);
    this.resizeNavbar = this.resizeNavbar.bind(this);

    // Reference to content-footer-wrapper
    this.contentFooterWrapperRef = React.createRef();

    // Custom scrollbar thumb variables and functions
    this.dragThumbMouseDown = this.dragThumbMouseDown.bind(this);
    this.dragThumbMouseUp = this.dragThumbMouseUp.bind(this);
    this.dragThumb = this.dragThumb.bind(this);
    this.customScrollbarThumbRef = React.createRef();
    this.thumbDragOffset = 0;
    this.thumbDragStartPos = 0;
    // Custom scrollbar scroll variables and functions
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollEnding = this.handleScrollEnding.bind(this);
    this.handleScrollShowing = this.handleScrollShowing.bind(this);
    this.scrollEnded = false;
    this.isScrolling = false;

    this.navBarMenuAlign = {
      left: 'left',
      right: 'right',
      center: 'center'
    };

    this.horizontalMenuAlign = this.navBarMenuAlign.left;
  }

  componentDidMount() {
    // Event handlers
    document.addEventListener('click', this.handleClick);
    window.addEventListener('resize', this.resizeHandler);
    window.addEventListener('scroll', this.handleScrollShowing, true);
    window.addEventListener('scroll', this.handleScrollEnding, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('resize', this.resizeNavbar);
    window.removeEventListener('scroll', this.handleScrollShowing, true);
    window.removeEventListener('scroll', this.handleScrollEnding, true);
  }

  /**
   * Scroll after opening accordions
   */
  scrollFooterAccordions() {
    const wrapper = this.contentFooterWrapperRef.current;
    // 5ms interval
    const id = setInterval(frame, 5);
    let previousScrollTop = undefined;
    function frame() {
      // Not scrolling anymore == reached bottom of the page.
      if (
        previousScrollTop !== undefined &&
        wrapper.scrollTop === previousScrollTop
      ) {
        clearInterval(id);
      } else {
        previousScrollTop = wrapper.scrollTop;
        // Scroll 10px down on every interval (until bottom reached).
        wrapper.scrollTop += 10;
      }
    }
  }

  handleScrollShowing(event) {
    if (this.scrollEnded) {
      this.scrollEnded = false;
      return;
    }
    // Add on-scrollbar class to show the scrollbar
    if (
      event &&
      event.target &&
      event.target.classList &&
      event.target.classList.contains('on-scrollbar') === false
    ) {
      event.target.classList.add('on-scrollbar');
    }
  }

  handleScrollEnding(event) {
    // Clear our timeout throughout the scroll
    window.clearTimeout(this.isScrolling);
    // Set a timeout to run after scrolling ends
    this.isScrolling = setTimeout(() => {
      // Run the callback

      // Hide the custom scrollbar thumb
      const thumb = this.customScrollbarThumbRef.current;
      if (thumb && !thumb.classList.contains('dragging')) {
        thumb.classList.remove('visible');
      }

      //Undefined check added to avoid the contains refrence issue - 20-12-2022 
      if(event.target.classList != undefined){ 
        if (event.target.classList.contains('on-scrollbar') === true) {
        event.target.classList.remove('on-scrollbar');
        event.stopPropagation();
      }
    }
      this.scrollEnded = true;
    }, 2000);
  }

  dragThumbMouseDown(event) {
    event = event || window.event;
    event.preventDefault();
    // get the mouse cursor position at startup
    this.thumbDragStartPos = event.clientY;
    // call a function when mouse is released
    document.onmouseup = this.dragThumbMouseUp;
    // call a function whenever the cursor moves
    document.onmousemove = this.dragThumb;
  }

  dragThumb(event) {
    event = event || window.event;
    event.preventDefault();
    const scrollablePage = this.contentFooterWrapperRef.current;
    // calculate the new cursor position
    this.thumbDragOffset = this.thumbDragStartPos - event.clientY;
    this.thumbDragStartPos = event.clientY;
    // Set thumb into dragging state
    const thumb = this.customScrollbarThumbRef.current;
    thumb.classList.add('dragging');
    // set the element's new position:
    // Limit value to 0. Cannot drag out from the page.
    // Limit the value to page height - thumb height.
    let thumbTop = thumb.offsetTop - this.thumbDragOffset;
    thumbTop = thumbTop > 0 ? thumbTop : 0;
    // prettier-ignore
    thumbTop = thumbTop + thumb.scrollHeight > scrollablePage.clientHeight ? scrollablePage.clientHeight - thumb.scrollHeight : thumbTop;
    thumb.style.top = thumbTop + 'px';
    // Set the page scroll value to where the thumb was dragged.
    scrollablePage.scrollTop =
      (thumbTop / scrollablePage.clientHeight) * scrollablePage.scrollHeight;
  }

  dragThumbMouseUp() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
    const thumb = this.customScrollbarThumbRef.current;
    thumb.classList.remove('dragging');
  }

  // Horizontal menu info update method
  updateHorizontalMenuInfo(info) {
    if (
      info.horizontalMenuEmpty !==
      this.state.horizontalMenuInfo.horizontalMenuEmpty ||
      info.horizontalMenuCollapsed !==
      this.state.horizontalMenuInfo.horizontalMenuCollapsed
    ) {
      this.setState({ horizontalMenuInfo: info }, function () {
        this.resizeNavbar();
      });
    }
  }

  resizeHandler() {
    // Close search due to resize
    this.setState({ isSearchActive: false });
    // Trigger re-render for the content due to resize.
    this.setState(this.state);
  }

  searchStatusChanged() {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  }

  handleScroll(event) {
    if (event.target === this.contentFooterWrapperRef.current) {
      const headerScrolled = event.target.scrollTop > 8 ? true : false;
      this.setState({
        shadowHeader: headerScrolled
      });

      const thumb = this.customScrollbarThumbRef.current;
      if (thumb) {
        thumb.classList.add('visible');
        thumb.style.top =
          `${(event.target.scrollTop / event.target.scrollHeight)} *
          ${event.target.clientHeight} +
          ${'px'}`;
        thumb.style.height =
          `${(event.target.clientHeight * event.target.clientHeight)} /
          ${event.target.scrollHeight}
          ${'px'}`;
      }
    }
  }

  // Merge vertical and horizontal menus.
  onMergeMenus() {
    this.state.verticalMenuRef.mergeMenus(this.state.horizontalMenuRef);
  }

  // Unmerge vertical and horizontal menus.
  onUnmergeMenus() {
    this.state.verticalMenuRef.unmergeMenus();
  }

  updateVerticalMenuInfo(info) {
    // Update vertical menu info, if it has changed.
    // Checking is needed in order to prevent loop,
    // since the setState causes update for the component and subcomponents.
    if (
      info.verticalMenuEmpty !==
      this.state.verticalMenuInfo.verticalMenuEmpty ||
      info.verticalMenuCollapsed !==
      this.state.verticalMenuInfo.verticalMenuCollapsed
    ) {
      this.setState({ verticalMenuInfo: info }, function () {
        this.resizeNavbar();
      });
    }
  }

  curtainToggleHandler() {
    this.setState({ isPanelActive: !this.state.isPanelActive });
  }

  handleClick(event) {
   
  }

  resizeNavbar() {
    // If search is not open
    if (!this.state.isSearchActive) {
      //Close sidemenu, if it is open and empty or the sidemenu is already collapsed.
      if (
        !this.state.horizontalMenuInfo.horizontalMenuEmpty &&
        (this.state.verticalMenuInfo.verticalMenuEmpty ||
          this.state.verticalMenuInfo.verticalMenuCollapsed)
      ) {
        this.setState({ isPanelActive: false });
      }

      if (this.state.horizontalMenuInfo.horizontalMenuCollapsed) {
        // Check if horizontal menu can be open.
        if (this.state.horizontalMenuRef !== undefined) {
          this.state.horizontalMenuRef.tryOpenMenu(
            this.state.verticalMenuInfo.verticalMenuCollapsed
          );
        }
      } else {
        // Tell horizontal menu to collapse
        if (this.state.horizontalMenuRef !== undefined) {
          this.state.horizontalMenuRef.closeMenu(
            this.state.verticalMenuInfo.verticalMenuCollapsed
          );
        }
      }
    }
  }


  rootPadding = () => {
    return (this.state.verticalMenuInfo !== undefined &&
      this.state.verticalMenuInfo.verticalMenuCollapsed) ||
      (this.state.verticalMenuInfo !== undefined &&
        !this.state.verticalMenuInfo.verticalMenuCollapsed &&
        !this.state.hasPanel)
      ? { paddingLeft: '0px' }
      : {};
  };
}

export default UilBasePage;
