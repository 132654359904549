import React from "react";
import "./uil_expandablecard.css";
import UilShadow from "../UilShadow/UilShadow";
import classNames from "classnames";
import UilBadge from "../UilBadge/UilBadge";

const UilExpandibleCard = (props) => {
  const {
    title,
    subtitle,
    content,
    iconsToShow,
    isRigthButton,
    isExpanded,
    onCardClick,
    footerSection
  } = props;

  const buttonWrapperClass = classNames("uil_exapandablecard_button_wrapper", {
    "uil_exapandablecard_button_wrapper-right": isRigthButton,
  });

  return (
    <UilShadow customClasses="uil_exapandablecard">
      <div className="uil_exapandablecard_wrapper">
        <div className="uil_exapandablecard_title_subtile_wrapper">
          <div
            onClick={onCardClick}
            className="uil_exapandablecard_title_arrow_wrapper"
          >
            <h4 className="uil_exapandablecard_title">{title}</h4>
            
            <span
              className={
                isExpanded
                  ? "uil-dl-icon-Chevron-up"
                  : "uil-dl-icon-Chevron-down"
              }
            ></span>
          </div>
          {subtitle && (
            <p className="uil_exapandablecard_subtitle">{subtitle}</p>
          )}
        </div>
      </div>
      {isExpanded && (
        <>
          {content && (
            <div className="uil_exapandablecard_content">{content}</div>
          )}
          {iconsToShow && (
            <div className="uil_exapandablecard_icon">{iconsToShow}</div>
          )}
            <div className={buttonWrapperClass}>
            {footerSection && <>{footerSection}</>}
            </div>
        </>
      )}
    </UilShadow>
  );
};
export default UilExpandibleCard;
