import React, { Component } from "react";
import DevelopersContext, {
  DevelopersContextProvider,
} from "../context/DevelopersContext";
import UilExpandableCard from "../../../app-components/UilExpandableCard/UilExpandableCard";
import ComponentData from "../static/ComponentData";
import Settings from "../static/settings";
import "./developers.css";
import ContentBlock from "../../../app-components/ContentBlock";
import UilButton from "app-components/UilButton/UilButton";
import GoToTop from "app/goToTop";
let contextType = null;

export default class GetStarted extends Component {
  componentDidMount() {}
  constructor(props) {
    super(props);
    this.state = {
      active: {
        react: false,
        angular: false,
        web: false,
      },
    };
  }
  onViewClick = (type) => {
    debugger;
  };

  onTabBarClick = (selectedTab) => {
    if (selectedTab) {
      const filterTabs = ComponentData.UilTabBar.tabs.filter(
        (tabs) => tabs.tabId === selectedTab[0]
      );
      if (filterTabs.length > 0) {
        contextType.setBannerTitle(filterTabs[0].tabName);
        contextType.setBannerDescription(filterTabs[0].Description);
        contextType.setBannerIndex(filterTabs[0].tabId);
      }
    }
  };
  onCardClick = (id) => {
    const updatedCardsActive = {
      ...this.state.active,
      [id]: !this.state.active[id],
    };
    this.setState({
      active: updatedCardsActive,
    });
    contextType.setActiveCard(updatedCardsActive);
  };

  render() {
    return (
      <DevelopersContextProvider>
        <DevelopersContext.Consumer>
          {(developersContext) => {
            contextType = developersContext;
            return (
              <React.Fragment>
                <div className="developerSections">
                  <span className="colorHeadings">
                    {
                      Settings.GetStartedContent.Titles
                        .whatIsTheAPPCoEDesignSystemCodelibrary
                    }
                  </span>
                  <span className="colorContent">
                    {
                      Settings.GetStartedContent.Descriptions
                        .whatIsTheAPPCoEDesignSystemCodelibraryDesc
                    }
                  </span>
                  <ContentBlock section>
                    <span className="colorHeadings">
                      {Settings.GetStartedContent.Titles.languages}
                    </span>
                    <div className="cardSection" style={{ minHeight: 420 }}>
                      {ComponentData.UilExpandibleCard.map((card) => {
                        const cardId = card.id;
                        return (
                          <UilExpandableCard
                            id={card.id}
                            onCardClick={() => this.onCardClick(card.id)}
                            isExpanded={contextType.state.activeCard[cardId]}
                            title={card.title}
                            content={card.content}
                            footerSection={
                              <div
                                className="uil_exapandable-card_footer"
                                style={{ display: "flex", gap: "16px" }}
                              >
                                <a href={card.link}>
                                <UilButton
                                  fitcontent
                                  icon="uil-dl-icon-downloadarrow"
                                  label="View"
                                 
                                  sizeType="large"
                                  styleType="square"
                                  type="secondary"
                                ></UilButton></a>
                              </div>
                            }
                          ></UilExpandableCard>
                        );
                      })}
                    </div>
                  </ContentBlock>
                  <GoToTop />
                </div>
              </React.Fragment>
            );
          }}
        </DevelopersContext.Consumer>
      </DevelopersContextProvider>
    );
  }
}
