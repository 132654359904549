import React from "react";
import UilShadow from "../UilShadow/UilShadow";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_card.css";

const UilCard = (props) => {
  const { uppersection, bodysection, bottomsection, isSelected, clickHandler } = props;
  const innerClassName = classNames(styles["uil-card-wrapper"], { 
    [styles["uil-card-wrapper-selected"]]: isSelected,
  })
  return (
    <UilShadow customClasses="uil-card">
      <div className={innerClassName} onClick={()=>clickHandler && clickHandler()}>
        {uppersection && <>{uppersection}</>}
        
        {bodysection && <>{bodysection}</>}

        {bottomsection && <>{bottomsection}</>}
      </div>
    </UilShadow>
  );
};

UilCard.prototype = {
  uppersection: PropTypes.any,
  bodysection: PropTypes.any,
  bottomsection: PropTypes.any,
  isSelected: PropTypes.bool,
};

export default UilCard;
