import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./uil_accordion.module.css";

const UilAccordionContentBlock = (props) => {
  const {
    id,
    isExpand = false,
    children,
    isIconOption,
    customClasses,
    title,
    counter,
    isCounterOption,
  } = props;

  const [expand, setExpand] = useState(isExpand);
  useEffect(() => {
    setExpand(isExpand);
  }, [isExpand]);

  const typeClass = classNames(customClasses, styles["uil-accordion"], {
    [styles["uil-accordion-regular"]]: props.type === "regular",
    [styles["uil-accordion-large"]]: props.type === "large",
    [styles["expand"]]: expand,
  });

  const expandClass = expand ? [styles["expand"]] : "";

  return (
    <div
      id={id}
      className={classNames(`${typeClass} ${customClasses}`)}
      disabled={props.disabled}
    >
      <div
        className={styles["uil-accordion-title-area"]}
        disabled={props.disabled}
        onClick={() => setExpand((expand) => !expand)}
      >
        <span
          className={`${styles["uil-accordion-title"]} ${expandClass}`}
          disabled={props.disabled}
        >
          {title}
          {isCounterOption && (
            <span
              className={styles["uil-accordion-counter"]}
              disabled={props.disabled}
            >
              {`(${counter})`}
            </span>
          )}
        </span>

        {isIconOption && (
          <span
            className={classNames(
              `${styles["uil-dl-icon-chevron-down"]}  ${expandClass}`
            )}
            disabled={props.disabled}
          ></span>
        )}
      </div>
      {expand && (
        <div
          className={styles["uil-accordion-content"]}
          disabled={props.disabled}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const UilAccordion = (props) => {
  return (
    <React.Fragment>
      <UilAccordionContentBlock {...props}></UilAccordionContentBlock>
    </React.Fragment>
  );
};

UilAccordion.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.any,
  customClasses: PropTypes.string,
  isExpand: PropTypes.bool,
  children: PropTypes.any,
  isIconOption: PropTypes.bool,
  type: PropTypes.string,
  counter: PropTypes.number,
  isCounterOption: PropTypes.bool,
};

UilAccordion.defaultProps = {
  disabled: false,
  type: "regular",
  counter: 6
};

export default UilAccordion;
